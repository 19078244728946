
import {
  ActivitiesApiActivityFavouriteProductsRequest,
  Activity,
  ActivityFavouriteProducts200Response,
  ActivityProduct,
  FavouriteProducts,
  RestErrorResponse,
} from 'farmdok-rest-api';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import FavouriteProductsSection from '@/activities/modals/createEditActivity/components/FavouriteProductsSection.vue';
import FindService from '@/activities/modals/createEditActivity/services/FindService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import { Api } from '@/plugins/farmdokRestApi';
import ProductService from '@/products/services/ProductService';
import { ActivityType, Field, Unit } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { RootState } from '@/store/types';

import { Getters as CreateEditActivityGetters } from '../store/getters';

export default defineComponent({
  name: 'FavouriteProductsSectionContainer',
  components: {
    FavouriteProductsSection,
  },
  created() {
    this.$store.dispatch('activityTypes/subscribe');
    this.$store.dispatch('products/subscribe');
    this.$store.dispatch('units/subscribe');
    this.$store.dispatch('fields/subscribe');
  },
  data() {
    const favouriteProducts: FavouriteProducts[] = [];

    return {
      favouriteProducts,
    };
  },
  computed: {
    findService(): FindService {
      const productService = new ProductService(
        this.$store.state.products.mineralFertilizers.data,
        this.$store.state.products.companyFertilizers.data,
        this.$store.state.products.secondaryFertilizers.data,
        this.$store.state.products.herbizides.data,
        this.$store.state.products.crops.data,
        this.$store.state.products.miscellaneous.data,
        this.$store.state.products.harvests.data,
        this.$store.state.products.seeds.data,
        this.$store.state.products.otherProductsAndFertilizers.data,
        this.$store.state.products.waads.data,
      );
      const activityProductService = new ActivityProductService(
        this.$store.state.units.data,
        productService,
        calculateAmountsAndUnits,
      );
      return new FindService(this.activities, activityProductService);
    },
    units(): Data<Unit> {
      return (this.$store.state as RootState).units.data;
    },
    ...mapGetters({
      currentProcessOrderIds: 'auth/currentProcessOrderIds',
      currentCompanyIds: 'auth/currentCompanyIds',
      products: 'products/allProducts',
    }),
    fields(): Data<Field> {
      return (this.$store.state as RootState).fields.data;
    },
    processedArea() {
      const sumProcessedArea = this.$store.getters[
        `activities/createEditActivity/sumProcessedArea`
      ] as CreateEditActivityGetters['sumProcessedArea'];

      return sumProcessedArea;
    },
    activityType(): ActivityType | undefined {
      const activityTypeId = (this.$store.state as RootState).activities.createEditActivity.activities?.[0]
        .activityTypeId; // TODO #957 use all activities
      if (!activityTypeId) return undefined;
      return (this.$store.state as RootState).activityTypes.data[activityTypeId];
    },
    activities(): Activity[] {
      return (this.$store.state as RootState).activities.createEditActivity.activities;
    },
    currentActivityProduct(): ActivityProduct | undefined {
      const { currentActivityProductId } = (this.$store.state as RootState).activities.createEditActivity;
      if (!currentActivityProductId) return undefined;
      return this.findService.findActivityProduct(currentActivityProductId);
    },
    showFavouriteProducts(): boolean {
      if (this.favouriteProducts.length === 0) {
        return false;
      }
      if (!this.currentActivityProduct || this.currentActivityProduct.productId === null) {
        return true;
      }

      return false;
    },
    selectedFieldsCropIds(): string[] {
      const selectedFieldIds = this.$store.getters[
        `activities/createEditActivity/selectedFields`
      ] as CreateEditActivityGetters['selectedFields'];
      const fields = (this.$store.state as RootState).fields.data;

      const cropIds = selectedFieldIds
        ?.map((selectedFieldId) => fields[selectedFieldId]?.cropId)
        .filter(notNullOrUndefined);

      if (!cropIds) return [];

      const uniqueCropIds = [...new Set(cropIds)];
      return uniqueCropIds;
    },
    loadFavouriteProductsTriggerKey(): string {
      if (this.activityType) {
        const currentProcessOrderId = this.currentProcessOrderIds?.[0].id;
        const companyIds = [...this.currentCompanyIds].sort().join('-');
        const activityTypeId = this.activityType.id;
        const cropIds = [...this.selectedFieldsCropIds].sort().join('-');

        return `${currentProcessOrderId}_${companyIds}_${activityTypeId}_${cropIds}`;
      }
      return '';
    },
  },
  watch: {
    loadFavouriteProductsTriggerKey: {
      handler() {
        this.loadFavouriteProducts();
      },
      immediate: true,
    },
  },
  methods: {
    async loadFavouriteProducts() {
      if (!this.activityType) return;

      const { activitiesApi } = this.$api as Api;
      const requestParameters: ActivitiesApiActivityFavouriteProductsRequest = {
        companyIds: this.currentCompanyIds.join(','),
        processOrderId: this.currentProcessOrderIds[0],
        activityTypeId: this.activityType.id,
        cropIds: this.selectedFieldsCropIds.join(','),
      };

      let apiResponse: ActivityFavouriteProducts200Response | RestErrorResponse | undefined;
      try {
        const response = await activitiesApi.activityFavouriteProducts(requestParameters);
        apiResponse = response.data;
      } catch (error) {
        console.error(error);
        this.favouriteProducts = [];
      }

      if (apiResponse) {
        if (apiResponse.status === 'success') {
          this.favouriteProducts = (apiResponse as ActivityFavouriteProducts200Response).data || [];
        } else {
          this.favouriteProducts = [];
        }
      }
    },
    addFavouriteProducts(favouriteProducts: FavouriteProducts) {
      this.$store.dispatch(`activities/createEditActivity/addFavouriteProducts`, favouriteProducts);
    },
  },
});
