var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { RuleViolationType, } from 'farmdok-rest-api';
import { isViolationNotChecked } from '@/activities/ruleCheck/types';
import { ENTRY_SYNCED } from '@/shared/constants';
export default class TableDataActivityService {
    constructor(activities, metadata, units, fields, productCategories, equipments, users, violationService, activityProductService, companiesById, processOrdersById, productsLoading, findProductById, productStorageDropdownItem, pesticideIndicationsDropdownItem, activityTypeDropdownItem, fieldDropdownItem, calculateAmountsAndUnits, isHerbicide) {
        this.activities = activities;
        this.metadata = metadata;
        this.units = units;
        this.fields = fields;
        this.productCategories = productCategories;
        this.equipments = equipments;
        this.users = users;
        this.violationsService = violationService;
        this.activityProductService = activityProductService;
        this.companiesById = companiesById;
        this.processOrdersById = processOrdersById;
        this.productsLoading = productsLoading;
        this.findProductById = findProductById;
        this.productStorageDropdownItem = productStorageDropdownItem;
        this.pesticideIndicationsDropdownItem = pesticideIndicationsDropdownItem;
        this.activityTypeDropdownItem = activityTypeDropdownItem;
        this.fieldDropdownItem = fieldDropdownItem;
        this.calculateAmountsAndUnits = calculateAmountsAndUnits;
        this.isHerbicide = isHerbicide;
    }
    convertToActivitiesTableData() {
        const tableData = [];
        this.activities.forEach((activity) => {
            var _a, _b;
            const _c = activity, { products, equipment, id, activityTypeId, fieldId, storeStatus } = _c, restOfActivity = __rest(_c, ["products", "equipment", "id", "activityTypeId", "fieldId", "storeStatus"]);
            const productsNotDeleted = TableDataActivityService.getNotDeletedActivityProducts(products);
            const equipmentNotDeleted = TableDataActivityService.getNotDeletedActivityEquipment(equipment);
            const metadataWithFallback = this.getMetadataWithFallback(id);
            const activityTypeDropdownItem = (_a = this.activityTypeDropdownItem(activityTypeId)) !== null && _a !== void 0 ? _a : undefined;
            const fieldDropdownItem = fieldId ? (_b = this.fieldDropdownItem(fieldId)) !== null && _b !== void 0 ? _b : undefined : undefined;
            const fieldSize = this.getFieldSize(activity.fieldId);
            const ruleCheckStatus = this.getRuleCheckStatus(id);
            if (!activity.processOrderId)
                throw Error('Activity must have a process order id');
            const companyName = this.getCompanyName(activity.processOrderId);
            const user = this.getUserName(activity.userId);
            const longestSubtableLength = Math.max(productsNotDeleted.length, equipmentNotDeleted.length);
            if (longestSubtableLength === 0) {
                tableData.push(Object.assign(Object.assign(Object.assign(Object.assign({}, restOfActivity), { id,
                    activityTypeDropdownItem,
                    fieldDropdownItem }), metadataWithFallback), { fieldSize,
                    ruleCheckStatus,
                    companyName,
                    user,
                    storeStatus }));
            }
            else {
                for (let i = 0; i < longestSubtableLength; i += 1) {
                    const singleProduct = productsNotDeleted[i];
                    const singleEquipment = equipmentNotDeleted[i];
                    tableData.push(Object.assign(Object.assign(Object.assign(Object.assign({}, restOfActivity), { id,
                        activityTypeDropdownItem,
                        fieldDropdownItem }), metadataWithFallback), { product: singleProduct ? this.toTableDataActivityProduct(singleProduct, activity.processedArea) : undefined, equipment: singleEquipment ? this.toTableDataActivityEquipment(singleEquipment) : undefined, fieldSize,
                        ruleCheckStatus,
                        companyName,
                        user,
                        storeStatus }));
                }
            }
            tableData.push(TableDataActivityService.rowForAddButtons(id, activityTypeDropdownItem, fieldDropdownItem, fieldSize, ruleCheckStatus, restOfActivity, metadataWithFallback, companyName, user));
        });
        return tableData;
    }
    static getRuleCheckStatusType(violations) {
        if (!violations || violations.length === 0) {
            return 'OK';
        }
        if (violations.some((violation) => !isViolationNotChecked(violation))) {
            return RuleViolationType.Violation;
        }
        return RuleViolationType.NotChecked;
    }
    getMetadataWithFallback(activityId) {
        var _a, _b, _c, _d;
        const metadataSelect = (_b = (_a = this.metadata[activityId]) === null || _a === void 0 ? void 0 : _a.select) !== null && _b !== void 0 ? _b : false;
        const metadataExpand = (_d = (_c = this.metadata[activityId]) === null || _c === void 0 ? void 0 : _c.expand) !== null && _d !== void 0 ? _d : false;
        return { select: metadataSelect, expand: metadataExpand };
    }
    toTableDataActivityProduct(activityProduct, processedArea) {
        var _a;
        const activityProductWithFallbackAmount = Object.assign(Object.assign({}, activityProduct), { amount: (_a = activityProduct.amount) !== null && _a !== void 0 ? _a : 0 });
        const amountsAndUnits = this.activityProductService.getAmountsAndUnits(activityProductWithFallbackAmount, processedArea);
        const { amount, unitId } = activityProduct, restOfActivityProduct = __rest(activityProduct, ["amount", "unitId"]);
        const productStorageDropdownItem = this.productStorageDropdownItem(activityProduct.productId, activityProduct.storagePlaceId);
        const pesticideIndicationDropdownItem = this.getOptionalPesticideIndicationDropdownItem(activityProduct);
        return Object.assign(Object.assign(Object.assign(Object.assign({}, restOfActivityProduct), { productStorageDropdownItem: productStorageDropdownItem !== null && productStorageDropdownItem !== void 0 ? productStorageDropdownItem : undefined, pesticideIndicationDropdownItem }), amountsAndUnits), { isLoading: this.productsLoading });
    }
    toTableDataActivityEquipment(activityEquipment) {
        var _a, _b;
        const { id, equipmentId } = activityEquipment, restOfActivityEquipment = __rest(activityEquipment, ["id", "equipmentId"]);
        return Object.assign(Object.assign({}, restOfActivityEquipment), { id, dropdownItem: {
                id: equipmentId,
                name: (_b = (_a = this.equipments[equipmentId]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
            } });
    }
    static rowForAddButtons(id, activityTypeDropdownItem, fieldDropdownItem, fieldSize, ruleCheckStatus, restOfActivity, metadata, companyName, user) {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, restOfActivity), { id,
            activityTypeDropdownItem,
            fieldDropdownItem,
            fieldSize,
            ruleCheckStatus }), metadata), { product: TableDataActivityService.dummyProductForAddProductButton(), equipment: TableDataActivityService.dummyEquipmentForAddEquipmentButton(), companyName,
            user, storeStatus: ENTRY_SYNCED });
    }
    static dummyProductForAddProductButton() {
        return {
            id: '',
            productId: '',
            tstamp: 0,
            productStorageDropdownItem: 'HOT_DISPLAY_BUTTON_RENDERER',
            pesticideIndicationDropdownItem: undefined,
            amountUnitRelative: {
                amount: null,
                unitId: null,
                isFixed: false,
            },
            amountUnitTotal: {
                amount: null,
                unitId: null,
                isFixed: false,
            },
            isLoading: false,
        };
    }
    static dummyEquipmentForAddEquipmentButton() {
        return {
            id: '',
            tstamp: 0,
            dropdownItem: 'HOT_DISPLAY_BUTTON_RENDERER',
        };
    }
    getOptionalPesticideIndicationDropdownItem(activityProduct) {
        const product = this.findProductById(activityProduct.productId);
        if (product &&
            this.isHerbicide(product, this.productCategories) &&
            activityProduct.pestId &&
            activityProduct.pesticideIndicationId) {
            const dropdownItem = this.pesticideIndicationsDropdownItem(activityProduct.productId, activityProduct.pestId, activityProduct.pesticideIndicationId);
            return dropdownItem;
        }
        return undefined;
    }
    getFieldSize(fieldId) {
        if (!fieldId)
            return undefined;
        const field = this.fields[fieldId];
        return field === null || field === void 0 ? void 0 : field.fieldSize;
    }
    getRuleCheckStatus(activityId) {
        var _a;
        const violations = (_a = this.violationsService) === null || _a === void 0 ? void 0 : _a.getViolations(activityId);
        if (violations) {
            const type = TableDataActivityService.getRuleCheckStatusType(violations);
            const tooltipLabel = TableDataActivityService.getRuleCheckTooltipLabel(violations);
            return {
                type,
                tooltipLabel,
            };
        }
        return { type: 'OK', tooltipLabel: null };
    }
    getCompanyName(processOrderId) {
        var _a;
        const { companyId } = this.processOrdersById[processOrderId];
        return (_a = this.companiesById[companyId].abbreviation) !== null && _a !== void 0 ? _a : this.companiesById[companyId].name;
    }
    getUserName(userId) {
        const user = userId ? this.users[userId] : undefined;
        if (!user)
            return undefined;
        return `${user.firstname} ${user.lastname}`;
    }
    static getRuleCheckTooltipLabel(violations) {
        if (!violations || violations.length === 0) {
            return null;
        }
        if (violations.length === 1) {
            return violations[0].shortMessage;
        }
        return 'Es gibt mehrere Warnhinweise';
    }
    static getNotDeletedActivityProducts(products) {
        return products.filter((product) => !product.deleted);
    }
    static getNotDeletedActivityEquipment(equipment) {
        return equipment.filter((equipmentItem) => !equipmentItem.deleted);
    }
}
