// eslint-disable-next-line import/prefer-default-export
export enum ProductCategoryType {
  MINERAL_FERTILIZER = 'femi',
  COMPANY_FERTILIZER = 'feco',
  SECONDARY_FERTILIZER = 'fesec',
  HERBIZIDE = 'herb',
  CROP = 'crop',
  MISCELLANEOUS = 'misc',
  HARVEST = 'harvest',
  SEED = 'seed',
  OTHER_AUXILARY_PRODUCTS_AND_FERTILIZERS = 'feoa',
  WAAD = 'waad',
}
