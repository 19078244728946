import { Activity, ActivityEquipment, ActivityProduct } from 'farmdok-rest-api';

import CompanyService from '@/auth/services/CompanyService';
import { Company } from '@/auth/store/types';
import FieldService from '@/fields/services/FieldService';
import { Product } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';
import { createUuid } from '@/shared/modules/uuid';

import ActivityEquipmentService from './ActivityEquipmentService';
import ActivityProductService from './ActivityProductService';

export default class ActivityService {
  private readonly activities: Data<Activity>;

  private readonly fieldService: FieldService;

  private readonly activityEquipmentService: ActivityEquipmentService;

  private readonly activityProductService: ActivityProductService;

  private readonly companyService: CompanyService;

  constructor(
    activities: Data<Activity>,
    fieldService: FieldService,
    activityEquipmentService: ActivityEquipmentService,
    activityProductService: ActivityProductService,
    companyService: CompanyService,
  ) {
    this.activities = activities;
    this.fieldService = fieldService;
    this.activityEquipmentService = activityEquipmentService;
    this.activityProductService = activityProductService;
    this.companyService = companyService;
  }

  public static createActivity(id?: string): Activity {
    return {
      id: id ?? createUuid(),
      deleted: null,
      tstamp: 0,
      userId: null,
      processOrderId: '',
      trackId: null,
      activityTypeId: '',
      fieldId: null,
      userComment: null,
      timeStart: null,
      files: [],
      timeFinish: null,
      creationDate: null,
      processedArea: null,
      workingWidth: null,
      workingTime: null,
      drivingTime: null,
      setupTime: null,
      pauseTime: null,
      isCopy: false,
      processingState: null,
      completed: false,
      products: [],
      equipment: [],
    };
  }

  // TODO rename to findActivity and return Activity | undefined
  public getActivity(activityId: string): Activity | null {
    const activity = this.activities[activityId];
    return activity ?? null;
  }

  public getCropOfActivity(activityId: string): Product | null {
    const activity = this.activities[activityId];
    if (!activity) throw new Error(`Activity with id ${activityId} not found`);

    const { fieldId } = activity;
    if (!fieldId) return null;

    return this.fieldService.getCropOfField(fieldId);
  }

  public getCompanyOfActivity(activityId: string): Company {
    const activity = this.getActivity(activityId);
    if (!activity) throw new Error(`Activity with id ${activityId} not found`);

    if (!activity.processOrderId) throw Error('Activity must have a process order id');
    return this.companyService.getCompanyByProcessOrder(activity.processOrderId);
  }

  public findActivityEquipmentsOrSiblings(
    activity: Activity,
    referenceActivityEquipment: ActivityEquipment,
  ): ActivityEquipment[] {
    return activity.equipment.filter((e) => this.activityEquipmentService.isSibling(e, referenceActivityEquipment));
  }

  public findActivityProductsOrSiblings(
    activity: Activity,
    referenceActivityProduct: ActivityProduct,
  ): ActivityProduct[] {
    return activity.products.filter((p) => this.activityProductService.isSibling(p, referenceActivityProduct));
  }

  //
  // ENTITY FUNCTIONS START
  // the following functions should be part of Activity Entity
  //
  public static findActivityEquipmentsById(activity: Activity, activityEquipmentIds: string[]): ActivityEquipment[] {
    return activity.equipment.filter((activityEquipment) => activityEquipmentIds.includes(activityEquipment.id));
  }

  public addActivityEquipment(activity: Activity, activityEquipment: ActivityEquipment): void {
    if (!this.isCompatibleActivityEquipment(activity, activityEquipment)) {
      throw new Error(`Activity equipment ${activityEquipment.id} is not compatible with activity`);
    }
    activity.equipment.push(activityEquipment);
  }

  public addActivityProduct(activity: Activity, activityProduct: ActivityProduct): void {
    if (!this.isCompatibleActivityProduct(activity, activityProduct)) {
      throw new Error(`Activity product ${activityProduct.id} is not compatible with activity`);
    }
    activity.products.push(activityProduct);
  }

  private isCompatibleActivityEquipment(activity: Activity, activityEquipment: ActivityEquipment): boolean {
    const companyIdActivityEquipment = this.activityEquipmentService.getCompanyId(activityEquipment);
    if (!companyIdActivityEquipment) return true;

    const companyId = this.companyService.getCompanyByProcessOrder(activity.processOrderId!).id;

    if (companyId !== null && companyId !== companyIdActivityEquipment) {
      return false;
    }

    return true;
  }

  private isCompatibleActivityProduct(activity: Activity, activityProduct: ActivityProduct): boolean {
    const companyIdActivityProduct = this.activityProductService.getCompanyId(activityProduct);
    if (!companyIdActivityProduct) return true;

    const companyId = this.companyService.getCompanyByProcessOrder(activity.processOrderId!).id;

    if (companyId !== null && companyId !== companyIdActivityProduct) {
      return false;
    }

    return true;
  }
}
