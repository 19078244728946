import { GeoObjectType } from 'farmdok-rest-api';
import Vue from 'vue';
import { MutationTree } from 'vuex';

import { removableStore, subscribableStore, syncableStore } from './common';
import { GeoObjectState, HideableSubLayer } from './types';

const mutations: MutationTree<GeoObjectState> = {
  ...subscribableStore.mutations,
  ...syncableStore.mutations,
  ...removableStore.mutations,
  removeMapObject(state, mapObjectId: string) {
    Vue.delete(state.data, mapObjectId);
  },
  setIsCreateActive(state, isCreateActive: boolean) {
    state.isCreateActive = isCreateActive;
  },
  setIsEditActive(state, isEditActive: boolean) {
    state.isEditActive = isEditActive;
  },
  setCurrentObjectType(state, geoObjectType: GeoObjectType) {
    state.currentObjectType = geoObjectType;
  },
  setHiddenSubLayers(state, isolationZoneSubLayers: HideableSubLayer[]) {
    state.hiddenIsolationZoneSubLayers = isolationZoneSubLayers
      .filter((subLayer) => subLayer.hidden)
      .map((subLayer) => subLayer.name);
  },
};

export default mutations;
