var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { BASE_PATH } from '../common';
import { apiOptionsToSearchParams, dataToBase64, urlSearchParamsToUnencodedString } from '../requestUtils';
// eslint-disable-next-line import/prefer-default-export
export function fetchProducts(companies, categoryType, axiosOptions, apiOptions = { itemsPerPage: 5000 }) {
    return __awaiter(this, void 0, void 0, function* () {
        const searchParams = apiOptionsToSearchParams(apiOptions);
        searchParams.append('version', '2.0');
        const filter = getFilter(companies, categoryType);
        searchParams.append('filter', dataToBase64(filter));
        const url = `${BASE_PATH}/material?${urlSearchParamsToUnencodedString(searchParams)}`;
        return axios.get(url, axiosOptions);
    });
}
function getFilter(companies, categoryType) {
    const companyIds = companies.map((company) => company.id);
    const regionIds = companies.map((company) => company.regionId);
    const filter = [
        'AND',
        ['systemOnly', '=', false],
        ['category.type', '=', categoryType],
        ['regionId', 'IN', regionIds],
        ['OR', ['companyId', 'IS', null], ['companyId', 'IN', companyIds]],
    ];
    return filter;
}
