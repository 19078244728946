
import { ActivityProduct, ProductCategoryType } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';

import UnitSelection from '@/activities/modals/createEditActivity/components/UnitSelection.vue';
import { Getters as CreateEditActivityGetters } from '@/activities/modals/createEditActivity/store/getters';
import ActivityProductService from '@/activities/services/ActivityProductService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import ProductService from '@/products/services/ProductService';
import { Product } from '@/shared/api/rest/models';
import { Unit } from '@/shared/api/rest/models/unit';
import { Data } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';
import { Getters as UnitsGetters } from '@/units/store/getters';

export default defineComponent({
  name: 'UnitSelectionContainer',
  components: { UnitSelection },
  props: {
    selectedProduct: {
      type: Object as PropType<Product | null>,
      required: true,
    },
  },
  created() {
    this.$store.dispatch('units/subscribe');
  },
  computed: {
    ...mapState('auth', ['currentCompanies']),
    ...mapState('productCategories', {
      productCategories: 'data',
    }),
    units(): Data<Unit> {
      return this.$store.state.units.data;
    },
    currentActivityProduct(): ActivityProduct | null {
      return this.$store.getters[`activities/createEditActivity/currentActivityProduct`];
    },
    selectedUnitId(): string | null {
      const unitIdWithFallback = this.currentActivityProduct
        ? this.activityProductService.findUnitIdWithFallback(this.currentActivityProduct)
        : null;
      return unitIdWithFallback ?? null;
    },
    unit(): Unit | null {
      if (this.currentActivityProduct?.unitId) {
        return this.units[this.currentActivityProduct.unitId];
      }
      if (this.selectedProduct?.unitId) {
        return this.units[this.selectedProduct.unitId];
      }
      return null;
    },
    productCategoryType(): ProductCategoryType | null {
      if (this.selectedProduct?.categoryId) {
        return this.productCategories[this.selectedProduct.categoryId]?.type || null;
      }
      return null;
    },
    compatibleUnits(): Unit[] | null {
      if (!this.currentCompanies || !this.unit || !this.productCategoryType) {
        return null;
      }
      const compatibleUnits: Unit[] = (this.$store.getters['units/compatibleUnits'] as UnitsGetters['compatibleUnits'])(
        this.unit.groupId,
        this.currentCompanies,
        this.productCategoryType,
      );

      if (compatibleUnits.length === 0) {
        return [this.unit];
      }

      return compatibleUnits;
    },
    productService(): ProductService {
      const rootState = this.$store.state as RootState;
      return new ProductService(
        rootState.products.mineralFertilizers.data,
        rootState.products.companyFertilizers.data,
        rootState.products.secondaryFertilizers.data,
        rootState.products.herbizides.data,
        rootState.products.crops.data,
        rootState.products.miscellaneous.data,
        rootState.products.harvests.data,
        rootState.products.seeds.data,
        rootState.products.otherProductsAndFertilizers.data,
        rootState.products.waads.data,
      );
    },
    activityProductService(): ActivityProductService {
      return new ActivityProductService(this.units, this.productService, calculateAmountsAndUnits);
    },
  },
  methods: {
    updateUnit(unitId: string): void {
      if (this.currentActivityProduct) {
        const sumProcessedArea = this.$store.getters[
          `activities/createEditActivity/sumProcessedArea`
        ] as CreateEditActivityGetters['sumProcessedArea'];

        const updatedActivityProduct = this.activityProductService.updateUnit(
          this.currentActivityProduct,
          unitId,
          sumProcessedArea,
        );
        const unit = updatedActivityProduct.unitId ? this.units[updatedActivityProduct.unitId] : null;
        this.$store.commit(`activities/createEditActivity/updateAmountAndUnitOnActivityProductInAllActivities`, {
          activityProductId: this.currentActivityProduct.id,
          updateValues: {
            amount: updatedActivityProduct.amount,
            unit,
          },
          rootState: this.$store.state,
        });
      }
    },
  },
});
