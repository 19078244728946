import { Activity, ActivityProduct } from 'farmdok-rest-api';
import { GetterTree } from 'vuex';

import AggregationService from '@/activities/modals/createEditActivity/services/AggregationService';
import MergedActivityEquipmentService, {
  MergedActivityEquipment,
} from '@/activities/modals/createEditActivity/services/MergedActivityEquipmentService';
import MergedActivityProductsService, {
  MergedActivityProduct,
} from '@/activities/modals/createEditActivity/services/MergedActivityProductsService';
import ActivityEquipmentService from '@/activities/services/ActivityEquipmentService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import EquipmentService from '@/equipments/services/EquipmentService';
import ProductService from '@/products/services/ProductService';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { RootState } from '@/store/types';

import { CreateEditActivityState } from './types';

export type Getters = {
  activityTimeStarts: number[];
  currentActivityProduct: ActivityProduct | null;
  selectedFields: string[];
  selectedCompanyIds: string[];
  sumProcessedArea: number;
  sumWorkingTime: number;
  sumDrivingTime: number;
  sumSetupTime: number;
  sumPauseTime: number;
  mergedActivityProducts: MergedActivityProduct[];
  mergedActivityEquipment: MergedActivityEquipment[];
};

const moduleGetters: GetterTree<CreateEditActivityState, RootState> = {
  activityTimeStarts: (state) =>
    state.activities.map((activity: Activity) => activity?.timeStart).filter(notNullOrUndefined),
  currentActivityProduct: (state, getters, rootState) => {
    if (!state.currentActivityProductId) return null;
    if (!state.activities) return null;
    const productService = new ProductService(
      rootState.products.mineralFertilizers.data,
      rootState.products.companyFertilizers.data,
      rootState.products.secondaryFertilizers.data,
      rootState.products.herbizides.data,
      rootState.products.crops.data,
      rootState.products.miscellaneous.data,
      rootState.products.harvests.data,
      rootState.products.seeds.data,
      rootState.products.otherProductsAndFertilizers.data,
      rootState.products.waads.data,
    );
    const activityProductService = new ActivityProductService(
      rootState.units.data,
      productService,
      calculateAmountsAndUnits,
    );
    const service = new MergedActivityProductsService(state.activities, rootState.units.data, activityProductService);

    return (
      service.mergedActivityProducts.find((activityProduct) => activityProduct.id === state.currentActivityProductId) ??
      null
    );
  },
  selectedFields: (state) =>
    state.activities.filter((activity) => activity.fieldId).map((activity: Activity) => activity.fieldId),
  selectedCompanyIds: (state, getters, rootState, rootGetters) =>
    state.activities.reduce((acc: string[], activity: Activity) => {
      const companyId = rootGetters['auth/getCompanyByProcessOrderId'](activity.processOrderId)?.id;
      if (!acc.includes(companyId)) {
        acc.push(companyId);
      }
      return acc;
    }, []),
  sumProcessedArea: (state) => {
    const aggregationService = new AggregationService(state.activities);
    return aggregationService.sumProcessedArea;
  },
  sumWorkingTime: (state) => {
    const aggregationService = new AggregationService(state.activities);
    return aggregationService.sumWorkingTime;
  },
  sumDrivingTime: (state) => {
    const aggregationService = new AggregationService(state.activities);
    return aggregationService.sumDrivingTime;
  },
  sumSetupTime: (state) => {
    const aggregationService = new AggregationService(state.activities);
    return aggregationService.sumSetupTime;
  },
  sumPauseTime: (state) => {
    const aggregationService = new AggregationService(state.activities);
    return aggregationService.sumPauseTime;
  },
  mergedActivityProducts: (state, getters, rootState) => {
    const productService = new ProductService(
      rootState.products.mineralFertilizers.data,
      rootState.products.companyFertilizers.data,
      rootState.products.secondaryFertilizers.data,
      rootState.products.herbizides.data,
      rootState.products.crops.data,
      rootState.products.miscellaneous.data,
      rootState.products.harvests.data,
      rootState.products.seeds.data,
      rootState.products.otherProductsAndFertilizers.data,
      rootState.products.waads.data,
    );
    const activityProductService = new ActivityProductService(
      rootState.units.data,
      productService,
      calculateAmountsAndUnits,
    );
    const service = new MergedActivityProductsService(state.activities, rootState.units.data, activityProductService);
    return service.mergedActivityProducts;
  },
  mergedActivityEquipment: (state, getters, rootState) => {
    const equipmentService = new EquipmentService(rootState.equipments.data);
    const activityEquipmentService = new ActivityEquipmentService(equipmentService);
    const service = new MergedActivityEquipmentService(activityEquipmentService);
    return service.mergedActivityEquipment(state.activities);
  },
};

export default moduleGetters;
