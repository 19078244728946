import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import Handsontable from 'handsontable';
import applyMergeRowsBorderStyles from '@/shared/handsontable/rework/features/nestedTable/utils/applyMergeRowsBorderStyles';
import ReadOnlyRenderer from '../../../renderers/ReadOnlyRenderer';
import isFirstRowOfEntry from '../../features/nestedTable/utils/isFirstRowOfEntry';
library.add(faChevronUp, faChevronDown);
export default function ExpandRenderer(instance, td, row, column, prop, value, cellProperties) {
    if (!isRowExpandable(row, instance)) {
        Handsontable.dom.empty(td); // render empty cell
        applyMergeRowsBorderStyles(td, row, instance);
        return;
    }
    // @ts-ignore
    Handsontable.renderers.CheckboxRenderer.apply(this, [instance, td, row, column, prop, value, cellProperties]);
    const checkbox = td.firstChild;
    const iconName = value ? 'chevron-up' : 'chevron-down';
    const [expandIcon] = icon({ prefix: 'fas', iconName }).node;
    td.classList.add('text-black');
    td.classList.add('expand');
    td.insertBefore(expandIcon, checkbox);
    if (cellProperties.readOnly) {
        // @ts-ignore
        ReadOnlyRenderer.apply(this, [instance, td, row, column, prop, value, cellProperties]);
    }
    applyMergeRowsBorderStyles(td, row, instance);
}
function isRowExpandable(row, hot) {
    const rowId = hot.getDataAtRowProp(row, 'id');
    const nextRowId = hot.getDataAtRowProp(row + 1, 'id');
    const isSingleRowEntry = rowId !== nextRowId;
    return isFirstRowOfEntry(row, hot) && !isSingleRowEntry;
}
