import { Activity, ActivityEquipment } from 'farmdok-rest-api';
import _cloneDeep from 'lodash.clonedeep';
import { ActionContext } from 'vuex';

import ActivityEquipmentService from '@/activities/services/ActivityEquipmentService';
import ActivityService from '@/activities/services/ActivityService';
import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../types';
import initServices from './utils/initServices';

export default function updateEquipmentOnActivityEquipmentInAllActivities(
  { state, commit, rootState, rootGetters }: ActionContext<CreateEditActivityState, RootState>,
  data: { activityEquipmentId: string; equipmentId: string },
): void {
  const { activityEquipmentService, companyService, activityService, findService } = initServices(
    state,
    rootState,
    rootGetters,
  );
  const referenceActivityEquipment = _cloneDeep(findService.getActivityEquipment(data.activityEquipmentId));

  state.activities.forEach((activity) => {
    const clonedActivity = _cloneDeep(activity);
    const family = activityService.findActivityEquipmentsOrSiblings(clonedActivity, referenceActivityEquipment);

    if (!activity.processOrderId) throw Error('Activity must have a process order id');
    const companyId = companyService.getCompanyByProcessOrder(activity.processOrderId).id;

    if (family.length > 0) {
      updateEquipments(family, data.equipmentId, companyId, clonedActivity.id, activityEquipmentService, commit);
    } else {
      addNewEquipment(clonedActivity, data.equipmentId, companyId, activityService, activityEquipmentService, commit);
    }
  });
}

function updateEquipments(
  equipment: ActivityEquipment[],
  referenceEquipmentId: string,
  companyId: string,
  activityId: string,
  activityEquipmentService: ActivityEquipmentService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  equipment.forEach((e) => {
    updateEquipment(e, referenceEquipmentId, companyId, activityId, activityEquipmentService, commit);
  });
}

function updateEquipment(
  equipment: ActivityEquipment,
  referenceEquipmentId: string,
  companyId: string,
  activityId: string,
  activityEquipmentService: ActivityEquipmentService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  const updatedEquipment = activityEquipmentService.setEquipmentOrSibling(equipment, referenceEquipmentId, companyId);

  commit('setActivityEquipment', { activityId, activityEquipment: updatedEquipment });
}

function addNewEquipment(
  activity: Activity,
  referenceEquipmentId: string,
  companyId: string,
  activityService: ActivityService,
  activityEquipmentService: ActivityEquipmentService,
  commit: ActionContext<CreateEditActivityState, RootState>['commit'],
) {
  let activityEquipment = ActivityEquipmentService.createActivityEquipment();
  activityEquipment = activityEquipmentService.setEquipmentOrSibling(
    activityEquipment,
    referenceEquipmentId,
    companyId,
  );
  activityService.addActivityEquipment(activity, activityEquipment);
  commit('addActivityEquipment', { activityEquipment: activity.equipment.slice(-1)[0], activityId: activity.id });
}
