<template>
  <ThePageSkeleton
    :page-feature-title="$router.currentRoute.meta.label"
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #sidebar>
      <slot name="headerLogo" />
      <Navigation
        :workflowKey="workflowKey"
        :stepCount="stepCount"
        @onNavigationNext="(callback) => $emit('onNavigationNext', callback)"
      />
      <slot name="steps" />
    </template>
    <template #content>
      <div id="application-maps__map-wrapper" class="application-maps__map-wrapper">
        <MapItemTooltip
          v-if="map != null && polygonHovered != null"
          :key="polygonHovered"
          class="application-maps__map-tooltip"
          :map="map"
          :polygon="polygons[polygonHovered]"
        >
          <div class="text-left">
            <ul class="application-maps__map-tooltip-list">
              <li class="application-maps__map-tooltip-list-item">
                {{ polygonHoveredData.name }}
              </li>
              <li class="application-maps__map-tooltip-list-item" v-if="polygonHoveredData.crop != null">
                {{ polygonHoveredData.crop }}
              </li>
              <li class="application-maps__map-tooltip-list-item" v-if="polygonHoveredData.size != null">
                {{ polygonHoveredData.size }}
              </li>
            </ul>
          </div>
        </MapItemTooltip>

        <template v-if="map">
          <template v-for="feature in allFeatures">
            <GoogleMapsPolygon
              :key="`polygon-${feature.heatmapKey}:${feature.featureIndex}:${feature.geometry.coordinates[0]}`"
              :map="map"
              :paths="toGoogleMapsPolygon(feature.geometry.coordinates)"
              :fillColor="feature.properties.customColor ?? feature.properties.fill"
              :fillOpacity="1"
              :strokeWeight="0"
              :zIndex="10"
              :visible="true"
              @click="activeHeatmapFeature = feature"
            />

            <GoogleMapsLabel
              v-if="['seeding', 'fertilization', 'spraying'].includes(workflowKey)"
              :map="map"
              :key="`label-${feature.heatmapKey}-${feature.featureIndex}`"
              :position="featureCenter(feature)"
              :skewed="false"
              :set="(zone = zoneByHexCode(feature.properties.customColor ?? feature.properties.fill))"
              :label="format(valueByWorkflow(zone))"
              fillColor=""
              textColor="#FFFFFF"
              :zIndex="10"
              :visible="true"
            />
          </template>

          <GoogleMapsOverlay
            v-if="activeHeatmapFeature && zonesAreLoaded"
            :map="map"
            :position="featureCenter(activeHeatmapFeature)"
            :skewed="true"
            :fillColor="'#FFFFFF'"
            :visible="true"
            :zIndex="12"
          >
            <FormFieldDropdown
              :items="dropdownCategories"
              v-model="selectedZone"
              colorCoded
              label="Select custom zone value"
              variant="vertical"
              disableDelete
            />

            <BButtonGroup class="d-flex flex-row">
              <BButton
                size="sm"
                variant="primary"
                class="w-50"
                @click="updateFeatureValue"
                :disabled="selectedZone.id === activeHeatmapFeature.customColor"
                >{{ $t('OK') }}
              </BButton>
              <BButton size="sm" variant="white" class="w-50" @click="hideOverlay">
                {{ $t('Abbrechen') }}
              </BButton>
            </BButtonGroup>
          </GoogleMapsOverlay>
        </template>

        <div class="application-maps__map-container" ref="map-container"></div>
      </div>
    </template>
    <template #featureNotEnabled>
      <MaxWidthContainer left size="xl">
        <h2 class="my-4">{{ $t('Jetzt upgraden auf FARMDOK PERFORMANCE!') }}</h2>
        <p>
          <!-- eslint-disable max-len -->
          {{
            $t(
              'Steige um auf FARMDOK PERFORMANCE und du erhältst Zugang zu FARMDOK Monitoring. Die Anwendung ermöglicht es Vegetations­unterschiede teilflächenspezifisch in der Karte darzustellen und den zeitlichen Verlauf zu analysieren.',
            )
          }}
          <!-- eslint-enable max-len -->
        </p>
        <p>
          <!-- eslint-disable max-len -->
          {{
            $t(
              'Für die Auswertung werden Daten der Sentinel Erdbeobachtungs­satelliten verwendet. Satelliten- & Wetterdaten zeigen Biomassezuwachs (NDVI- und MSAVI2-Index), Stickstoffverbrauch (REIP-Index) und Wassergehalt (NDWI-Index) auf. Sie sind die optimale Unterstützung für effektive Produktionsentscheidungen.',
            )
          }}
          <!-- eslint-enable max-len -->
        </p>
        <div class="row my-4">
          <div class="col-4">
            <img src="./assets/precision_01.png" alt="Application maps preview" class="w-100" />
          </div>
          <div class="col-4">
            <img src="./assets/precision_02.png" alt="Application maps preview" class="w-100" />
          </div>
          <div class="col-4">
            <img src="./assets/precision_03.png" alt="Application maps preview" class="w-100" />
          </div>
        </div>
        <Button
          leading-icon
          variant="danger"
          class="d-print-none"
          :icon="['far', 'shopping-cart']"
          @click="() => $router.push({ name: 'shop' })"
        >
          Zum Shop
        </Button>
      </MaxWidthContainer>
    </template>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingCart as faShoppingCartRegular } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import * as turf from '@turf/turf';
import numbro from 'numbro';
import { mapGetters, mapState } from 'vuex';

import GoogleMapsLabel from '@/fields/components/GoogleMapsLabel.vue';
import GoogleMapsOverlay from '@/fields/components/GoogleMapsOverlay.vue';
import GoogleMapsPolygon from '@/fields/components/GoogleMapsPolygon.vue';
import { combinedNameExclCrop } from '@/fields/handsontable/columns/columns';
import calculatePolygonCenter from '@/fields/utils/calculatePolygonCenter';
import toGoogleMapsPath from '@/fields/utils/toGoogleMapsPath';
import toGoogleMapsPoint from '@/fields/utils/toGoogleMapsPoint';
import toGoogleMapsPolygon from '@/fields/utils/toGoogleMapsPolygon';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import Button from '@/shared/components/buttons/Button.vue';
import FormFieldDropdown from '@/shared/components/form/FormFieldDropdown.vue';
import MapItemTooltip from '@/shared/components/map/MapItemTooltip.vue';
import { GOOGLE_MAPS_SETTINGS } from '@/shared/constants';
import googleMapsApi from '@/shared/modules/googleMapsApi';
import isUnique from '@/shared/modules/isUniqueFilter';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import Navigation from './components/Navigation.vue';
import {
  POLYGON_STATES,
  ZONE_GENERATION_MODE_SATELLITE,
  ZONE_GENERATION_MODE_UPLOAD,
} from './store/baseWorkflowStore/common';

library.add(faChevronLeft, faChevronRight, faShoppingCartRegular);

let google = null;

export default {
  name: 'PageApplicationMaps',
  components: {
    GoogleMapsPolygon,
    FormFieldDropdown,
    GoogleMapsOverlay,
    GoogleMapsLabel,
    ThePageSkeleton,
    Navigation,
    MapItemTooltip,
    MaxWidthContainer,
    Button,
  },
  props: {
    noContentbar: {
      type: Boolean,
      default: false,
    },
    workflowKey: {
      type: String,
      required: true,
    },
    stepCount: {
      type: Number,
      required: true,
    },
  },
  inject: {
    googleMapsSettings: {
      default: () => GOOGLE_MAPS_SETTINGS,
    },
  },
  data() {
    return {
      map: null,
      polygonHovered: null,
      storeUnsubscribe: null,
      activeHeatmapFeature: undefined,
      heatmapClickListener: null,
      selectedZone: { id: '', name: '' },
    };
  },
  computed: {
    ...mapGetters({
      fields: 'fields',
    }),
    ...mapState({
      paginationStep(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].paginationStep;
      },
      zoneGenerationMode(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].zoneGenerationMode;
      },
      fieldsWithHeatmap(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/fieldsWithHeatmap`];
      },
      polygons(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].polygons;
      },
      heatmaps(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/currentHeatmaps`];
      },
      uploadedZonesByFilename(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/uploadedZonesByFilename`];
      },
      zones(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/zones`];
      },
      zoneDosage(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/zoneDosage`];
      },
      selectedIndexType(state) {
        return state.precisionFarming.applicationMaps[this.workflowKey].selectedIndexType;
      },
      heatmapsOfSelectedFields(state, getters) {
        return getters[`precisionFarming/applicationMaps/${this.workflowKey}/heatmapsOfSelectedFields`];
      },
      ...mapGetters('precisionFarming/applicationMaps/spraying', ['reducedSprayMixPerZone']),
    }),
    allFeatures() {
      return this.heatmapsOfSelectedFields.flatMap((heatmap) => heatmap.features);
    },
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(
        availableFeatures.FEATURE_APPLICATION_MAPS_FERTILIZATION_FD_BASIC,
      );
    },
    selectedFields: {
      get() {
        return this.$store.state.precisionFarming.applicationMaps[this.workflowKey].selectedFields;
      },
      set(selected) {
        this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/setSelectedFields`, selected);
      },
    },
    selectedFieldsData() {
      return Object.entries(this.fields)
        .filter(([key]) => this.selectedFields.includes(key))
        .reduce((fields, [key, value]) => {
          // eslint-disable-next-line no-param-reassign
          fields[key] = value;
          return fields;
        }, {});
    },
    /**
     * Return meta data of currently hovered polygon. This info should update on mouseover of another polygon.
     *
     * @return {{color: *, size: string, name: *, crop: *}|{color: string, size: string, name: string, crop: string}}
     */
    polygonHoveredData() {
      if (this.polygonHovered == null || this.polygons[this.polygonHovered] == null) {
        return {
          color: '',
          name: '',
          crop: '',
          size: '',
        };
      }
      const { lineChartColor: color, name } = this.polygons[this.polygonHovered];
      let { crop, size } = this.polygons[this.polygonHovered];
      if (crop != null) {
        crop = crop.name;
      }
      if (size == null) {
        size = google.maps.geometry.spherical.computeArea(this.polygons[this.polygonHovered].getPath()) / 10000;
      }
      size = this.$t('{size} ha', { size: numbro(size).format() });
      return {
        color,
        name,
        crop,
        size,
      };
    },
    zonesAreLoaded() {
      const supportedWorkflow = ['fertilization', 'spraying', 'seeding'].includes(this.workflowKey);
      return this.paginationStep === 2 && supportedWorkflow && !!this.zones.length;
    },
    dropdownCategories() {
      const zoneItems = this.zones.map(this.toDropdownItem);
      return [
        {
          name: 'Zones',
          id: '0',
          items: zoneItems,
          sort: false,
        },
      ];
    },
    pointsInFocus() {
      return (this.selectedFields || this.fieldsWithHeatmap)
        .filter(isUnique)
        .map((key) => this.polygons[key])
        .filter(notNullOrUndefined)
        .map((polygon) => polygon.getPath())
        .flatMap((path) => path.getArray());
    },
  },
  watch: {
    paginationStep() {
      if (this.paginationStep === 1 || this.paginationStep === 2) {
        this.polygonsRedraw();
        this.uploadedZonesRedraw();
      }

      if (this.paginationStep !== 2) {
        this.hideOverlay();
      }
    },
    zoneGenerationMode() {
      this.polygonsRedraw();
      this.uploadedZonesRedraw();
    },
    selectedFields(selectedKeys, oldSelectedKeys) {
      this.selectedFieldsOnUpdate(selectedKeys, oldSelectedKeys);
    },
    polygons() {
      this.polygonsRedraw();
    },
    heatmapsOfSelectedFields: {
      deep: true,
      handler() {
        this.polygonsRedraw();
      },
    },
    uploadedZonesByFilename() {
      this.polygonsRedraw();
      this.uploadedZonesRedraw();
    },
    pointsInFocus(points, oldPoints) {
      // skip centering when no points are provided
      if (points.length > oldPoints.length) {
        const bounds = new google.maps.LatLngBounds();
        points.forEach((point) => bounds.extend(point));
        this.map?.fitBounds(bounds, 60);
      }
    },
    activeHeatmapFeature(current) {
      if (!current) {
        return;
      }
      const color = current.properties.customColor ?? current.properties.fill;
      const zone = this.zoneByHexCode(color);
      this.selectedZone = this.toDropdownItem(zone);
    },
  },
  mounted() {
    this.storeUnsubscribe = this.$store.subscribe(({ type }) => {
      if (type === 'afterResetData') {
        this.mapInit();
      }
    });
    this.mapInit();
  },
  beforeDestroy() {
    google.maps.event.removeListener(this.heatmapClickListener);
    this.storeUnsubscribe();
  },
  methods: {
    toGoogleMapsPolygon,
    toGoogleMapsPath,
    toGoogleMapsPoint,
    calculatePolygonCenter,
    hideOverlay() {
      this.activeHeatmapFeature = undefined;
    },
    zoneByHexCode(color) {
      return this.zones?.find((zone) => zone.color === color);
    },
    toDropdownItem(zone) {
      return zone
        ? {
            id: zone.color,
            name: this.format(this.valueByWorkflow(zone)),
            value: zone.rate,
          }
        : { id: '', name: '', value: 0 };
    },
    updateFeatureValue() {
      const feature = { ...this.activeHeatmapFeature };
      feature.vegetation.custom_value_q = this.selectedZone.value;
      feature.properties.customColor = this.selectedZone.id;
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/updateFeature`, feature);
      this.hideOverlay();
    },
    format(number) {
      const noDecimals = this.selectedIndexType === 'REIP' || this.workflowKey === 'seeding';

      return numbro(number ?? 0).format({
        thousandSeparated: true,
        mantissa: noDecimals ? 0 : 2,
      });
    },
    featureCenter(feature) {
      const polygon = turf.polygon(feature.geometry.coordinates);
      // Hypothesis 1: Centroid
      let pointToUse = turf.centroid(polygon);

      if (!turf.booleanPointInPolygon(pointToUse, polygon)) {
        // Hypothesis 2: Intersect horizontal ray with polygon
        // Create a horizontal line (ray) that passes through the centroid
        const rayLine = turf.lineString([
          [pointToUse.geometry.coordinates[0] - 0.1, pointToUse.geometry.coordinates[1]], // 0.1 degrees to the left
          [pointToUse.geometry.coordinates[0] + 0.1, pointToUse.geometry.coordinates[1]], // and to the right
        ]);

        // Find intersection points between the ray and the polygon
        const intersections = turf.lineIntersect(rayLine, polygon);

        if (intersections.features.length >= 2) {
          // Sort the intersection points by their longitude (x-coordinate)
          intersections.features.sort((a, b) => a.geometry.coordinates[0] - b.geometry.coordinates[0]);

          // Attempt with the two leftmost points
          const centerLeft = turf.midpoint(intersections.features[0], intersections.features[1]);

          if (turf.booleanPointInPolygon(centerLeft, polygon)) {
            pointToUse = centerLeft;
          } else if (intersections.features.length > 2) {
            // Attempt with the two rightmost points if there are more than two intersection points
            const centerRight = turf.midpoint(
              intersections.features[intersections.features.length - 2],
              intersections.features[intersections.features.length - 1],
            );

            if (turf.booleanPointInPolygon(centerRight, polygon)) {
              pointToUse = centerRight;
            } else {
              // Fallback to pointOnFeature if neither center point is within the polygon
              pointToUse = turf.pointOnFeature(polygon);
            }
          } else {
            // If there are exactly two points but the center isn't inside, also fallback
            pointToUse = turf.pointOnFeature(polygon);
          }
        } else {
          // Fallback if less than two intersection points are found
          pointToUse = turf.pointOnFeature(polygon);
        }
      }

      return this.toGoogleMapsPoint(pointToUse.geometry.coordinates);
    },
    /**
     * Load google maps api.
     * Render a new google map.
     * Load all fields from backend.
     * Then trigger draw of all field-polygons, heatmaps and uploaded zones.
     */
    async mapInit() {
      if (!this.currentCompaniesHaveFeature.enabled) {
        return;
      }
      google = await googleMapsApi();
      const options = {
        tilt: 0,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        disableDefaultUI: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.TOP_RIGHT,
          mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        fullscreenControl: false,
        isFractionalZoomEnabled: true,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
        ],
      };
      this.map = new google.maps.Map(this.$refs['map-container'], options);
      await this.initFields();
      this.map.addListener('click', () => {
        this.hideOverlay();
      });
    },
    async initFields() {
      await this.$store.dispatch('auth/subscribe');
      await this.$store.dispatch('fields/subscribe');
      const bounds = new google.maps.LatLngBounds();
      Object.keys(this.fields).forEach((key) => {
        const field = this.fields[key];
        if (
          field.fieldContour == null ||
          field.fieldContour.geoJson == null ||
          field.fieldContour.geoJson.type !== 'Polygon'
        ) {
          return;
        }
        const { geoJson } = field.fieldContour;
        const fieldInfo = {
          name: combinedNameExclCrop.data.call(this, field),
          crop: field.crop,
          size: field.fieldSize,
        };
        const { lat, lon } = field;
        const latLng = {
          lat,
          lon,
        };
        this.polygonAdd(key, latLng, geoJson, fieldInfo);
        geoJson.coordinates[0].forEach((point) => bounds.extend({ lng: point[0], lat: point[1] }));
      });
      if (Object.values(this.fields).length > 0) {
        this.mapFitBounds({ bounds });
      } else {
        this.map.setOptions(this.googleMapsSettings.GOOGLE_MAPS_DEFAULT_CENTER);
      }

      this.selectedFieldsOnUpdate(this.selectedFields, []);
      this.$nextTick(() => {
        this.uploadedZonesRedraw();
      });
    },
    /**
     * Update google maps position and zoom to fit the bounds (used for initial field rendering).
     *
     * @param bounds
     * @param maxZoom
     */
    mapFitBounds({ bounds, maxZoom = 16, usePan = false }) {
      if (!this.map) return;

      google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
        this.map?.setOptions({ maxZoom: null });
      });
      this.map.setOptions({ maxZoom });
      if (usePan) {
        this.map.setZoom(maxZoom);
        this.map.panToBounds(bounds, 500);
      } else {
        this.map.fitBounds(bounds);
      }
    },
    selectedFieldsOnUpdate(selectedKeys, oldSelectedKeys) {
      const selectArray = selectedKeys.filter((key) => !oldSelectedKeys.includes(key) && this.polygons[key]);
      const deselectArray = oldSelectedKeys.filter((key) => !selectedKeys.includes(key) && this.polygons[key]);

      selectArray.forEach((key) => {
        this.activatePolygon(key);
      });
      deselectArray.forEach((key) => {
        this.deactivatePolygon(key);
      });
    },
    /**
     * Create a new polygon for the given field key and add it to the map and store.
     *
     * @param key
     * @param latLng
     * @param geoJson
     * @param fieldInfo
     */
    polygonAdd(key, latLng, geoJson, fieldInfo) {
      const polygon = new google.maps.Polygon({
        ...fieldInfo,
        field_key: key,
        paths: geoJson.coordinates[0].map((point) => ({ lng: point[0], lat: point[1] })),
        strokeColor: this.googleMapsSettings.POLYGON_VISIBLE_STROKE_COLOR,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: this.googleMapsSettings.POLYGON_VISIBLE_FILL_COLOR,
        fillOpacity: 0.3,
        state: POLYGON_STATES.INACTIVE,
        lat: latLng.lat,
        lon: latLng.lon,
      });
      [polygon.pathsForAxios] = geoJson.coordinates;

      google.maps.event.addListener(polygon, 'click', () => {
        // allow selecting fields from inside the map in step 1 only
        if (this.paginationStep === 1) {
          const polygonState = this.polygons[key].state;
          this.polygonSwitchState(key, polygonState);
        }
      });
      google.maps.event.addListener(polygon, 'mouseover', () => {
        this.polygonHovered = key;
      });
      google.maps.event.addListener(polygon, 'mouseout', () => {
        if (this.polygonHovered === key) {
          this.polygonHovered = null;
        }
      });
      this.$store.commit(`precisionFarming/applicationMaps/${this.workflowKey}/addPolygon`, polygon);
    },
    /**
     * Add all polygons to google map + update their options according to state.
     */
    polygonsRedraw() {
      Object.values(this.polygons).forEach((polygon) => {
        const { state } = polygon;
        let polygonOptions = {};
        if (state === POLYGON_STATES.ACTIVE) {
          polygonOptions = {
            strokeColor: this.googleMapsSettings.POLYGON_ACTIVE_STROKE_COLOR,
            fillColor: this.googleMapsSettings.POLYGON_ACTIVE_FILL_COLOR,
            fillOpacity: 0.3,
            strokeOpacity: 1,
          };
          if (
            this.zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE &&
            this.fieldsWithHeatmap.includes(polygon.field_key)
          ) {
            polygonOptions.fillOpacity = 0;
            polygonOptions.strokeOpacity = 0;
          }
        } else if (state === POLYGON_STATES.INACTIVE) {
          polygonOptions = {
            fillColor: this.googleMapsSettings.POLYGON_VISIBLE_STROKE_COLOR,
            strokeColor: this.googleMapsSettings.POLYGON_VISIBLE_FILL_COLOR,
            fillOpacity: 0.3,
            strokeOpacity: 1,
          };
        }
        polygon.setMap(this.map);
        polygon.setOptions(polygonOptions);
      });
    },
    deactivatePolygon(key) {
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/polygonSetState`, {
        key,
        state: POLYGON_STATES.INACTIVE,
      });
    },
    addToSelected(key) {
      if (!this.selectedFields.includes(key)) {
        this.selectedFields = [...this.selectedFields, key];
      }
    },
    removeFromSelected(key) {
      if (this.selectedFields.includes(key)) {
        this.selectedFields = this.selectedFields.filter((fieldKey) => fieldKey !== key);
      }
    },
    activatePolygon(key) {
      this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/polygonSetState`, {
        key,
        state: POLYGON_STATES.ACTIVE,
      });
    },
    polygonSwitchState(key, state) {
      if (state === POLYGON_STATES.INACTIVE) {
        this.addToSelected(key);
      } else {
        this.removeFromSelected(key);
      }
    },
    /**
     * Remove all map features (heatmaps and/or uploaded zones) and then re-add all uploaded zones.
     */
    uploadedZonesRedraw() {
      if (this.zoneGenerationMode !== ZONE_GENERATION_MODE_UPLOAD) {
        return;
      }
      this.map.data.forEach((feature) => {
        this.map.data.remove(feature);
      });
      Object.values(this.uploadedZonesByFilename).forEach((geoJson) => {
        this.map.data.addGeoJson(geoJson);
        this.map.data.setStyle((feature) => {
          const fillColor = feature.getProperty('fill');
          return {
            fillColor,
            strokeColor: 'transparent',
            fillOpacity: 0.8,
            zIndex: 10,
          };
        });
      });
    },
    valueByWorkflow(zone) {
      if (!zone) {
        return 0;
      }

      const zoneIndex = this.zones.findIndex((z) => z === zone);

      switch (this.workflowKey) {
        case 'seeding':
          return zone.manualSeedRate ?? zone.seedRateWithLossCompensation;
        case 'fertilization':
          return this.paginationStep === 2 ? zone.rate : this.zoneDosage[zoneIndex].dosage;
        case 'fertilizationCereals':
          return 0;
        case 'spraying':
          return this.paginationStep === 2 ? zone.rate : this.reducedSprayMixPerZone[zoneIndex];
        default:
          throw new Error('Workflow is unknown');
      }
    },
  },
};
</script>

<style scoped>
.application-maps__map-wrapper {
  position: relative;
  flex: 1;
  height: 100%;
}

.application-maps__map-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.application-maps__map-tooltip-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.application-maps__map-tooltip-list-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.d-flex {
  column-gap: 1em;
}
</style>
