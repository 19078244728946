import { getCurrentInstance, onBeforeMount } from 'vue';
export default function useToast() {
    let app;
    onBeforeMount(() => {
        app = getCurrentInstance();
    });
    function toast(message, variant = 'danger') {
        if (app) {
            app.proxy.$bvToast.toast(message, { title: 'Warning', variant });
        }
    }
    return {
        toast,
    };
}
