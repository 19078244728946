import { ImportActivity } from 'farmdok-rest-api';
import { GetterTree } from 'vuex';

import tableDataActivitiesImportService from '@/activities/importActivities/services/TableDataActivitiesImportService';
import { TableDataImportActivity } from '@/activities/importActivities/types/TableDataImportActivity.types';
import isImportActivityValid from '@/activities/importActivities/utils/isImportActivityValid';
import ProductService from '@/products/services/ProductService';
import { SubscribableGetters } from '@/shared/mixins/store/subscribableData/getters';
import { RootState } from '@/store/types';

import { subscribableStore } from './common';
import { ImportActivitiesState, ImportActivityWithId } from './types';

const { convertToActivitiesImportTableData } = tableDataActivitiesImportService();

export type Getters = SubscribableGetters<ImportActivityWithId> & {
  selectedImportActivityIds: string[];
  tableDataImportActivities: TableDataImportActivity[];
  isImportActivityValid: (importActivityId: string) => boolean;
};

const moduleGetters: GetterTree<ImportActivitiesState, RootState> = {
  ...subscribableStore.getters,

  selectedImportActivityIds: (state: ImportActivitiesState): string[] => {
    const importActivityIds = Object.keys(state.data);
    return importActivityIds.filter(
      (importActivityId: string) => state.tableDataMetadata[importActivityId]?.select ?? true,
    );
  },

  tableDataImportActivities: (
    state: ImportActivitiesState,
    getters: Getters,
    rootState: RootState,
    rootGetters,
  ): TableDataImportActivity[] => {
    const regionCodes: string[] = rootGetters['auth/currentCompaniesRegionCodes'];
    if (regionCodes.length > 1) throw new Error('Multiple region codes not supported');

    const importActivities = Object.values(state.data);

    const productService = new ProductService(
      rootState.products.mineralFertilizers.data,
      rootState.products.companyFertilizers.data,
      rootState.products.secondaryFertilizers.data,
      rootState.products.herbizides.data,
      rootState.products.crops.data,
      rootState.products.miscellaneous.data,
      rootState.products.harvests.data,
      rootState.products.seeds.data,
      rootState.products.otherProductsAndFertilizers.data,
      rootState.products.waads.data,
    );

    return convertToActivitiesImportTableData(
      importActivities,
      rootState.activities.importActivities.tableDataMetadata,
      rootGetters['products/loading'],
      rootState.fields.data,
      rootState.users.data,
      rootState.units.data,
      rootState.equipments.data,
      rootState.auth.companiesById,
      productService,
      rootGetters['activityTypes/dropdownItem'],
      rootGetters['fields/dropdownItem'],
      rootGetters['auth/processOrdersById'],
      rootGetters['products/dropdownItem'],
      rootGetters['activities/findSimilarActivitiesForImportActivity'],
    );
  },
  isImportActivityValid:
    (state: ImportActivitiesState, getters: Getters) =>
    (importActivityId: string): boolean => {
      const importActivity = getters.findById(importActivityId) as ImportActivity;
      return isImportActivityValid(importActivity);
    },
};

export default moduleGetters;
