import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import initialState from './state';
const vuexModule = {
    namespaced: true,
    state: initialState(),
    mutations,
    actions,
    getters,
};
export default vuexModule;
