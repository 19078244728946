<template>
  <ThePageSkeleton
    :page-feature-title="$router.currentRoute.meta.label"
    :page-feature-enabled="currentCompaniesHaveFeature.enabled"
    :page-feature-visible="currentCompaniesHaveFeature.visible"
  >
    <template #contentbar>
      <TheContentbar v-if="!noContentbar">
        <template #right="{ contentbarLarge }">
          <ProcessOrderSwitch class="ml-4" :slim="!contentbarLarge" />
          <CompanySwitch class="ml-4" :slim="!contentbarLarge" />
        </template>
      </TheContentbar>
    </template>
    <template #sidebar>
      <SidebarFields
        tableId="sidebar-table-fields-monitoring"
        allowFieldsWithNoContour
        :selectedRows.sync="selectedFields"
      />
    </template>
    <template #content>
      <div id="monitoring__map-wrapper" class="monitoring__map-wrapper">
        <MapItemTooltip
          v-if="map != null"
          class="monitoring__map-tooltip"
          :map="map"
          :polygon="polygons[hoveredPolygon]"
        >
          <div class="text-left">
            <ul class="monitoring__map-tooltip-list">
              <li class="monitoring__map-tooltip-list-item">
                <span
                  v-if="fieldsForOverlay.length > 1 && hoveredPolygonData.color != null"
                  class="monitoring__map-tooltip-list-item-color"
                >
                  <span :style="{ background: hoveredPolygonData.color }"></span>
                </span>
                {{ hoveredPolygonData.name }}
              </li>
              <li class="monitoring__map-tooltip-list-item" v-if="hoveredPolygonData.crop != null">
                {{ hoveredPolygonData.crop }}
              </li>
              <li class="monitoring__map-tooltip-list-item" v-if="hoveredPolygonData.size != null">
                {{ hoveredPolygonData.size }}
              </li>
            </ul>
          </div>
        </MapItemTooltip>
        <div class="monitoring__map-container" ref="map-container"></div>
        <div class="monitoring__map-legend bg-lightest">
          <div v-for="entry in legendEntries" :key="entry.label" :style="entry.style">{{ entry.label }}</div>
        </div>
        <div v-show="mapLoader.visible" class="monitoring__map-loader">
          <FontAwesomeIcon
            class="monitoring__map-loader-svg text-primary"
            icon="circle-notch"
            spin
            :style="mapLoader.position"
          />
        </div>
        <MonitoringOverlay v-if="overlay.loading || fieldsForOverlay.length > 0" class="visible">
          <template v-slot:header>
            <h3 class="h5 font-weight-bold mb-0">{{ $t('Monitoring') }}</h3>
          </template>
          <template v-slot:main>
            <IndexGraph
              :navigationDate="navigationDate"
              :hoveredPolygon="hoveredPolygon"
              @update:navigationDate="(date) => (navigationDate = date)"
            />
            <WeatherInfo
              v-if="!overlay.folded"
              variant="compact"
              :selectedField="selectedFieldForWeather"
              :navigationDate="navigationDate"
              @update:navigationDate="(date) => (navigationDate = date)"
            />
          </template>
        </MonitoringOverlay>
      </div>
    </template>
    <template #featureNotEnabled>
      <MaxWidthContainer left size="xl">
        <h2 class="my-4">{{ $t('Jetzt upgraden auf FARMDOK PERFORMANCE!') }}</h2>
        <p>
          <!-- eslint-disable max-len -->
          {{
            $t(
              'Steige um auf FARMDOK PERFORMANCE und du erhältst Zugang zu FARMDOK Monitoring. Die Anwendung ermöglicht es Vegetations­unterschiede teilflächenspezifisch in der Karte darzustellen und den zeitlichen Verlauf zu analysieren.',
            )
          }}
          <!-- eslint-enable max-len -->
        </p>
        <p>
          <!-- eslint-disable max-len -->
          {{
            $t(
              'Für die Auswertung werden Daten der Sentinel Erdbeobachtungs­satelliten verwendet. Satelliten- & Wetterdaten zeigen Biomassezuwachs (NDVI- und MSAVI2-Index), Stickstoffverbrauch (REIP-Index) und Wassergehalt (NDWI-Index) auf. Sie sind die optimale Unterstützung für effektive Produktionsentscheidungen.',
            )
          }}
          <!-- eslint-enable max-len -->
        </p>
        <div class="row my-4">
          <div class="col-4">
            <img src="./assets/crop_monitoring_01.png" alt="Vegetation monitoring preview" class="w-100" />
          </div>
          <div class="col-4">
            <img src="./assets/crop_monitoring_02.png" alt="Vegetation monitoring preview" class="w-100" />
          </div>
          <div class="col-4">
            <img src="./assets/crop_monitoring_03.png" alt="Vegetation monitoring preview" class="w-100" />
          </div>
        </div>
        <Button
          leading-icon
          variant="danger"
          class="d-print-none"
          :icon="['far', 'shopping-cart']"
          @click="() => $router.push({ name: 'shop' })"
        >
          Zum Shop
        </Button>
      </MaxWidthContainer>
    </template>
  </ThePageSkeleton>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import axios from 'axios';
import numbro from 'numbro';
import { mapGetters } from 'vuex';

import SidebarFields from '@/fields/components/SidebarFields.vue';
import { combinedNameExclCrop } from '@/fields/handsontable/columns/columns';
import TheContentbar from '@/layout/components/TheContentbar.vue';
import ThePageSkeleton from '@/layout/components/ThePageSkeleton.vue';
import CompanySwitch from '@/shared/components/CompanySwitch.vue';
import MaxWidthContainer from '@/shared/components/MaxWidthContainer.vue';
import ProcessOrderSwitch from '@/shared/components/ProcessOrderSwitch.vue';
import Button from '@/shared/components/buttons/Button.vue';
import MapItemTooltip from '@/shared/components/map/MapItemTooltip.vue';
import WeatherInfo from '@/shared/components/weather/WeatherInfo.vue';
import { GOOGLE_MAPS_SETTINGS } from '@/shared/constants';
import { hexToHsl } from '@/shared/modules/colorHelpers';
import getRandomColor from '@/shared/modules/getRandomColor';
import googleMapsApi from '@/shared/modules/googleMapsApi';
import isUnique from '@/shared/modules/isUniqueFilter';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

import IndexGraph from './components/IndexGraph.vue';
import MonitoringOverlay from './components/MonitoringOverlay.vue';

library.add(faCircleNotch);

export default {
  name: 'PageMonitoring',
  components: {
    ThePageSkeleton,
    TheContentbar,
    SidebarFields,
    MonitoringOverlay,
    IndexGraph,
    WeatherInfo,
    MapItemTooltip,
    CompanySwitch,
    ProcessOrderSwitch,
    MaxWidthContainer,
    Button,
  },
  props: {
    noContentbar: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    googleMapsSettings: {
      default: () => GOOGLE_MAPS_SETTINGS,
    },
    polygonStates: {
      default: {
        INACTIVE: 'inactive',
        ACTIVE: 'active',
        HIDDEN: 'hidden',
        HEATMAP: 'heatmap',
      },
    },
    heatmapStates: {
      default: {
        INACTIVE: 'inactive',
        ACTIVE: 'active',
        LOADING: 'loading',
        NONE: 'none',
      },
    },
  },
  data() {
    return {
      storeUnsubscribe: null,
      google: null,
      map: null,
      markers: {},
      externalFieldsCount: 0,
      navigationDate: new Date(),
      initialLoaded: false,
      fieldsForOverlayLoading: false,
      mapLoader: {
        visible: false,
        position: {
          top: 0,
          left: 0,
        },
      },
      hoveredPolygon: null,
    };
  },
  computed: {
    ...mapGetters({
      fields: 'fields',
      polygons: 'precisionFarming/monitoring/polygons',
      overlay: 'precisionFarming/monitoring/overlay',
      currentHeatmaps: 'precisionFarming/monitoring/currentHeatmaps',
      vegetationData: 'precisionFarming/monitoring/vegetationData',
    }),
    currentCompaniesHaveFeature() {
      return this.$store.getters.currentCompaniesHaveFeature(availableFeatures.FEATURE_VEGETATION_MONITORING);
    },
    selectedFields: {
      get() {
        return this.$store.state.precisionFarming.monitoring.selectedFields;
      },
      set(selected) {
        this.$store.commit('precisionFarming/monitoring/setSelectedFields', selected);
      },
    },
    fieldsForOverlay() {
      return Object.values(this.polygons).filter((polygon) => polygon.state === 'active');
    },
    selectedFieldForWeather() {
      if (this.fieldsForOverlay.length > 0) {
        const firstSelectedField = this.selectedFields[0];
        return this.fields[firstSelectedField];
      }
      return null;
    },
    hoveredPolygonData() {
      if (this.hoveredPolygon == null || this.polygons[this.hoveredPolygon] == null) {
        return {
          color: '',
          name: '',
          crop: '',
          size: '',
        };
      }
      const { lineChartColor: color, name } = this.polygons[this.hoveredPolygon];
      let { crop, size } = this.polygons[this.hoveredPolygon];
      if (crop != null) {
        crop = crop.name;
      }
      if (size == null) {
        size = this.google.maps.geometry.spherical.computeArea(this.polygons[this.hoveredPolygon].getPath()) / 10000;
      }
      size = this.$t('{size} ha', { size: numbro(size).format() });
      return {
        color,
        name,
        crop,
        size,
      };
    },
    legendEntries() {
      if (Object.values(this.currentHeatmaps).length === 0) {
        return [];
      }
      const colorCodesByName = Object.values(this.currentHeatmaps).reduce(
        (acc, current) =>
          current.color_codes.reduce((accInner, colorCode) => {
            if (accInner[colorCode.name] != null) {
              return accInner;
            }
            return {
              ...accInner,
              [colorCode.name]: colorCode,
            };
          }, acc),
        {},
      );
      return Object.values(colorCodesByName).map((colorCode) => {
        let label = colorCode.name;
        let color = 'var(--white)';
        if (label === 'cloud') {
          label = this.$t('Wolken');
          color = 'var(--black)';
        } else if (label === 'snow') {
          label = this.$t('Schnee');
        } else if (hexToHsl(colorCode.col)[2] > 40) {
          color = 'var(--black)';
        }
        return {
          label,
          style: { color, backgroundColor: colorCode.col },
        };
      });
    },
    pointsInFocus() {
      return this.selectedFields
        .filter(isUnique)
        .map((key) => this.polygons[key])
        .filter(notNullOrUndefined)
        .map((polygon) => polygon.getPath())
        .flatMap((path) => path.getArray());
    },
  },
  watch: {
    selectedFields(selectedKeys, oldSelectedKeys) {
      const selectArray = selectedKeys.filter((key) => !oldSelectedKeys.includes(key) && this.polygons[key]);
      const deselectArray = oldSelectedKeys.filter((key) => !selectedKeys.includes(key) && this.polygons[key]);

      selectArray.forEach((key) => {
        this.activatePolygon(key);
      });
      deselectArray.forEach((key) => {
        this.deactivatePolygon(key);
      });
    },
    polygons(polygons) {
      Object.keys(polygons).forEach((polygonKey) => {
        const polygon = polygons[polygonKey];
        const STATE = polygon.state;
        let polygonOptions = {};

        if (this.currentHeatmaps[polygonKey]) {
          return;
        }

        if (STATE === this.polygonStates.ACTIVE) {
          polygonOptions = {
            strokeColor: this.googleMapsSettings.POLYGON_ACTIVE_STROKE_COLOR,
            fillColor: this.googleMapsSettings.POLYGON_ACTIVE_FILL_COLOR,
            fillOpacity: 0.3,
            strokeOpacity: 1,
          };
        } else if (STATE === this.polygonStates.HIDDEN) {
          polygonOptions = {
            fillOpacity: 0,
            strokeOpacity: 0,
          };
        } else if (STATE === this.polygonStates.INACTIVE) {
          polygonOptions = {
            fillColor: this.googleMapsSettings.POLYGON_VISIBLE_STROKE_COLOR,
            strokeColor: this.googleMapsSettings.POLYGON_VISIBLE_FILL_COLOR,
            fillOpacity: 0.3,
            strokeOpacity: 1,
          };
        }
        polygon.setMap(this.map);
        polygon.setOptions(polygonOptions);
      });
    },
    currentHeatmaps(heatmaps) {
      const heatmapKeys = Object.keys(heatmaps);

      this.map.data.forEach((feature) => {
        this.map.data.remove(feature);
      });

      heatmapKeys.forEach((polygonKey) => {
        if (!heatmaps[polygonKey].errorMessage) {
          this.map.data.addGeoJson(heatmaps[polygonKey]);
          this.polygons[polygonKey].setOptions({
            fillOpacity: 0,
            strokeOpacity: 0,
          });
        } else {
          // polygonState red
        }
      });
      this.map.data.setStyle((feature) => {
        const fillColor = feature.getProperty('fill');
        return {
          fillColor,
          strokeColor: 'transparent',
          fillOpacity: 1,
          zIndex: -1,
        };
      });
    },
    pointsInFocus(points, oldPoints) {
      // skip centering when no points are provided
      if (points.length > oldPoints.length) {
        const bounds = new google.maps.LatLngBounds();
        points.forEach((point) => bounds.extend(point));
        this.map?.fitBounds(bounds, { bottom: 30, top: 30, right: 400, left: 10 });
      }
    },
  },
  mounted() {
    this.storeUnsubscribe = this.$store.subscribe(({ type }) => {
      if (type === 'afterResetData') {
        this.reInitialize();
      }
    });
    this.reInitialize();
  },
  beforeDestroy() {
    this.storeUnsubscribe();
  },
  methods: {
    async reInitialize() {
      if (this.currentCompaniesHaveFeature.enabled === false) {
        return;
      }
      this.markers = {};
      this.externalFieldsCount = 0;
      this.initialLoaded = false;
      this.fieldsForOverlayLoading = false;
      this.mapLoader = {
        visible: false,
        position: {
          top: 0,
          left: 0,
        },
      };
      this.hoveredPolygon = null;
      await this.initMaps();
      await this.setInitialDrawings();
      this.externalFieldsListener();
    },
    async initMaps() {
      const google = await googleMapsApi();
      this.google = google;
      const options = {
        tilt: 0,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        disableDefaultUI: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.TOP_RIGHT,
          mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        fullscreenControl: false,
        isFractionalZoomEnabled: true,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
        ],
      };
      this.map = new google.maps.Map(this.$refs['map-container'], options);
    },
    externalFieldsListener() {
      this.google.maps.event.addListener(this.map, 'click', async (event) => {
        if (
          event.latLng == null ||
          event.latLng.lat() == null ||
          event.latLng.lng() == null ||
          event.pixel == null ||
          event.pixel.y == null ||
          event.pixel.x == null
        ) {
          return;
        }

        const lat = event.latLng.lat();
        const lon = event.latLng.lng();

        this.mapLoader.visible = true;
        this.mapLoader.position = {
          top: event.pixel.y,
          left: event.pixel.x,
        };

        await this.getFieldContourByPoint(lat, lon).then((geoJson) => {
          if (geoJson) {
            this.externalFieldsCount += 1;
            const key = `ext${this.externalFieldsCount}`;
            const fieldInfo = {
              name: `${this.$t('Neues Feld')} ${this.externalFieldsCount}`,
            };
            this.drawPolygon(key, { lat, lon }, geoJson, fieldInfo, true);
          }
          this.mapLoader.visible = false;
        });
      });
    },
    async setInitialDrawings() {
      if (!this.google || this.initialLoaded) {
        return;
      }
      await this.$store.dispatch('auth/subscribe');
      await this.$store.dispatch('fields/subscribe');
      const bounds = new this.google.maps.LatLngBounds();
      await Promise.all(
        Object.keys(this.fields).map(async (key) => {
          const field = this.fields[key];
          const { lat, lon } = field;
          let geoJson;
          if (
            field.fieldContour == null ||
            field.fieldContour.geoJson == null ||
            field.fieldContour.geoJson.type !== 'Polygon'
          ) {
            if (lat === null || lon === null) {
              return;
            }
            await this.getFieldContourByPoint(lat, lon).then((shapeFromPoint) => {
              if (shapeFromPoint) {
                geoJson = shapeFromPoint;
              }
            });
          } else {
            ({ geoJson } = field.fieldContour);
          }
          const fieldInfo = {
            name: combinedNameExclCrop.data.call(this, field),
            crop: field.crop,
            size: field.fieldSize,
          };
          if (geoJson != null) {
            this.drawPolygon(key, { lat, lon }, geoJson, fieldInfo);
            geoJson.coordinates[0].forEach((point) => bounds.extend({ lng: point[0], lat: point[1] }));
          }
        }),
      );
      if (Object.values(this.fields).length > 0) {
        this.map.fitBounds(bounds);
      } else {
        this.map.setOptions(this.googleMapsSettings.GOOGLE_MAPS_DEFAULT_CENTER);
      }
      this.initialLoaded = true;
    },
    /**
     * key has to be a string because there are dynamically created fields w/ key 'extXY'
     *
     * @string key
     */
    drawPolygon(key, latLng, geoJson, fieldInfo, isExternalPolygon) {
      const polygon = new this.google.maps.Polygon({
        ...fieldInfo,
        field_key: key,
        paths: geoJson.coordinates[0].map((point) => ({ lng: point[0], lat: point[1] })),
        strokeColor: this.googleMapsSettings.POLYGON_VISIBLE_STROKE_COLOR,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: this.googleMapsSettings.POLYGON_VISIBLE_FILL_COLOR,
        fillOpacity: 0.3,
        clickable: true,
        state: this.polygonStates.INACTIVE,
        lat: latLng.lat,
        lon: latLng.lon,
        lineChartColor: getRandomColor(),
        external: isExternalPolygon,
        loadedVegetationData: {},
        heatmapState: this.heatmapStates.INACTIVE,
      });
      [polygon.pathsForAxios] = geoJson.coordinates;

      this.google.maps.event.addListener(polygon, 'click', () => {
        this.switchPolygonState(key, polygon.state);
      });
      this.google.maps.event.addListener(polygon, 'mouseover', () => {
        if (polygon.state === this.polygonStates.HIDDEN) {
          return;
        }
        this.hoveredPolygon = key;
      });
      this.google.maps.event.addListener(polygon, 'mouseout', () => {
        if (this.hoveredPolygon === key) {
          this.hoveredPolygon = null;
        }
      });
      this.$store.commit('precisionFarming/monitoring/addPolygon', polygon);
      if (isExternalPolygon) {
        this.activatePolygon(key);
      }
    },
    switchPolygonState(key, state) {
      if (state === this.polygonStates.INACTIVE || state === this.polygonStates.HIDDEN) {
        this.addToSelected(key);
      } else {
        this.removeFromSelected(key);
      }
    },
    addToSelected(key) {
      // Check if key is an internal field and trigger selection
      if (this.fields[key] && !this.selectedFields.includes(key)) {
        this.selectedFields = [...this.selectedFields, key];
      }
    },
    removeFromSelected(key) {
      // Check if key is an internal field and trigger de-selection
      if (this.fields[key] != null && this.selectedFields.includes(key)) {
        this.selectedFields = this.selectedFields.filter((currentFieldKey) => currentFieldKey !== key);
      }
    },
    activatePolygon(key) {
      this.$store.dispatch('precisionFarming/monitoring/setDnnPolygonState', {
        key,
        values: {
          state: this.polygonStates.ACTIVE,
        },
      });
    },
    deactivatePolygon(key) {
      this.$store.dispatch('precisionFarming/monitoring/setDnnPolygonState', {
        key,
        values: {
          state: this.polygonStates.INACTIVE,
        },
      });
      this.$store.commit('precisionFarming/monitoring/unsetCurrentHeatmap', key);
    },
    async getFieldContourByPoint(lat, lon) {
      const { data } = await axios.post('/admin/sen4/fieldContourByPoint', {
        lat,
        lon,
      });
      try {
        return data.contour;
      } catch {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.monitoring__map-wrapper {
  position: relative;
  flex: 1;
  height: 100%;
}

.monitoring__map-container,
.monitoring__map-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.monitoring__map-loader-svg {
  position: absolute;
  z-index: 3;
  font-size: 17px;
  margin-top: -8px;
  margin-left: -8px;
}

.monitoring__map-legend {
  position: absolute;
  left: 30px;
  bottom: 20px;
  z-index: 1;
}

.monitoring__map-legend div {
  padding: 3px 5px;
  text-align: center;
}

.monitoring__map-tooltip-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.monitoring__map-tooltip-list-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.monitoring__map-tooltip-list-item-color {
  display: inline-block;
  width: 7px;
  margin-right: 2px;
}

.monitoring__map-tooltip-list-item-color > span {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
</style>
