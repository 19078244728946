import { Module } from 'vuex';

import { RootState } from '@/store/types';

import companyFertilizers from '../companyFertilizers/store';
import crops from '../crops/store';
import harvests from '../harvests/store';
import herbizides from '../herbizides/store';
import mineralFertilizers from '../mineralFertilizers/store';
import miscellaneous from '../miscellaneous/store';
import otherProductsAndFertilizers from '../otherProductsAndFertilizers/store';
import secondaryFertilizers from '../secondaryFertilizers/store';
import seeds from '../seeds/store';
import waads from '../waads/store';
import actions from './actions';
import getters from './getters';
import { ProductsState } from './types';

const vuexModule: Module<ProductsState, RootState> = {
  namespaced: true,
  // @ts-ignore
  state: {},
  actions,
  getters,
  modules: {
    companyFertilizers,
    crops,
    harvests,
    herbizides,
    mineralFertilizers,
    miscellaneous,
    otherProductsAndFertilizers,
    secondaryFertilizers,
    seeds,
    waads,
  },
};

export default vuexModule;
