import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import CompanyService from '@/auth/services/CompanyService';
import EquipmentService from '@/equipments/services/EquipmentService';
import FieldService from '@/fields/services/FieldService';
import isHerbicide from '@/products/herbizides/isHerbicide';
import ProductService from '@/products/services/ProductService';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { toUnixTimestamp } from '@/shared/modules/unixTimestampHelpers';
import ActivityRuleViolationService from '../ruleCheck/activityRuleViolations/services/ActivityRuleViolationService';
import ViolationService from '../ruleCheck/services/ViolationService';
import ActivityEquipmentService from '../services/ActivityEquipmentService';
import ActivityProductService from '../services/ActivityProductService';
import ActivityService from '../services/ActivityService';
import TableDataActivityService from '../services/TableDataActivityService';
import { convertToActivitiesWithDisplayNames, convertToActivityWithDisplayNames, } from '../utils/convertToActivityWithDisplayName';
import findSimilarActivitiesForImportActivity from '../utils/findSimilarActivitiesForImportActivity';
import { subscribableStore, syncableStore } from './common';
const moduleGetters = Object.assign(Object.assign(Object.assign({}, subscribableStore.getters), syncableStore.getters), { activityWithDisplayNames: (state, getters, rootState, rootGetters) => (id) => {
        const activity = state.data[id];
        const findProductById = rootGetters['products/findProductById'];
        const productsLoading = rootGetters['products/loading'];
        const storagePlaces = rootState.storagePlaces.data;
        const equipments = rootState.equipments.data;
        const fields = rootState.fields.data;
        const customers = rootGetters['customers/data'];
        const crops = rootState.products.crops.data;
        return convertToActivityWithDisplayNames(activity, findProductById, productsLoading, storagePlaces, equipments, fields, customers, crops);
    }, activitiesWithDisplayNames: (state, getters, rootState, rootGetters) => {
        const findProductById = rootGetters['products/findProductById'];
        const productsLoading = rootGetters['products/loading'];
        const storagePlaces = rootState.storagePlaces.data;
        const equipments = rootState.equipments.data;
        const fields = rootState.fields.data;
        const customers = rootGetters['customers/data'];
        const crops = rootState.products.crops.data;
        return convertToActivitiesWithDisplayNames(state.data, findProductById, productsLoading, storagePlaces, equipments, fields, customers, crops);
    }, activityProductDisplayName: (state, getters, rootState, rootGetters) => (productId, storagePlaceId) => {
        const product = rootGetters['products/findProductById'](productId);
        if (!product)
            return null;
        if (storagePlaceId) {
            const storagePlace = rootState.storagePlaces.data[storagePlaceId];
            if (!storagePlace)
                return product.name;
            return `${product.name} (${storagePlace.name})`;
        }
        return product.name;
    }, selectedActivityIds: (state) => Object.entries(state.tableDataMetadata)
        .filter(([, metadata]) => metadata.select)
        .map(([id]) => id), selectedActivities: (state, getters) => {
        const selectedIds = getters.selectedActivityIds;
        return Object.values(state.data).filter((activity) => selectedIds.includes(activity.id));
    }, tableDataActivities: (state, getters, rootState, rootGetters) => {
        const currentTimestamp = toUnixTimestamp(Date.now());
        const activities = Object.values(state.data).filter((activity) => activity.deleted === undefined || activity.deleted === null || activity.deleted > currentTimestamp);
        const regionCodes = rootGetters['auth/currentCompaniesRegionCodes'];
        if (regionCodes.length > 1)
            throw new Error('Multiple region codes not supported');
        const fieldService = new FieldService(rootState.fields.data, rootGetters['products/findProductById']);
        const equipmentService = new EquipmentService(rootState.equipments.data);
        const activityEquipmentService = new ActivityEquipmentService(equipmentService);
        const companyService = new CompanyService(rootState.auth.companiesById);
        const productService = new ProductService(rootState.products.mineralFertilizers.data, rootState.products.companyFertilizers.data, rootState.products.secondaryFertilizers.data, rootState.products.herbizides.data, rootState.products.crops.data, rootState.products.miscellaneous.data, rootState.products.harvests.data, rootState.products.seeds.data, rootState.products.otherProductsAndFertilizers.data, rootState.products.waads.data);
        const activityProductService = new ActivityProductService(rootState.units.data, productService, calculateAmountsAndUnits);
        const activityService = new ActivityService(state.data, fieldService, activityEquipmentService, activityProductService, companyService);
        const activityRuleViolationService = new ActivityRuleViolationService(state.ruleCheck.activityRuleViolations.data);
        const violationService = new ViolationService(state.ruleCheck.rules.data, activityService, activityRuleViolationService, regionCodes[0]);
        const { companiesById } = rootState.auth;
        return new TableDataActivityService(activities, state.tableDataMetadata, rootState.units.data, rootState.fields.data, rootState.productCategories.data, rootState.equipments.data, rootState.users.data, violationService, activityProductService, companiesById, rootGetters['auth/processOrdersById'], rootGetters['products/loading'], rootGetters['products/findProductById'], rootGetters['products/dropdownItem'], rootGetters['pesticideIndications/dropdownItem'], rootGetters['activityTypes/dropdownItem'], rootGetters['fields/dropdownItem'], calculateAmountsAndUnits, isHerbicide).convertToActivitiesTableData();
    }, pesticideIdsInUse: (state, getters, rootState, rootGetters) => {
        const activities = Object.values(state.data);
        const pesticideIdsInUse = new Set();
        activities.forEach((activity) => {
            activity.products.forEach((activityProduct) => {
                const product = rootGetters['products/findProductById'](activityProduct.productId);
                if (product && isHerbicide(product, rootState.productCategories.data)) {
                    pesticideIdsInUse.add(product.id);
                }
            });
        });
        return Array.from(pesticideIdsInUse).sort();
    }, findSimilarActivitiesForImportActivity: (state) => (importActivity) => {
        const activities = Object.values(state.data);
        return findSimilarActivitiesForImportActivity(activities, importActivity);
    }, findAllByFieldId: (state) => (fieldId) => Object.values(state.data).filter((activity) => activity.fieldId === fieldId), findAllByFieldIds: (state) => (fieldIds) => Object.values(state.data)
        .filter((activity) => notNullOrUndefined(activity.fieldId))
        .filter((activity) => fieldIds.includes(activity.fieldId)) });
export default moduleGetters;
