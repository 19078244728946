import { AxiosRequestConfig } from 'axios';
import { ActionContext } from 'vuex';

import { fetchFieldsByIds, fetchFieldsByProcessOrderIds } from '@/shared/api/rest/RestApi';
import { ApiResponse, Field } from '@/shared/api/rest/models';
import removableData from '@/shared/mixins/store/removableData';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import syncableData from '@/shared/mixins/store/syncableData';
import { SubscribableDataState } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

import { AmaCode, CatchCropVariant, GwArea, KindOfUseType, Model, ModelDefinition } from './types';

export const kindOfUseTypes: KindOfUseType[] = [
  { id: 1, name: '' },
  { id: 2, name: 'Ackerland' },
  { id: 3, name: 'Grünland' },
  { id: 4, name: 'Wein' },
  { id: 5, name: 'Wein Terassen' },
  { id: 6, name: 'Gemeinschaftsweide' },
  { id: 7, name: 'Almen' },
  { id: 8, name: 'Spezialkulturen' },
  { id: 9, name: 'Geschützter Anbau' },
  { id: 10, name: 'Sonstige Nutzflächen' },
  { id: 11, name: 'Forst' },
];

export const catchCropVariants: CatchCropVariant[] = [
  { name: 'k.A.', id: '' },
  { name: 'Variante 1 - GREENING', id: 10 },
  { name: 'Variante 1 - GREENING + ÖPUL', id: 15 },
  { name: 'Variante 1 - ÖPUL', id: 1 },
  { name: 'Variante 2 - GREENING', id: 11 },
  { name: 'Variante 2 - GREENING + ÖPUL', id: 16 },
  { name: 'Variante 2 - ÖPUL', id: 2 },
  { name: 'Variante 3 - GREENING', id: 12 },
  { name: 'Variante 3 - GREENING + ÖPUL', id: 17 },
  { name: 'Variante 3 - ÖPUL', id: 3 },
  { name: 'Variante 4 - GREENING', id: 13 },
  { name: 'Variante 4 - GREENING + ÖPUL', id: 18 },
  { name: 'Variante 4 - ÖPUL', id: 4 },
  { name: 'Variante 4 mit MZ - GREENING + ÖPUL', id: 20 },
  { name: 'Variante 4 mit MZ - ÖPUL', id: 7 },
  { name: 'Variante 5 - GREENING', id: 14 },
  { name: 'Variante 5 - GREENING + ÖPUL', id: 19 },
  { name: 'Variante 5 - ÖPUL', id: 5 },
  { name: 'Variante 5 mit MZ - GREENING + ÖPUL', id: 21 },
  { name: 'Variante 5 mit MZ - ÖPUL', id: 8 },
  { name: 'Variante 6 - ÖPUL', id: 6 },
  { name: 'Variante 6 mit MZ - ÖPUL', id: 9 },
  { name: 'Variante 1', id: 30 },
  { name: 'Variante 2', id: 31 },
  { name: 'Variante 3', id: 32 },
  { name: 'Variante 4', id: 33 },
  { name: 'Variante 5', id: 34 },
  { name: 'Variante 6', id: 35 },
  { name: 'Variante 7', id: 36 },
];

export const amaCodes: AmaCode[] = [
  { name: 'AG', id: 'AG' },
  { name: 'APL', id: 'APL' },
  { name: 'APN', id: 'APN' },
  { name: 'BG', id: 'BG' },
  { name: 'BHG', id: 'BHG' },
  { name: 'BIO', id: 'BIO' },
  { name: 'BM0', id: 'BM0' },
  { name: 'BM1', id: 'BM1' },
  { name: 'BM2', id: 'BM2' },
  { name: 'BM3', id: 'BM3' },
  { name: 'DIV', id: 'DIV' },
  { name: 'ENP', id: 'ENP' },
  { name: 'EWA', id: 'EWA' },
  { name: 'FFV', id: 'FFV' },
  { name: 'FW', id: 'FW' },
  { name: 'GI', id: 'GI' },
  { name: 'k.A.', id: '' },
  { name: 'K20', id: 'K20' },
  { name: 'KNM', id: 'KNM' },
  { name: 'LRS', id: 'LRS' },
  { name: 'M', id: 'M' },
  { name: 'N2', id: 'N2' },
  { name: 'NUE', id: 'NUE' },
  { name: 'OG', id: 'OG' },
  { name: 'OP', id: 'OP' },
  { name: 'OPAG', id: 'OPAG' },
  { name: 'OPALP', id: 'OPALP' },
  { name: 'OPBIO', id: 'OPBIO' },
  { name: 'OPBM', id: 'OPBM' },
  { name: 'OPEEB', id: 'OPEEB' },
  { name: 'OPENP', id: 'OPENP' },
  { name: 'OPEOWH', id: 'OPEOWH' },
  { name: 'OPGWA', id: 'OPGWA' },
  { name: 'OPGWG', id: 'OPGWG' },
  { name: 'OPIMG', id: 'OPIMG' },
  { name: 'OPK20', id: 'OPK20' },
  { name: 'OPM', id: 'OPM' },
  { name: 'OPMULCH', id: 'OPMULCH' },
  { name: 'OPN2', id: 'OPN2' },
  { name: 'OPNUE', id: 'OPNUE' },
  { name: 'OPOG', id: 'OPOG' },
  { name: 'OPSLK', id: 'OPSLK' },
  { name: 'OPUBB', id: 'OPUBB' },
  { name: 'OPVFUWA', id: 'OPVFUWA' },
  { name: 'OPVHEWH', id: 'OPVHEWH' },
  { name: 'OPVINWH', id: 'OPVINWH' },
  { name: 'OPVSILA', id: 'OPVSILA' },
  { name: 'OPWF', id: 'OPWF' },
  { name: 'OPWRRL', id: 'OPWRRL' },
  { name: 'OPZOG', id: 'OPZOG' },
  { name: 'OPZWF', id: 'OPZWF' },
  { name: 'OVF', id: 'OVF' },
  { name: 'OVFPV', id: 'OVFPV' },
  { name: 'SG', id: 'SG' },
  { name: 'SLK', id: 'SLK' },
  { name: 'SONSTIGE', id: 'SONSTIGE' },
  { name: 'VF', id: 'VF' },
  { name: 'WF', id: 'WF' },
  { name: 'WPF', id: 'WPF' },
  { name: 'ZOG', id: 'ZOG' },
];

export const gwAreas: GwArea[] = [
  { name: 'WRRL GW GRAD', id: 'gwGrad' },
  { name: 'ÖPUL GWA', id: 'gwOepul' },
  { name: 'keines', id: 'gwNone' },
  { name: 'N-RISIKO', id: 'nRisiko' },
  { name: 'N-RISIKO + ÖPUL GWA', id: 'nRisikoGw' },
];

/* eslint-disable camelcase */
export const F_FieldGroupNumber = 'fieldGroup.mfa';
export const F_FieldGroupCustomer = 'fieldGroup.customerId';
/* eslint-enable camelcase */

export const model: Model = {
  processOrderId: null,
  name: null,
  fieldSize: null,
  [F_FieldGroupNumber]: null,
  [F_FieldGroupCustomer]: null,
  fieldNumber: null,
  comment: null,
  finished: false,
  amaCode: null,
  catchcropVariant: null,
  created: Math.round(new Date().getTime() / 1000),
  cropId: null,
  varietyId: null,
  preCropId: null,
  kindofuse: null,
  additionalData: {
    cadastralCommunity: null,
    plots: null,
    owner: null,
    rightOfUse: null,
    overrideFertilizerClass: null,
  },
  gwArea: 'gwNone',
  lat: null,
  lon: null,
  status: null,
  inWaterProtectionZone: null,
};

export const modelDefinition: ModelDefinition = {
  name: {
    type: 'text',
    required: true,
    nullable: false,
  },
  fieldSize: {
    type: 'number',
    nullable: true,
  },
  amaCode: {
    type: 'enum',
    values: amaCodes.map((variant) => variant.id),
    nullable: true,
  },
  catchcropVariant: {
    type: 'enum',
    values: catchCropVariants.map((variant) => variant.id),
    nullable: true,
  },
  kindofuse: {
    type: 'enum',
    values: kindOfUseTypes.map((variant) => variant.id),
    default: 1,
  },
  gwArea: {
    type: 'enum',
    values: gwAreas.map((variant) => variant.id),
    nullable: true,
  },
};

export const resolve = ['fieldGroup', 'fieldContour', 'processOrder'];
const resolveInSeparateList = ['crop', 'preCrop', 'variety'];

type Resolve = unknown; // TODO
type ResolveInSeparateList = unknown; // TODO

export async function fetchAll(
  context: ActionContext<SubscribableDataState<Field>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Field[], Resolve, ResolveInSeparateList>> {
  const processOrderIds = context.rootGetters['auth/currentProcessOrderIds'];
  if (processOrderIds?.length === 0) {
    const emptyFakeResponse: ApiResponse<Field[]> = {
      version: '2.0',
      status: 'success',
      data: [],
      page: 0,
      itemsPerPage: 0,
      total: 0,
    };
    return emptyFakeResponse;
  }

  const { data } = await fetchFieldsByProcessOrderIds<Resolve, ResolveInSeparateList>(processOrderIds, axiosOptions, {
    resolve,
    resolveInSeparateList,
    itemsPerPage: 5000,
  });

  return data;
}

async function fetchByIds(
  ids: string[],
  context: ActionContext<SubscribableDataState<Field>, RootState>,
  axiosOptions?: AxiosRequestConfig,
): Promise<ApiResponse<Field[], Resolve>> {
  const { data } = await fetchFieldsByIds(ids, axiosOptions, { resolve, itemsPerPage: 5000 });

  return data;
}

export const subscribableStore = subscribableData(fetchAll, fetchByIds);

export const dataUrl = '/admin/rest/field';
export const syncableStore = syncableData(dataUrl, { model, modelDefinition });
export const removableStore = removableData(dataUrl);
