import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';

import { subscribableStore, syncableStore } from './common';
import { GeoObjectState } from './types';

const moduleGetters: GetterTree<GeoObjectState, RootState> = {
  ...subscribableStore.getters,
  ...syncableStore.getters,
  getIsolationZoneSubTypes: (state) => {
    const types = Object.values(state.data)
      .filter((geoObject) => geoObject.objectType === 'IsolationZone' && geoObject.objectSubType !== '')
      .map((geoObject) => {
        const isHidden = state.hiddenIsolationZoneSubLayers.includes(geoObject.objectSubType);
        return {
          name: geoObject.objectSubType,
          hidden: isHidden,
        };
      });
    return types;
  },
};

export default moduleGetters;
