import { Activity } from 'farmdok-rest-api';

import { Getters as ActivityTypeGetters } from '@/activityTypes/store/getters';
import { Getters as AuthGetters } from '@/auth/store/getters';
import { Company } from '@/auth/store/types';
import { ActivityType } from '@/shared/api/rest/models';

export default function setActivityTypeOrSibling(
  activity: Activity,
  activityTypeSyncName: string,
  getCompanyByProcessOrderId: AuthGetters['getCompanyByProcessOrderId'],
  getActivityTypeOfCompanyBySyncName: ActivityTypeGetters['getActivityTypeOfCompanyBySyncName'],
  getActivityTypeBySyncName: ActivityTypeGetters['getBySyncName'],
): Activity {
  const company = getCompanyIfProcessOrderSet(activity, getCompanyByProcessOrderId);
  const activityType = getActivityType(
    activityTypeSyncName,
    company,
    getActivityTypeOfCompanyBySyncName,
    getActivityTypeBySyncName,
  );
  return {
    ...activity,
    activityTypeId: activityType.id,
  };
}

function getCompanyIfProcessOrderSet(
  activity: Activity,
  getCompanyByProcessOrderId: AuthGetters['getCompanyByProcessOrderId'],
): Company | null {
  if (activity.processOrderId === '') return null;

  if (!activity.processOrderId) throw Error('Activity must have a process order id');
  return getCompanyByProcessOrderId(activity.processOrderId);
}

function getActivityType(
  syncName: string,
  company: Company | null,
  getActivityTypeOfCompanyBySyncName: ActivityTypeGetters['getActivityTypeOfCompanyBySyncName'],
  getActivityTypeBySyncName: ActivityTypeGetters['getBySyncName'],
): ActivityType {
  if (company) {
    return getActivityTypeOfCompanyBySyncName(syncName, company.id);
  }

  return getActivityTypeBySyncName(syncName);
}
