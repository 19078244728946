import roundToDecimals from '@/shared/modules/roundToDecimals';
import { calculateRelative, calculateTotal } from './calculateAmount';
import { findRelativeUnit, findTotalUnit, isRelativeUnit, isTotalUnit } from './findUnit';
export default function calculateAmountsAndUnits(amount, unitId, processedAreaInHa, units) {
    if (!unitId) {
        return EMPTY_AMOUNTS_AND_UNITS;
    }
    const unit = units[unitId];
    if (!unit)
        return EMPTY_AMOUNTS_AND_UNITS;
    if (isTotalUnit(unit)) {
        return calculateAmountsAndUnitsFromTotalUnit(amount, unit, processedAreaInHa, units);
    }
    if (isRelativeUnit(unit)) {
        return calculateAmountsAndUnitsFromRelativeUnit(amount, unit, processedAreaInHa, units);
    }
    throw new Error(`Unit ${unit.id} is neither relative nor total unit. This shouldn't happen.`);
}
function calculateAmountsAndUnitsFromTotalUnit(amountTotal, unitTotal, processedAreaInHa, units) {
    var _a;
    const unitRelative = findRelativeUnit(unitTotal, units);
    const amountRelative = typeof amountTotal === 'number' && typeof processedAreaInHa === 'number' && unitRelative
        ? calculateRelative(amountTotal, unitRelative, processedAreaInHa, units)
        : null;
    const amountRelativeRounded = typeof amountRelative === 'number' ? roundToDecimals(amountRelative, 2) : null;
    return {
        amountUnitRelative: {
            amount: amountRelativeRounded,
            unitId: (_a = unitRelative === null || unitRelative === void 0 ? void 0 : unitRelative.id) !== null && _a !== void 0 ? _a : null,
            isFixed: false,
        },
        amountUnitTotal: {
            amount: amountTotal !== null && amountTotal !== void 0 ? amountTotal : null,
            unitId: unitTotal.id,
            isFixed: true,
        },
    };
}
function calculateAmountsAndUnitsFromRelativeUnit(amountRelative, unitRelative, processedAreaInHa, units) {
    var _a;
    const unitTotal = findTotalUnit(unitRelative, units);
    const amountTotal = typeof amountRelative === 'number' && typeof processedAreaInHa === 'number' && unitTotal
        ? calculateTotal(amountRelative, unitRelative, processedAreaInHa, units)
        : null;
    const amountTotalRounded = typeof amountTotal === 'number' ? roundToDecimals(amountTotal, 2) : null;
    return {
        amountUnitRelative: {
            amount: amountRelative !== null && amountRelative !== void 0 ? amountRelative : null,
            unitId: unitRelative.id,
            isFixed: true,
        },
        amountUnitTotal: {
            amount: amountTotalRounded,
            unitId: (_a = unitTotal === null || unitTotal === void 0 ? void 0 : unitTotal.id) !== null && _a !== void 0 ? _a : null,
            isFixed: false,
        },
    };
}
const EMPTY_AMOUNTS_AND_UNITS = {
    amountUnitRelative: {
        amount: null,
        unitId: null,
        isFixed: false,
    },
    amountUnitTotal: {
        amount: null,
        unitId: null,
        isFixed: false,
    },
};
