/* eslint-disable */
export default function en() {
  return {
    "": "",
    "#FEHLER": "#ERROR",
    "(Alle ausw\u00e4hlen)": "(Select all)",
    "(leer)": "(empty)",
    "(verschiedene)": "(various)",
    "1 Feld mit Erntema\u00dfnahme": "1 field with harvest activity",
    "1 Feld ohne Erntema\u00dfnahme": "1 field without harvest activity",
    "1 Fl\u00e4che ({area} ha) an {partner} gesendet.": "1 field ({area} ha) transmitted to {partner}.",
    "404: Nicht gefunden.": "404: Not found.",
    "<Bezeichnung>": "<Name>",
    "<strong>Lege die Datei hier ab<\/strong> <small>oder dr\u00fccke hier, um eine Datei hinzuzuf\u00fcgen.<\/small>": "<strong>Drop the file here<\/strong <small> or click here to select a file.<\/small>",
    "AGRANA FSNr - Feldname": "AGRANA Field group no - field name",
    "AMA Code": "AMA code",
    "AMA Code in der Datenquelle (aus dem Import)": "AMA code in the data source (from import)",
    "AMA Codes": "AMA codes",
    "Abbrechen": "Cancel",
    "Abgeschlossen": "Completed",
    "Ablaufdatum": "Date of expiry",
    "Ablaufdatum:": "Expiry date:",
    "Abmelden": "Logout",
    "Abschlie\u00dfen": "Complete",
    "Absenden": "Send",
    "Abstand prüfen": "Check distance",
    "Achtung": "Warning",
    "Die Saatstärke ist in einer oder mehreren Zonen beträchtlich {comparative} als üblich.": "The seed rate is considerably {comparative} than usual in one or more zones.",
    "Die Variation von Ausbringmengen bei der Applikation von Herbiziden birgt das Risiko, dass der Schaderreger nicht effektiv bekämpft wird. Die variable Ausbringung von Herbiziden wir nicht empfohlen.": "Varying application rates when applying herbicides harbours the risk that the pest is not effectively controlled. The variable application of herbicides is not recommended.",
    "Die Variation von Ausbringmengen bei der Applikation von Insektiziden birgt hohes Risiko der Bildung von Resistenzen. Die variable Ausbringung von Insektiziden wird nicht empfohlen.": "Varying application rates when applying insecticides involves a high risk of resistance developing. The variable application of insecticides is not recommended.",
    "Achtung! Beim Verlassen der Seite wird das Hochladen abgebrochen!": "Warning! The upload will be cancelled when you leave the page!",
    "Ackerkulturen": "Arable crops",
    "Adresse": "Address",
    "Adresse suchen": "Search adress",
    "Aktion sicher ausf\u00fchren?": "Are you sure to perform this action?",
    "Aktionsgebiet": "Action area",
    "Aktiv": "Active",
    "Aktiv setzen": "Set active",
    "Aktiviere deinen sicheren Zugang!": "Activate your account!",
    "Aktiviere den Schalter, um Unterebenen in der Karte anzuzeigen.": "Activate the switch to display sublayers in the map.",
    "Aktivieren": "Activate",
    "Aktivität konnte nicht importiert werden. Fehler: ": "Activity could not be imported. Error: ",
    "Aktualisiere den in der Arbeitsplanung geplanten Auftrag und f\u00fcge die eben erstellte Applikationskarte hinzu.": "Add the application map you just created to the task from the task plan.",
    "Aktuell unterst\u00fctzt FARMDOK Applikationskarten nur f\u00fcr Minerald\u00fcngung.": "Currently FARMDOK only supports application maps for mineral fertilization.",
    "Aktuell:": "Current:",
    "Alle": "All",
    "Alle ausblenden": "Hide all",
    "Alle Dateien entfernen": "Remove all files",
    "Alle Eintr\u00e4ge": "All entries",
    "Alle Farmen": "All farms",
    "Alle Felder": "All fields",
    "Alle Werte in kg\/ha": "Values in kg\/ha",
    "Alle Werte in K\u00f6rner/ha": "Values in grains/ha",
    "Alle anzeigen": "Show all",
    "Alle ausw\u00e4hlen": "Select all",
    "Alle w\u00e4hlen": "Select all",
    "Alle ausgewählten Polygone werden zu einem vereint. Willst du fortsetzen?": "All selected polygons will be combined into one. Do you want to continue?",
    "Allgemeinen Gesch\u00e4ftsbedingungen (AGB)": "Terms of service",
    "Als Excel-Datei exportieren": "Export as Excel file",
    "An angegebene Betriebe zum Lesen freigegeben.": "Shared with specified accounts for read-only access.",
    "An diesem Datum wurde das Satellitenbild aufgenommen. Alle 5 Tage passiert der Satellit die gleiche Stelle. Bedingt durch Wolken und Wetter ist nicht jedes Bild verwendbar.": "On this date, the satellite image was taken. The satellite passes the same location every 5 days. Due to clouds and weather not every image is usable.",
    "Anbau": "Cultivation",
    "Anbauplanung": "Cultivation plan",
    "Andere": "Other",
    "Andere T\u00e4tigkeiten": "Other activities",
    "Angezeigt: {visible} von {total}": "Displaying: {visible} of {total}",
    "Anleitung: Woher bekomme ich eine Datei mit meinen Feldern?": "Guide: Where can I get a file with my fields?",
    "Anleitung: Woher bekomme ich eine ISOXML Datei mit meinen Ma\u00dfnahmen?": "Hint: Where can I get an ISOXML file with my activities?",
    "Anleitung: Die KI-unterstützte Auswertung von Drohnenbilder ermöglicht es Stechapfelpflanzen im Soja zu erkennen und geolokalisiert in einer Karte zu kennzeichnen. Woher bekomme ich geeignete Drohnenbilder für diese Auswertung?": "Instructions: The AI-supported evaluation of drone images makes it possible to recognise datura plants in soybeans and mark them geolocated on a map. Where can I get suitable drone images for this analysis?",
    "Anleitung: Der Umfang auszuwertender Bilddaten kann sehr groß sein. Es können Einzelbilder, eine .zip-Datei mit Bildern oder ein Order mit Bildern hochgeladen werden. Bei großen Datenmengen oder schlechter Internetverbindung kann das Hochladen mehrere Stunden dauern. Achtung: Die Benennung der Dateien muss eindeutig sein. Duplikate überschreiben das Original.": "Instructions: The volume of image data to be evaluated can be very large. Individual images, a .zip file with images or a folder with images can be uploaded. If the data volume is large or the internet connection is poor, the upload can take several hours. Important: The file names must be unique. Duplicates overwrite the original.",
    "Antrag": "Application",
    "Anzahl Eintr\u00e4ge: {total}": "Number of entries: {total}",
    "Anzahl der Tasks auf der CNH-Plattform:": "Number of tasks on the CNH platform:",
    "Anzahl der Zonen": "Number of zones",
    "Anzahl Felder": "Qty fields",
    "Anzahl Packungen": "Number of packages",
    "Anzahl Bilder": "Number of images",
    "Anzeige": "Display",
    "Anzeigen": "Show",
    "Anzeige der Unterebenen von Isolationszonen": "Display of sublevels of isolation zones",
    "Analyse gestartet": "Analysis started",
    "Assessment erstellen": "New assessment",
    "Assessment Name": "Assessment name",
    "Applikationskarte an John Deere senden": "Send application map to John Deere",
    "Applikationskarte erstellen": "Create application map",
    "Applikationskarte senden an ...": "Send application map to ...",
    "Applikationskarte": "Application map",
    "Applikationskarten": "Application maps",
    "Applikationskarten f\u00fcr Ausgleichs- oder Qualit\u00e4tsd\u00fcngung. Zonierung mittels Satelliten oder importierten Daten.": "Application maps for balancing or quality fertilization. Zoning by means of satellites or imported data.",
    "Applikationskarten f\u00fcr Ausgleichs- oder Qualit\u00e4tsd\u00fcngung von Getreide. Ein Projekt von Josephinum Research.": "Application maps for compensatory or quality fertilisation of cereals. A project of Josephinum Research.",
    "Applikationskarten für die variable Ausbringung von Wachstumsregler und Fungiziden auf Basis von Satellitendaten.": "Application maps for the variable application of growth regulators and fungicides based on satellite data.",
    "Arbeitsplanung": "Activity plan",
    "Arbeitszeit": "Working time",
    "Archiv editieren": "Edit archive",
    "Archivieren": "Put in archive",
    "Archiviert": "Archived",
    "Archivierte ausblenden": "Hide archived",
    "Archivierte einblenden": "Show archived",
    "Auf diesem Feld wurde bereits eine Erntema\u00dfnahme f\u00fcr das Erntejahr {current} gebucht. Schlie\u00dfe das Erntejahr f\u00fcr das Feld jetzt ab, es wird automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.": "A harvest activity has already been logged on this field for the crop year {current}. Complete the crop year for the field now, it will automatically be created in the next crop year {next} and set \"active\".",
    "Auf diesen Feldern wurden bereits Erntema\u00dfnahmen f\u00fcr das Erntejahr {current} gebucht. Schlie\u00dfe das Erntejahr f\u00fcr die Felder jetzt ab, sie werden automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.": "These fields have already harvest activities logged for the crop year {current}. Complete the crop year for the fields now, they will be automatically created and set \"active\" in the next crop year {next}.",
    "Auf welches Feld sollen bereits gebuchte Ma\u00dfnahmen \u00fcbernommen werden?": "To which field should already logged activites be transferred?",
    "Aufteilen beenden": "Close splitting",
    "Aufteilen": "Split",
    "Aufteilen eines Polygons": "Split a polygon",
    "Auftrag": "Task",
    "Auftrag aktualisieren": "Update task",
    "Auftrag erstellen": "Create a task",
    "Auftrag planen": "Plan task",
    "Auftrag\/Applikationskarte": "Task\/application map",
    "Auftragsnummer": "Task number",
    "Aufzeichnungspflichten erf\u00fcllen": "Meet legal obligations",
    "Aus importierter Datei.": "From imported file.",
    "Ausgew\u00e4hlt: {selected} von {total}": "Selected: {selected} of {total}",
    "Ausgew\u00e4hlte Felder in ein anderes Erntejahr verschieben": "Move selected fields to another crop year",
    "Ausgew\u00e4hlte Maßnahmen in ein anderes Erntejahr oder in eine andere Farm verschieben": "Move selected activities to another crop year or farm",
    "Ausgleichsd\u00fcngung (1. Gabe)": "Balancing fertilization (1. application)",
    "Aussaat": "Seeding",
    "AUSSAAT": "SEEDING",
    "Auswahl aufheben": "Deselect all",
    "Auswahl exportieren": "Export selection",
    "Auswahl umkehren": "Invert selection",
    "Auswertung": "Reporting",
    "B": "B",
    "Basissaatst\u00e4rke": "Basic seed strength",
    "Basistemperatur": "Base temperature",
    "Basistemperatur ist relevant für die Wärmesumme. Es wird angenommen, dass unter ihr kein Wachstum stattfindet.": "Base temperature is relevant for the heat sum. It is assumed that no growth takes place below it.",
    "BMMY - Mehrj\u00e4hrige Biomasse (3 Jahre)": "BMMY - Multi year biomass (3 years)",
    "Bearbeiten": "Edit",
    "Bearbeitete Fl\u00e4che": "Processed area",
    "Bedarf": "Demand",
    "Begr\u00fcnung": "Greening",
    "Begr\u00fcnung (Zwischenfrucht)": "Greening (catch crop)",
    "Behalte diese Option bei den folgenden Konflikten bei": "Keep this option for the following conflicts",
    "Bei Wintergetreide wird f\u00fcr die 2. Gabe eine konstante D\u00fcngermenge empfohlen. Wurde die erste Gabe nicht mittels Applikationskarte ausgleichend ged\u00fcngt, so kann für die 2. Gabe der Berechnungsvorschlag der erste Gabe angewendet werden.": "For winter cereals, a constant fertiliser quantity is recommended for the second application. If the first application was not fertilised by means of an application card, the recommendation for the first application can be used for the second application.",
    "Beim Laden der Wetterdaten ist ein Fehler aufgetreten.": "An error occurred when loading weather data.",
    "Beistrich-getrennt": "Comma separated",
    "Bekannt, unter anderem dafür in Google Earth angezeigt zu werden": "Known, for being displayed in Google Earth (and others)",
    "Bemerkung": "Comment",
    "Benachrichtigungen": "Notifications",
    "Benutzer": "User",
    "Benutzername oder Passwort ung\u00fcltig.": "Username or password invalid.",
    "Berater-Tipp": "Expert-Hint",
    "Berater-Tipp: Hast du Verluste durch mechanische Pflege, Wild oder \u00e4hnliche Einflüsse ber\u00fccksichtigt?": "Advisor tip: Have you considered losses caused by mechanical care, game or similar influences?",
    "Berechnen ...": "Calculating ...",
    "Berechne einen Vorschlag f\u00fcr ...": "Prepare a recommendation for ...",
    "Berichte": "Reports",
    "Bericht erstellen": "Create report",
    "Beschreibung": "Description",
    "Beschr\u00e4nkungen bei der Ernte:": "Crop limitations:",
    "Beschr\u00e4nkungen f\u00fcr Sch\u00e4dlinge:": "Pest limitations:",
    "Betrieb": "Farm",
    "Betrieb erstellen": "Create farm",
    "Betrieb fehlt.": "Company missing.",
    "Betrieb w\u00e4hlen": "Choose farm",
    "Betriebe": "Farms",
    "Betriebsbezeichnung": "Farm name",
    "Betriebsmittel": "Products",
    "Betriebsmittelkategorien": "Product categories",
    "Betriebsname": "Farm name",
    "Betriebsnummer": "Farm number",
    "Betriebsnummer des Sender-Betriebs": "Farm number of the sender farm",
    "Bezeichnung": "Name",
    "Bezeichnung fehlt.": "Name is missing.",
    "Bezeichnung f\u00fcr Feld {number} fehlt.": "Name for field {number}\u00a0is missing.",
    "Bilder": "Images",
    "Biologisch": "Organic",
    "Bitte achte darauf, die gleiche E-Mail-Adresse wie in der FARMDOK App am Smartphone oder Tablet zu verwenden. Du findest die E-Mail-Adresse in der FARMDOK App im Men\u00fcpunkt \"Mehr\".": "Please make sure to use the same e-mail address as in the FARMDOK app on your smartphone or tablet. You can find the e-mail address in the FARMDOK app in the menu item \"More\".",
    "Bitte beachte das Risiko von Resistenzbildung bei zu geringen Ausbringmengen von Insektiziden.": "Please bear in mind the risk of resistance developing if too little insecticide is applied.",
    "Bitte beachte die Risiken von Resistenzbildung und abgeschwächter oder ausbleibender Wirkung bei zu geringen Ausbringmengen von Herbiziden.": "Please note the risks of resistance development and weakened or absent effect if herbicide application rates are too low.",
    "Bitte w\u00e4hle eine Option und klicke auf Weiter.": "Please select an option and click next.",
    "Bitte w\u00e4hle einen Eintrag aus.": "Please select an entry.",
    "Bitte w\u00e4hle wie Duplikate behandelt werden sollen.": "Please select how duplicates should be handled.",
    "Bitte \u00fcberpr\u00fcfe deine E-Mails": "Please check your emails",
    "Bitte \u00fcberpr\u00fcfe deine E-Mails!": "Please check your e-mails!",
    "Bitte w\u00e4hle ein Feld aus, bevor du fortfährst.": "Please select a field before proceeding.",
    "Bitte w\u00e4hle ein Produkt und eine Produktmenge aus, bevor du fortfährst.": "Please select a product and a product amount before proceeding.",
    "Bitte w\u00e4hle eine Sorte aus, bevor du fortfährst.": "Please select a variety before proceeding.",
    "Bitte beachte die technischen Möglichkeiten deiner Feldspritze. Prüfe ob deine Feldspritze in der Lage ist die festgelegte Menge Spritzbrühe auszubringen.": "Please note the technical capabilities of your field sprayer. Check whether your field sprayer is able to apply the specified quantity of spray liquid.",
    "Bodenproben": "Soil samples",
    "Bodenproben teilen": "Share soil samples",
    "Bodenfeuchtigkeit": "Soil moisture",
    "Bodentemperatur": "Soil temperature",
    "Ca": "Ca",
    "Ca-Klasse": "Ca class",
    "Christbaumkulturen": "Christmas trees",
    "Chronologisch": "Chronological",
    "CIre - Chlorophyll": "CIre - Chlorophyll",
    "CNH CONNECT Plattform": "CNH CONNECT Platform",
    "Dabei werden {count} bestehende Felder ver\u00e4ndert.": "This changes {count} existing fields.",
    "Dabei wird ein bestehendes Feld ver\u00e4ndert.": "This changes one existing field.",
    "Danke, dass Du Dich bei Farmdok registriert hast! Deine E-Mail {email} ist jetzt best\u00e4tigt. Klicke auf Weiter und danach helfen wir Dir beim Einstieg.": "Thank you for joining Farmdok! Your email {email} is now confirmed. Click on Continue and we will help you get started.",
    "Darstellung": "Appearance",
    "Das Abrufen der Maschinenliste von John Deere fehlgeschlagen.": "An error occurred when loading the equipment list from MyJohnDeere.",
    "Das Erntejahr {current} f\u00fcr die ausgew\u00e4hlten Felder befindet sich in Planung.": "The crop year {current} for the selected fields is in the planning stage.",
    "Das Erntejahr {current} f\u00fcr dieses Feld befindet sich in Planung.": "The crop year {current} for this field is in the planning stage.",
    "Das Erntejahr {newYear} wurde hinzugef\u00fcgt.": "The crop year {newYear} was added.",
    "Das Feld besitzt keine Kontur": "The field has no contour",
    "Das Feld der folgenden Maßnahme konnte im Ziel nicht gefunden werden.": "The field of the following activity could not be found in the target.",
    "Das Feld konnte nicht wieder hergestellt werden.": "The field could not be restored.",
    "Das Feld wird derzeit im angezeigten Erntejahr {current} bewirtschaftet.": "The field is currently cultivated in the indicated crop year {current}.",
    "Das Feld wurde erfolgreich geteilt.": "The field was successfully split.",
    "Das gew\u00e4hlte Feld existiert bereits am Ziel. W\u00e4hle wie dieses Feld behandelt werden soll.": "The selected field already exists at the destination. Choose how this field should be treated.",
    "Das manuelle Mapping für Werte dieser Datenquelle wird zurückgesetzt in den Originalzustand.": "The manual mapping for values of this data source is reset to the original state.",
    "Das Startdatum ist standardmäßig das Aussaatdatum der Maßnahme oder alternativ der Jahresbeginn. Es ist relevant für die Wärmesumme und die Niederschlagssumme.": "The start date is by default the sowing date of the measure or alternatively the beginning of the year. It is relevant for the heat sum and the precipitation sum.",
    "Die Karte auf der rechten Seite zeigt den bedruckbaren Bereich. Du kannst eine Legende erstellen und sie irgendwo im druckbaren Bereich platzieren. Die Legende wird auf der Grundlage der Kulturen erstellt (Du kannst dies mit der Einstellung \"Einfärben nach...\" im Menü ändern).": "The map on the right-hand side indicates the printable are. You can create a legend and place it anywhere within the printable area. The legend's based on the crops chosen colors (you can change this with the \"Color by...\" setting in the menu).",
    "Die zulässige Größe für ausstehende Uploads wurde überschritten. Limit wird in ca. 1 Woche automatisch zurückgesetzt.": "Exceeded maximum allowed size for pending uploads. Limit will be reset automatically in ~ 1 week.",
    "Datei hochladen": "Upload file",
    "Dateien": "Files",
    "Datei herunterladen": "Download file",
    "Dateien hochladen": "Upload files",
    "Datei wird hochgeladen": "Uploading file",
    "Dateien erfolgreich an John Deere gesendet.": "Files were sent to John Deere succesfully.",
    "Dateiname": "File name",
    "Datei-Import": "File import",
    "Dateien hochladen und mit einem Link mit anderen teilen": "Upload files and share them via a link with others",
    "Dateien werden für den Maßnahmenimport nicht unterstützt.": "files are not supported for importing activities.",
    "Dateiformat": "File format",
    "Dateityp": "File type",
    "Daten": "Data",
    "Daten werden importiert...": "Importing data...",
    "Daten Ursprung": "Data origin",
    "Daten in FARMDOK zuordnen.": "Assign data in FARMDOK.",
    "Daten laden": "Load data",
    "Daten-Vorschau": "Data preview",
    "Datenquelle": "Data source",
    "Datum": "Date",
    "Datum an dem die Freigabe verf\u00e4llt": "Date on which the share expires",
    "Datum an dem die Datei hochgeladen wurde": "Date on which the file was uplaoded",
    "Datum bis": "Date to",
    "Datum von": "Date from",
    "Deaktiviere das Kontrollk\u00e4stchen um Spalten zu verstecken. Zum \u00c4ndern der Spaltenreihenfolge in der Tabelle klicke auf das Feld und ziehe es an die gew\u00fcnschte Stelle.": "Deactivate the checkbox to hide columns. To change the order of columns, click on the entry and drag it to your desired position.",
    "Dein Betrieb wurde erfolgreich erstellt.": "Your farm has been created successfully.",
    "Dein Partner hat Felder für diesen Vertrag bereitgestellt. Dein Partner kann die Felder jederzeit aktualisieren.": "Your partner provided fields for this contract. Your partner may update the fields anytime.",
    "Dein Passwort wurde erfolgreich gespeichert.": "Your password has been saved successfully.",
    "Dein Profil wird f\u00fcr dich angepasst!": "Your profile is being be customized for you.",
    "Deine Auswahl enth\u00e4lt Felder mit abgeschlossener Kontrahierung. \u00c4ndere den Partner-Status, um die Eintr\u00e4ge zu l\u00f6schen.": "Your selection contains contracted fields. Change the partner status to remove fields.",
    "Deine E-Mail ist best\u00e4tigt.": "Your email is confirmed.",
    "Deine Maschinen in MyJohnDeere.com": "Your machines in MyJohnDeere",
    "Deine Organisationen in MyJohnDeere": "Your organizations in MyJohnDeere",
    "Demn\u00e4chst verf\u00fcgbar": "Coming soon",
    "Der Erntejahr-Status konnte nicht ge\u00e4ndert werden.": "The crop year status could not be changed.",
    "Der Index wird aus Satellitendaten berechnet und bildet die Basis f\u00fcr die Teilfl\u00e4chen (Zonen).": "The index gets calculated from satellite imagery and is the basis for subareas (zones).",
    "Der Status der gew\u00e4hlten Felder wurde aktualisiert.": "The status of the selected fields has been updated.",
    "Der Vertragsabschluss ist erfolgt. Dein Partner kann keine Aktualisierungen senden.": "Contracting is done. Your partner can not send any updates.",
    "Der Vertrag wurde storniert. Die bereitgestellten Felder werden nicht mehr ber\u00fccksichtigt.": "The contract is canceled. The provided fields are not considered anymore.",
    "Der Wert in der Datenquelle (aus dem Import) konnte nicht eindeutig erkannt werden. Bitte w\u00e4hle eine Eintrag aus der Liste. Andernfalls kann kein Wert importiert werden.": "The value from the data source (import) could not be recognized with certainty. Please select an entry from the list. Otherwise, the value cannot be imported.",
    "Der Upload der Drohnenbilder wurde erfolgreich abgeschlossen und die Analyse der Drohnenbilder gestartet. Sobald die Ergebnisse verfügbar sind, werden sie im Datura control center angezeigt. Du kannst den aktuellen Status des Assessments in der “Job history” verfolgen.": "The upload of the drone images has been successfully completed and the analysis of the drone images has been started. As soon as the results are available, they will be displayed in the Datura control centre. You can track the current status of the assessment in the \"Job history\".",
    "Deshalb wurde keine Aktivit\u00e4t verschoben nach {cropYear} auf {companyName}": "Therefore no activity has been moved to {cropYear} on {companyName}",
    "Derzeit werden nur Zip-Dateien unterst\u00fctzt.": "Currently only ZIP files are supported.",
    "Detaillierte T\u00e4tigkeiten": "Detailed activity types",
    "Details anzeigen": "Show details",
    "Den Status des aktuellen Auftrags und den Auftragsverlauf anzeigen": "View the status of the current order and the order history",
    "Die AGB konnten nicht geladen werden.": "Terms and conditions could not be loaded.",
    "Die aktuelle Tabelle enth\u00e4lt Sub-Tabellen. Bitte w\u00e4hle f\u00fcr welche die Eintr\u00e4ge im exportieren XLS in einer eigenen Zeile dargestellt werden sollen.": "The current table contains sub-tables. Please select for which ones the entries in the exported XLS should be displayed in a separate row.",
    "Die aktuelle Trennlinie ist ungültig. Zeichne eine Linie die das aktuelle Feld in genau zwei Teile teilt. Du kannst die Trennlinie durch Ziehen der Eckpunkte anpassen.": "The current dividing line is invalid. Draw a line that divides the current field into exactly two parts. You can adjust the dividing line by dragging the corner points.",
    "Die Datei ist zu gro\u00df. (max. {maxFileSize} MB)": "The file is too big. (max. {maxFileSize} MB)",
    "Die E-Mail-Adresse ist ung\u00fcltig.": "The e-mail address is invalid.",
    "Die Ernte f\u00fcr das Erntejahr {current} ist auf den ausgew\u00e4hlten Feldern bereits abgeschlossen. Sie sind zur Zeit in einem zuk\u00fcnftigen Erntejahr \"aktiv\".": "The harvest for the crop year {current} is already completed on the selected fields. They are currently \"active\" in a future crop year.",
    "Die Ernte f\u00fcr das Erntejahr {current} ist bereits abgeschlossen. Das Feld ist in einem zuk\u00fcnftigen Erntejahr \"aktiv\".": "The harvest for the crop year {current} is already completed. The field is \"active\" in a future harvest year.",
    "Die Felder aus dem nachfolgenden Erntejahr {sourceYear} \u00fcbernehmen.": "Import the fields from the following crop year {sourceYear}.",
    "Die Felder aus dem vorangehenden Erntejahr {sourceYear} werden \u00fcbernommen.": "The same fields like in the previous crop year {sourceYear} will get created.",
    "Die Felder werden derzeit im angezeigten Erntejahr {current} bewirtschaftet.": "The fields are currently being farmed in the indicated crop year {current}.",
    "Die Felder wurden aus {sourceYear} \u00fcbernommen.": "The fields were imported from {sourceYear}.",
    "Die Felder wurden erfolgreich erstellt.": "The fields were created successfully.",
    "Die Felder wurden erfolgreich kopiert.": "The fields have been copied successfully.",
    "Die Felder wurden erfolgreich vereint. Du kannst die Eigenschaften des neuen Felds jetzt anpassen.": "The fields have been successfully merged. You can now customize the properties of the new field.",
    "Die Felder wurden erfolgreich verschoben.": "The fields have been moved successfully.",
    "Die Felder wurden erfolgreich zusammengef\u00fcgt.": "The fields were successfully merged.",
    "Die Felder wurden noch nicht erstellt. Bist du sicher, dass du den Import abbrechen willst?": "The fields have not been created yet. Are you sure you want to cancel the import?",
    "Die Feldkontur konnte nicht aktualisiert werden.": "The field contour could not be updated.",
    "Die Feldkonturen konnten nicht kombiniert werden.": "The field contours could not be combined.",
    "Die Fl\u00e4chen wurden nicht \u00fcber FARMDOK an {partner} gemeldet.": "The fields have not been transmitted to {partner} via FARMDOK.",
    "Die Gesamt-Erntemengen wurden von {partner} geladen. Klicke hier, um im n\u00e4chsten Schritt Ma\u00dfnahmen auf deinen Feldern zu erstellen.": "The total harvesting amounts have been imported from {partner}. Click here to create activities on your fields in the next step.",
    "Die Importvorschau wird geladen. Hier kannst du bereits Anpassungen vornehmen.": "Loading the import preview. Here you can make adjustments.",
    "Die Liste der verf\u00fcgbaren L\u00e4nder konnte nicht geladen werden. Bitte \u00fcberpr\u00fcfe deine Internetverbindung und lade die Seite neu.": "The list of available countries could not be loaded. Please check your internet connection and reload the page.",
    "Die minimale Saatst\u00e4rke darf nicht gr\u00f6\u00dfer als die maximale Saatst\u00e4rke sein.": "The minimum seed rate must not be greater than the maximum seed rate.",
    "Die maximale Saatst\u00e4rke darf nicht kleiner als die minimale Saatst\u00e4rke sein.": "The maximum seed rate must not be smaller than the minimum seed rate.",
    "Die Partnernummer ist deine Identifikationsnummer bei SAATBAU LINZ eGen.": "The partner number is your identification number at SAATBAU LINZ eGen.",
    "Die Werte konnten nicht geladen werden.": "Values could not be loaded.",
    "Die aktuelle Trennlinie ist ung\u00fclitg. Zeichne eine Linie die das aktuelle Feld in genau zwei Teile teilt.": "The current dividing line is invalid. Draw a line that splits the current field into exactly two parts.",
    "Die folgenden Felder existieren bereits am Ziel.": "The following fields already exist at the destination.",
    "Die gew\u00e4hlten Feldst\u00fccke wurden erfolgreich archiviert.": "The selected field groups were successfully archived.",
    "Die gew\u00e4hlten Feldst\u00fccke wurden wieder aktiviert.": "The selected field groups were reactivated.",
    "Die gew\u00e4hlten Kulturen wurden abgeschlossen. Die entsprechenden Felder sind jetzt f\u00fcr {next} aktiv.": "The selected crops have been completed. The corresponding fields are now active for {next}.",
    "Die hochgeladenen Zonen stimmen nicht mit den selektierten Feldern \u00fcberein. FARMDOK erstellt eine Zone f\u00fcr jedes Feld ohne Zone. Welcher Wert soll diesen Zonen zugewiesen werden?": "The uploaded zones do not match the selected fields. FARMDOK creates a zone for each field without a zone. What value should be assigned to these zones?",
    "Diese Eigenschaft ist ein Pflichtfeld und kann nicht deaktiviert oder verschoben werden.": "This attribute is required and cannot be deactivated or moved.",
    "Diese Eigenschaft ist ein Pflichtfeld und kann nicht deaktiviert werden.": "This attribute is required and cannot be deactivated.",
    "Diese Eigenschaft kann nicht verschoben werden.": "This attribute cannot be moved.",
    "Diese Funktion ist sehr beliebt. Jetzt im Shop freischalten und weitere Vorteile nutzen!": "This service is very popular. Activate it now in the shop and enjoy further benefits!",
    "Dieses Feature unterst\u00fctzt derzeit nur einen gew\u00e4hlten Betrieb.": "This feature currently supports only one selected company.",
    "Dieses Dateiformat wird nicht unterst\u00fctzt": "This file format is not supported",
    "Dieses Produkt in anderen Lagern": "This product on other storages",
    "Dieses Problem nicht mehr anzeigen": "Do not show this problem again",
    "Differenz zwischen Zonen (%)": "Difference from zone to zone (%)",
    "Direkt von eAMA": "Directly from eAMA",
    "Dosierung": "Dosage",
    "Download": "Download",
    "Dr\u00fcck bei Datenempf\u00e4nger FARMDOK GmbH die Taste \u201eEinwilligung starten\u201c und folge den Anweisungen.": "Click the \"Einwilligung starten\" button at data recipient FARMDOK GmbH and follow the instructions.",
    "Dr\u00fccke \"Importieren\" um den Import abzuschlie\u00dfen.": "Click \"Import\" to complete the import.",
    "Du verfügst auf diesem Betrieb über keine Berechtigung für dieses Feature.": "You do not have permission for this feature on this farm.",
    "Du bist derzeit bei CNH-Industrial als {userEmail} eingeloggt. Wenn du mehr als ein Unternehmen, eine Landwirtschaft oder einen Betrieb hast, wähle bitte aus.": "You are currently logged in on CNH-Industrial as {userEmail}. If you have more than one company, grower or farm, please select.",
    "Du bist auf MyJohnDeere mit <u>{userEmail}<\/u> eingeloggt. Falls du mehr als eine Organisation auf MyJohnDeere hast w\u00e4hle bitte die passende aus der Liste.": "You are logged in on MyJohnDeere with <u>{userEmail}<\/u>. If you have access to more than one organization in MyJohnDeere, please select one from the list.",
    "Du entfernst ein Feld, das von deinem Vertragspartner bereitgestellt wurde. Gewählten Eintrag wirklich löschen?": "You are removing a field, provided from your contract partner. Do you really want to delete the selected entry?",
    "Du hast bereits ein Konto?": "You already have an account?",
    "Du hast derzeit keine Berechtigung auf einen Betrieb zuzugreifen.": "You do not currently have permission to access a farm.",
    "Du hast keine Berechtigung ein neues Erntejahr hinzuzuf\u00fcgen.": "You do not have permission to add a new crop year.",
    "Du hast keine E-Mail bekommen? \u00dcberpr\u00fcfe bitte den Spam-Ordner.": "You did not receive an e-mail? Please check your spam folder.",
    "Du hast noch kein Konto?": "You don't have an account yet?",
    "Du hast noch keine Kontrakte angenommen.": "You do not have any contracts yet.",
    "Du importierst ein Feld.": "You are about to import one field.",
    "Du importierst {count} Felder.": "You are about to import {count} fields.",
    "Du kannst Fl\u00e4chen mit Dateien im Format Shape, XML, KML, XLS, XLSX und CSV hochladen.": "You can upload fields using files in formats Shape, XML, KML, XLS, XLSX or CSV.",
    "Du kannst aber Erntedaten importieren, sobald sie bei {partner} verf\u00fcgbar sind.": "But you can import harvesting data as soon as they are available at {partner}.",
    "Du kannst das auch bequem mit dem Smartphone oder Tablet auf der Couch machen oder auch alle deine Fl\u00e4chen auf einmal importieren.": "You can also do this comfortably on your smartphone or tablet on the couch, or import all your fields at once.",
    "Du kannst deine Felder anderen Benutzern freigeben. Du musst daf\u00fcr lediglich die E-Mail des Empf\u00e4ngers angeben.": "You can share your fields with other users. All you have to do is enter the recipient's e-mail address.",
    "Du kannst die Eigenschaften der neuen Felder noch anpassen.": "You can still customize the properties of the new fields.",
    "Du kannst die Fl\u00e4chen direkt von eAMA mit AMA-Betriebsnummer und AMA-Pin importieren.": "You can import the fields directly from eAMA with AMA farm number and AMA pin code.",
    "Du kannst die Trennlinie durch Ziehen der Eckpunkte anpassen.": "You can adjust the dividing line by dragging the corner points.",
    "Du l\u00f6schst damit auch alle zugeh\u00f6rigen Felder in allen Erntejahren.": "You also delete all associated fields in all crop years.",
    "Du musst in eAMA einmalig die Erlaubnis geben, dass deine Fl\u00e4chen in FARMDOK importieren werden d\u00fcrfen.": "You have to give permission once in eAMA to import your areas into FARMDOK.",
    "Du musst zumindest ein Feld f\u00fcr den Import w\u00e4hlen.": "You have to select at least one field for import.",
    "Duplikate erstellen.": "Create duplicates.",
    "Duplikate im Ziel-Betrieb\/Ziel-Erntejahr": "Duplicates in target farm\/target crop year",
    "Duplikate im Ziel-Erntejahr": "Duplicates in target crop year",
    "Durch das Produkt werden die Inhaltsstoffe (N, P\u2082O\u2085, K\u2082O) definiert. Inhaltsstoffe in Prozent und Menge in kg\/ha k\u00f6nnen aber auch manuell eingegeben werden.": "The ingredients (N, P\u2082O\u2085, K\u2082O) are being defined by the selected product. You can also enter ingredients (% and amount in kg\/ha) manually.",
    "Durch die Freigabe der ausgew\u00e4hlten Felder erh\u00e4lt Dein Vertragspartner SAATBAU LINZ eGen lesenden Zugriff auf die Felder sowie die darauf gebuchten Ma\u00dfnahmen und das Schlagblatt.": "By sharing selected fields, your contractual partner SAATBAU LINZ eGen receives read-only access to the fields as well as the activities and the field record.",
    "Durch erstellen eines FARMDOK Accounts stimmst du den {agb} von FARMDOK zu.": "By creating a FARMDOK account you agree to the {agb} of FARMDOK.",
    "Durchf\u00fchrungsdatum": "Execution date",
    "Durchschnitt": "Average",
    "Durchschnitt:": "Average:",
    "Durchschnittlicher NDVI-Index": "Average NDVI index",
    "Mehrjähriger Biomasseindex auf Basis Blattflächenindex": "Multi-year biomass-index based on the Leaf area index",
    "D\u00fcngekarte": "Fertilization map",
    "D\u00fcngekarte f\u00fcr alle Kulturen": "Fertilization map for any crop",
    "D\u00fcngekarte f\u00fcr Getreide": "Fertilization map for cereals",
    "D\u00fcngeplanung": "Fertilisation plan",
    "D\u00fcngeverordnung": "Fertilizer regulation",
    "D\u00fcngung": "Fertilisation",
    "D\u00dcNGUNG": "FERTILIZATION",
    "D\u00fcngeziel": "Objective of the fertilization",
    "Ebenen": "Layer",
    "E-Mail-Adresse": "E-mail address",
    "E-Mail-Adresse eingeben": "Enter email address",
    "E-mail des Users, der freigegeben hat": "E-mail of the user who shared",
    "Eigene Saatst\u00e4rke": "Custom seed rate",
    "Eigener Betrieb": "Own company",
    "Eigener D\u00fcnger": "Custom fertilizer",
    "Eigent\u00fcmer": "Owner",
    "Ein oder mehrere Felder haben eine ungültige Kontur": "One or more fields have an invalid contour",
    "Ein Feld mit diesem Namen existiert bereits.": "A field with this name already exists. Since you have activated the option 'Field name uniquely required', you must choose another name.",
    "Ein Betriebsleiter kann dir Zugriff gew\u00e4hren, oder du erstellst dir hier deinen eigenen Betrieb.": "A farm manager can grant you access, or you can create your own farm here.",
    "Eine \u00e4hnliche Ma\u00dfnahme mit gleichen Datum, T\u00e4tigkeit, Feld und Produkten existiert bereits.": "A similar activity with the same date, activity type, field and products already exists.",
    "Eine Zeile pro Maschine": "One line per equipment",
    "Eine Zeile pro Ma\u00dfnahme": "One line per activity",
    "Eine Zeile pro Produkt": "One line per product",
    "Einf\u00fcgen (oberhalb)": "Insert (above)",
    "Einheit": "Unit",
    "Einheit kg\/ha": "Unit kg\/ha",
    "Einheiten": "Units",
    "Einloggen": "Login",
    "Einstellungen": "Settings",
    "Einstellungen anzeigen": "Show settings",
    "Einstellungen f\u00fcr die Zonierung": "Settings for zoning",
    "Einstellungen verstecken": "Hide settings",
    "Einzelne, vollständige TASKDATA-Dateien müssen die Bezeichnung \"TASKDATA\" beinhalten. Zusammengehörige Dateien können nur gezipped hochgeladen werden.": "Individual, complete TASKDATA files must contain the term \"TASKDATA\". Files that belong together can only be uploaded in zipped form.",
    "Empfangen": "Receive",
    "Empf\u00e4nger": "Recipient",
    "Enth\u00e4lt nicht:": "Does not contain:",
    "Enth\u00e4lt:": "Contains:",
    "Ergebnis anpassen": "Customize result",
    "Erhalten": "Received",
    "Erledigt": "Done",
    "Erledigt!": "Done!",
    "Erneut senden": "Send again",
    "Ernte": "Harvest",
    "Ernte abschlie\u00dfen": "Complete harvest",
    "Erntedaten": "Harvesting data",
    "Erntedaten f\u00fcr Fl\u00e4chen laden, die nicht \u00fcber FARMDOK an {partner} gemeldet wurden.": "Load harvesting data for fields that were not transmitted to {partner} via FARMDOK.",
    "Erntedaten f\u00fcr {processOrderName} von {partner} importieren.": "Import harvesting data for {processOrderName} from {partner}.",
    "Erntedaten laden": "Load data",
    "Erntedatenimport von {partner}": "Harvesting data import from {partner}",
    "Erntefl\u00e4che gesamt (ha)": "Total harvesting area (ha)",
    "Ernteg\u00fcter": "Harvested crops",
    "Erntejahr": "Crop year",
    "Erntejahr abschlie\u00dfen": "Complete harvesting year",
    "Erntejahr beginnen": "Start new crop year",
    "Erntejahr w\u00e4hlen": "Select crop year",
    "Erntejahr w\u00e4hlen...": "Select crop year...",
    "Erntejahr {current} f\u00fcr die gew\u00e4hlten Kulturen abschlie\u00dfen und {next} als das aktive Jahr setzen.": "Complete crop year {current}\u00a0for the selected crops and set {next} as active crop year.",
    "Erntejahr {newYear} hinzuf\u00fcgen": "Add crop year {newYear}",
    "Erntejahr-Status": "Crop year status",
    "Erntejahr:": "Crop year:",
    "Erntema\u00dfnahmen vorhanden": "Harvest activities available",
    "Erntemenge gesamt (t)": "Total harvesting amount (t)",
    "Erntemenge gesamt: {totalPotatoes} t": "Total harvesting amount: {totalPotatoes} t",
    "Ersetze das Feld im Ziel": "Replace the field in the target",
    "Erstelle eine Kopie des Feldes im Ziel": "Create a copy of the field in the target",
    "Erstelle ein neues Erntejahr": "Create a new crop year",
    "Erstellt": "Created",
    "Ertrag maximieren": "Maximize yield",
    "Es existieren Ma\u00dfnahmen f\u00fcr die ausgew\u00e4hlten Felder. M\u00f6chtest du die Ma\u00dfnahmen auch kopieren?": "Activities exist for the selected fields. Do you want to copy the activities as well?",
    "Es existieren Ma\u00dfnahmen f\u00fcr die ausgew\u00e4hlten Felder. M\u00f6chtest du die Ma\u00dfnahmen auch verschieben?": "Activities exist for the selected fields. Do you want to move the activities as well?",
    "Es existieren mehrere Felder am Ziel.": "There are several fields at the target.",
    "Es hat noch kein Partner Felder bereitgestellt. Lade deine Partner ein, Felder in Farmdok zu kontrahieren.": "No partner has provided fields yet. Invite your partners to share fields in Farmdok.",
    "Es ist ein unbekannter Fehler aufgetreten.": "An unknown error has occurred.",
    "Es k\u00f6nnen Dateien im Format {fileFormat} importiert werden.": "Files in {fileFormat} format can be imported.",
    "Es k\u00f6nnen Dateien im Format ISOXML Taskdata importiert werden.": "Files in ISOXML Taskdata format can be imported.",
    "Es k\u00f6nnen Dateien in den Formaten Shape, XML, KML, XLS, XLSX und CSV importiert werden.": "Files in formats Shape, XML, KML, XLS, XLSX and CSV can be imported.",
    "Es konnte keine Task-Datei heruntergeladen werden": "No task file could be downloaded",
    "Es konnten nicht alle Dateien hochgeladen werden": "Could not upload all files",
    "Es k\u00f6nnen Dateien in den Formaten {fileFormats} und {lastFileFormat} importiert werden.": "Files in formats {fileFormats} and {lastFileFormat} can be imported.",
    "Es m\u00fcssen mindestens 2 Felder gew\u00e4hlt sein.": "At least 2 fields must be selected.",
    "Es sind Filter aktiv. Die folgenden Daten werden exportiert:": "Filters are active. The following data is exported:",
    "Es sind {count} Felder ausgew\u00e4hlt.": "{count} fields are selected.",
    "Es trat ein Fehler auf, bitte versuchen Sie es erneut.": "An error occured, please try it once more.",
    "Es werden keine Felder in {newYear} erstellt.": "No fields will get created in {newYear}.",
    "Es wurden mehrere Operationen durchgef\u00fchrt. Bei mindestens einer ist ein Fehler aufgetreten.": "Several operations have been performed. An error occurred in at least one of them.",
    "Es wurden noch keine Dateien hochgeladen!": "There are no files available yet!",
    "Es wurden noch keine Felder angelegt. Erstelle neue Felder!": "No fields have been created yet. Create new fields!",
    "Es wurden noch keine Felder \u00fcbermittelt. Lade deine Partner ein, Felder in Farmdok freizugeben.": "No fields have been provided yet. Invite your partners to share fields in Farmdok.",
    "Es wurden noch keine Maßnahmen angelegt. Erstelle neue Maßnahmen!": "No activities have been created yet. Create new activities!",
    "Es wurden {count} Felder ausgew\u00e4hlt. Die Felder werden im n\u00e4chsten Schritt zu einem vereint.": "{count} fields have been selected. The fields will be merged into one in the next step.",
    "Es wurden Felder mit verschiedenen Kulturen oder Sorten ausgewählt. Bitte beachte den Sorteneinfluss bei der Wahl der Mittel und Mengen. Mittel und Aufwandmengen müssen für alle ausgewählten Kulturen und Sorten geeignet sein!": "Fields with different crops or varieties were selected. Please consider the influence of the variety when selecting the products and quantities. Products and application rates must be suitable for all selected crops and varieties!",
    "Export": "Export",
    "Exporte": "Exports",
    "Exportieren": "Export",
    "Export pro Tabellenzeile": "Export per table row",
    "FARMDOK Eigenschaft": "FARMDOK property",
    "FARMDOK Eigenschaft \"{fieldName}\" muss gew\u00e4hlt sein.": "FARMDOK property \"{fieldName}\" must be selected.",
    "FARMDOK Feldpositionen": "FARMDOK field locations",
    "FARMDOK konnte den Ursprung deiner Daten leider nicht eindeutig feststellen. Bitte w\u00e4hle aus der folgenden Liste den richtigen Eintrag aus:": "Unfortunately, FARMDOK could not clearly determine the origin of your data. Please select the correct entry from the following list:",
    "FSNr": "Group No.",
    "FSNr - Schlag": "Group No. - Field",
    "Fahrzeit": "Driving time",
    "Fahrzeit minimieren": "Minimize travel time",
    "Falls deine Exportdateien als zip-Datei zur Verf\u00fcgung stehen, kannst du ganz einfach diese zip-Datei inkl. aller Daten in das wei\u00dfe Rechteck ziehen.": "If your export files are available as a zip file, you can simply drag this zip file including all data into the white rectangle.",
    "Falls du einen anderen MyJohnDeere Account verwenden m\u00f6chtest logge dich bitte auf MyJohnDeere.com aus. Dann trenne die Verbindung in FARMDOK. Danach kannst du dich mit dem neuen Account verbinden.": "If you want to use another MyJohnDeere account, logout on MyJohnDeere.com. After that, disconnect in FARMDOK and you will be able to sign in with the other account.",
    "Fast am Ziel!": "Almost there!",
    "Favoriten": "Favorites",
    "Fehler": "error",
    "Fehlerhaft/unvollst\u00e4ndig": "Incorrect/incomplete",
    "Feld": "Field",
    "Feld befindet sich im Wasserschutzgebiet": "Field located in water protection zone",
    "Feld 1": "Field 1",
    "Feld 2": "Field 2",
    "Feld Nr": "Field no",
    "Feld Nummer:": "Field number:",
    "Feld aufteilen": "Split field",
    "Feld ausw\u00e4hlen": "Select field",
    "Feld verschieben": "Move field",
    "Feld {number}": "Field {number}",
    "Feld-Nummer": "Field number",
    "Feld-\u00dcbersicht": "Field overview",
    "Felder": "Fields",
    "Felder aktualisieren.": "Update fields.",
    "Felder ausw\u00e4hlen": "Select fields",
    "Felder bearbeiten": "Edit fields",
    "Felder dürfen nicht die gleichen Namen enthalten.": "Fields must not have the same names, since you have activated the option 'Field name uniquely required'.",
    "Felder einfärben": "Color fields",
    "Felder exportieren": "Export fields",
    "Felder freigeben": "Share fields",
    "Felder hinzuf\u00fcgen": "Add fields",
    "Felder importieren": "Import fields",
    "Felder kombinieren": "Combine fields",
    "Felder kopieren": "Copy fields",
    "Felder kopieren anstatt zu verschieben.": "Copy fields instead of moving them.",
    "Felder nicht kopieren.": "Do not copy fields.",
    "Felder nicht verschieben.": "Do not move fields.",
    "Felder teilen ist nur für Einzelpolygone möglich": "Split fields is only availble for single polygons",
    "Felder und Geodaten teilen": "Share fields and geodata",
    "Felder verschieben": "Move fields",
    "Felder wiederherstellen": "Restore fields",
    "Felder wiederherstellen, die in den letzten 90 Tagen gel\u00f6scht wurden": "Restore fields that have been deleted in the last 90 days",
    "Felder zusammenlegen": "Merge fields",
    "Feldgr\u00f6\u00dfe": "Total field size",
    "Feldkontur l\u00f6schen": "Delete field contour",
    "Feldkontur zeichnen": "Draw field contour",
    "Feldname immer anzeigen": "Always show field name",
    "Feldnutzung": "Field utilization",
    "Feldmitte markieren": "Mark field middle",
    "Feldpositionen": "Field locations",
    "Feldst\u00fcck": "Field group",
    "Kunde - Feldst\u00fcck - Feld": "Customer - Field group - field",
    "Feldst\u00fcck - Feldnummer - Feld": "Field group - field number - field",
    "Feldst\u00fcck Nr": "Field group no",
    "Feldst\u00fcck Nummer": "Field group number",
    "Feldst\u00fcck Nummer:": "Field group number:",
    "Feldst\u00fcck hinzuf\u00fcgen": "Add field group",
    "Feldst\u00fcck konnte nicht wieder hergestellt werden.": "Field group could not be restored.",
    "Feldst\u00fcck-Nummer": "Field group number",
    "Feldst\u00fccke": "Field groups",
    "Feldst\u00fccke wiederherstellen": "Restore field groups",
    "Feldst\u00fccke wiederherstellen, die in den letzten 90 Tagen gel\u00f6scht wurden": "Restore field groups that have been deleted in the last 90 days",
    "Feldst\u00fccknummer - Feldname": "Field group number - field number",
    "Feldst\u00fccksliste": "Field group list",
    "Fertig! Die Felder\/Feldst\u00fccke inkl. Geokonturen wurden importiert und stehen f\u00fcr die Ma\u00dfnahmenbuchung zur Verf\u00fcgung!": "Ready! The fields\/field groups incl. geo contours have been imported and are available for the activity recording.",
    "Fertigstellen": "Finish",
    "Feuchtgebiet": "Humid region",
    "Fileupload zu John Deere fehlgeschlagen.": "File upload to John Deere failed.",
    "Filter aktiviert - {linkStart}Filter deaktivieren{linkEnd} um alle Eintr\u00e4ge anzuzeigen.": "Filter is active - {linkStart}Deactivate filter{linkEnd} to see all entries.",
    "Filter entfernen": "Remove filters",
    "Fl\u00e4chen": "Fields",
    "Fl\u00e4chen ansehen": "View fields",
    "Fl\u00e4chen aus dem Nachfolgejahr \u00fcbernehmen": "Import fields from next year",
    "Fl\u00e4chen aus dem Vorjahr \u00fcbernehmen": "Import fields from previous year",
    "Fl\u00e4chen senden": "Send fields",
    "Fl\u00e4chennutzung": "Field usage",
    "Fl\u00e4chensumme": "Total area",
    "Fl\u00e4chen\u00fcbermittlung": "Field transmission",
    "Frei": "Remaining",
    "Freigaben entfernen": "Remove shares",
    "Freigaben jetzt entfernen.": "Remove shares now.",
    "Freigeben": "Share",
    "Freigeben bis {date}.": "Shared until {date}.",
    "Freigeben bis:": "Share until:",
    "Freigegeben": "Shared",
    "Freigegebene Felder": "Shared fields",
    "Freigabedatum": "Date shared",
    "Freigabe entfernen": "Disable sharing",
    "Freunde einladen": "Invite friends",
    "Fruchtfolge": "Crop rotation",
    "FSNr-Schlag": "FSNr-Field",
    "Fortschritt": "Progress",
    "Futter": "Live stock",
    "F\u00fcr das ausgew\u00e4hlte Feld fehlen die Geo-Daten.": "Geo data is missing for the selected field.",
    "F\u00fcr die Auswertung werden Daten der Sentinel Erdbeobachtungs\u00adsatelliten verwendet. Satelliten- & Wetterdaten zeigen Biomassezuwachs (NDVI- und MSAVI2-Index), Stickstoffverbrauch (REIP-Index) und Wassergehalt (NDWI-Index) auf. Sie sind die optimale Unterst\u00fctzung f\u00fcr effektive Produktionsentscheidungen.": "Data from the Sentinel earth observation satellites in combination with weather data are used to visualize the biomass (NDVI and MSAVI2), the nitrogen content (REIP) and the water content (NDWI) of crops. These indices are the optimal basis for efficient production decisions.",
    "F\u00fcr diese Einheit ist eine Gesamteingabe nicht m\u00f6glich.": "Total entry is not possible for this unit.",
    "F\u00fcr Fragen steht Ihr regionaler Berater gerne zur Verf\u00fcgung (Beraterzuordnung nach Region).": "If you have any questions, please do not hesitate to contact your regional consultant (consultant assignment according to region).",
    "F\u00fcr jede Datei wird ein Link generiert. GeoJSON-Links k\u00f6nnen im Webbrowser ge\u00f6ffnet werden. Max. 10 MB pro Datei.": "For each file a link will be generated. GeoJSON-Links can be viewed in the web browser. Max.size 10 MB per file.",
    "GW Gebiete": "Groundwater area",
    "GW-Gebiet": "Groundwater-area",
    "Feuchtgebiet oder Trockengebiet": "Region (humidity)",
    "Ged\u00fcngt": "Fertilised",
    "gel\u00f6scht am": "deleted on",
    "Gemeinde": "Local community",
    "Gem\u00fcse": "Vegetable",
    "Geplant": "Planned",
    "Geplant setzen": "Set planned",
    "Gering": "Low",
    "gering": "low",
    "Gesamtanzahl der bereitgestellten Felder": "Total amount of provided fields",
    "Gesamtfl\u00e4che": "Total area",
    "Gesamtfl\u00e4che aus Erntedaten: {totalSize} ha": "Total area from harvesting data: {totalSize} ha",
    "Gesamtfl\u00e4che {totalArea} ha": "Total area {totalArea} ha",
    "Gesamtfl\u00e4che in ha": "Total area in ha",
    "Gesamt": "Total",
    "Gesamtanzahl": "Total",
    "Gesamtmenge": "Total amount",
    "Geschafft!": "You made it!",
    "Gesendet": "Sent",
    "Geteilt von": "Shared by",
    "Geteilte Informationen:": "Shared information:",
    "Gew\u00e4hlten Eintrag wirklich l\u00f6schen?": "Do you really want to delete the selected entry?",
    "Gew\u00e4hlte Eintr\u00e4ge wirklich l\u00f6schen?": "Do you really want to delete the selected entries?",
    "Gew\u00e4hlte Fl\u00e4che: {sizeTotalFormatted} ha": "Selected area: {sizeTotalFormatted} ha",
    "GIS-Layer": "GIS-Layer",
    "Gleiche Zonengr\u00f6\u00dfe": "Equal zone size",
    "Gleicher Indexabstand": "Equal index distance",
    "Grobe T\u00e4tigkeiten": "Rough activity types",
    "Gruppiert": "Grouped",
    "Gr\u00f6\u00dfe": "Size",
    "Gr\u00f6\u00dfe (ha)": "Size (ha)",
    "Gr\u00f6\u00dfe in ha": "Size in ha",
    "Gr\u00f6\u00dfe (in ha)": "Size (ha)",
    "Gr\u00f6\u00dfe (in ha):": "Size (ha):",
    "Gr\u00f6\u00dfer als:": "Greater than:",
    "Gr\u00fcnland und Feldfutterbau": "Greenland and forage cropping",
    "Gutscheine verwalten": "Manage vouchers",
    "Heil- und Gew\u00fcrzpflanzen": "Medicinal and aromatic plants",
    "Hektar": "Hectare",
    "Herbstantrag (HA)": "Autumn application (HA)",
    "Herunterladen": "Download",
    "Hier gehts zur Registrierung.": "Click here to register.",
    "Hier kannst du alle Freigaben f\u00fcr einen Betrieb im aktuellen Erntejahr entfernen.": "Here you can remove all shares for an account in the current crop year.",
    "Hier kannst du dein neues Passwort eingeben.": "You can enter your new password here.",
    "Hier kannst du deine eigenen Zonenkarten hochladen um daraus eine Applikationskarte zu erstellen. Bitte verwende Daten im Shape-Format.": "Here you can upload your own zone maps to create an application map. Please use data in shape format.",
    "Hilfe": "Help",
    "Hinweise f\u00fcr": "Indication for",
    "Hinweis: die maximale Prüfdistanz beträgt": "Note: the maximum inspection distance is {maxDistance}.",
    "Humus": "Humus",
    "Hoch": "High",
    "hoch": "high",
    "Low": "Low",
    "Medium": "Medium",
    "High": "High",
    "niedriger": "lower",
    "höher": "higher",
    "Hochformat": "Portrait",
    "Hochgeladen": "Uploaded",
    "Häufigkeit": "Occurrences",
    "Isolationszonen": "Isolation zones",
    "Isolationszone erstellen": "Create isolation zone",
    "Isolationszone bearbeiten": "Edit isolation zone",
    "Isolationszone löschen": "Delete isolation zone",
    "ISOXML": "ISOXML",
    "Ich habe bereits einen Account": "I already have an account",
    "Ich habe die Vereinbarungen gelesen und stimme der Daten\u00fcbertragung von FARMDOK zu {partner} zu genannten Zwecken ausdr\u00fccklich zu.": "I have read the agreements and agree to the transmission of data from FARMDOK to {partner} for the aforementioned reasons.",
    "Ich habe die Vereinbarungen gelesen und stimme der Daten\u00fcbertragung zu genannten Zwecken ausdr\u00fccklich zu.": "I have read the agreements and agree to the transmission of data for the aforementioned reasons.",
    "Ich verstehe, Services & Support erfolgen per E-Mail.": "I understand, services & support are provided by email.",
    "Ihre Organisation auf MyPLM® Connect, AFS Connect oder S-FLEET": "Your organization on MyPLM® Connect, AFS Connect or S-FLEET",
    "Im Erntejahr {previous} wurde ein Feld auf aktiv gestellt.": "In crop year {previous} one field was set to active.",
    "Im Erntejahr {previous} wurden {numberOfNewActiveFieldsInPrevious} Felder auf aktiv gestellt.": "In crop year {previous}, {numberOfNewActiveFieldsInPrevious} fields were set to active.",
    "Im Shape-Format exportieren": "Export in shape format",
    "Import in Farmdok": "Import to Farmdok",
    "Import zur\u00fccksetzen": "Reset import",
    "importiert": "imported",
    "Importieren": "Import",
    "Importiere Dateien in den Formaten Shape und GeoJSON.": "Import files in the format Shape or GeoJSON.",
    "Importieren von Ma\u00dfnahmen aus externen Quellen": "Import activities from a third party source",
    "Importiere Dateien in den Formaten Shape und GeoJSON. F\u00fcr jede Datei wird ein Link generiert. GeoJSON-Links k\u00f6nnen im Webbrowser ge\u00f6ffnet werden.": "Import files in the format Shape or GeoJSON. For each file a link will be generated. GeoJSON-Links can be viewed in the FARMDOK mobile app.",
    "Maßnahmen aus AFS Connect, S-Fleet und PLMConnect importieren": "Import activities from AFS Connect, S-Fleet and PLMConnect",
    "Möchten Sie die Bewertung wirklich abbrechen?": "Do you really want to cancel the assessment?",
    "Impressum": "Legal notice",
    "In FARMDOK nach dem Import.": "In FARMDOK after importing.",
    "In der hochgeladenen Datei befinden sich mehrere Werte. Welcher davon soll zum Erstellen der Zonen benutzt werden?": "There are multiple values in the uploaded file. Which one of them should be used to create the zones?",
    "In diesem Erntejahr sind noch keine Fl\u00e4chen verf\u00fcgbar.": "There are no fields available yet this crop year.",
    "Index ausw\u00e4hlen": "Select index",
    "Inhaltsstoffe des D\u00fcngers in %": "Ingredients of the fertilizer in %",
    "Inhaltsstoffe des D\u00fcngers in % und Ausbringmenge am Feld in kg/ha": "Ingredients of the fertilizer in % and application rate on the field in kg/ha",
    "Inhaltsstoffe in %": "Ingredients in %",
    "Ist genau:": "Equals:",
    "Ja, Ma\u00dfnahmen auch in das Ziel-Erntejahr kopieren.": "Yes, copy activities into the target crop year as well.",
    "Ja, Ma\u00dfnahmen auch in das Ziel-Erntejahr verschieben.": "Yes, move activities into the target crop year as well.",
    "Ja, ich will den FARMDOK Newsletter bekommen!": "Yes, I want to receive the FARMDOK newsletter.",
    "Jahr": "Year",
    "Ja": "Yes",
    "Jahre": "Years",
    "Jetzt Lizenz upgraden": "Upgrade license now",
    "Jetzt kaufen": "Buy now",
    "Jetzt starten": "START NOW",
    "Jetzt starten!": "Start now!",
    "Jetzt verschieben": "Move now",
    "Jetzt upgraden auf FARMDOK PERFORMANCE!": "Upgrade now to FARMDOK PERFORMANCE!",
    "K": "K",
    "Karte": "Map",
    "Karte erstellen": "Create map",
    "Karte anzeigen": "View map",
    "Katastralgemeinde": "Cadastral district",
    "Kein Betrieb gew\u00e4hlt": "No company selected",
    "Kein Enddatum festlegen": "Do not set an end date",
    "Kein Enddatum gesetzt.": "No end date set.",
    "Kein g\u00fcltiger Sch\u00e4dling vorhanden": "No valid pest available",
    "Kein weiteres Feld": "No further field",
    "Kein Vorschlag (manuelle Eingabe)": "No recommendation (manual entry)",
    "Keine": "None",
    "Keine Daten verf\u00fcgbar.": "No data available.",
    "Keine Eintr\u00e4ge vorhanden.": "No entries available.",
    "Keine Ergebnisse gefunden. \u00c4ndere den Suchbegriff.": "No results found. Change the search term.",
    "Keine Felder vorhanden": "No fields available",
    "Keine Feldposition": "No field location",
    "Keine Kontrakte vorhanden.": "No contracts found",
    "Keine Kultur": "Crop missing",
    "Keine Tätigkeit": "No activity",
    "Keine Kultur gepflegt": "No crop selected",
    "Keine Kulturdaten vorhanden.": "Crop data not available.",
    "Keine Organisation in deinem John Deere Account vorhanden.": "No organizations available in your John Deere account.",
    "Keine Satellitendaten f\u00fcr den ausgew\u00e4hlten Zeitraum verf\u00fcgbar.": "No satellite data available for the selected time period.",
    "Keine Wetterdaten für den ausgewählten Zeitraum verfügbar.": "No weather data available for the selected time period.",
    "Keine Shape-Datei im Zip gefunden.": "No shape file found in the ZIP archive.",
    "Keine Sorte": "No variety",
    "Keine aktiven Kulturen im aktuellen Erntejahr vorhanden.": "No active crops available in the current crop year.",
    "Keine Warnhinweise gefunden": "No warnings found",
    "Keines der gew\u00e4hlten Felder besitzt eine Kontur.": "None of the selected fields has a contour.",
    "Kleiner als:": "Smaller than:",
    "Klicke auf \"Aufteilen\" um zwei neue Felder zu erstellen.": "Click on \"Split\" to create two new fields.",
    "Klicke auf den Link in der E-Mail um dein Passwort zur\u00fcckzusetzen.": "Click on the link in the e-mail to reset your password.",
    "Klicke auf den Link in der E-Mail um deinen Account zu aktivieren.": "Click on the link in the e-mail to activate your account.",
    "Klicke hier f\u00fcr mehr Infos ...": "Click here for more info ...",
    "Klicke hier um dich einzuloggen.": "Click here to log in.",
    "Klicke in der Karte auf ein Feld um es hinzuzuf\u00fcgen.": "Click on a field in the map to add it.",
    "Klicke in der Karte um ein neues Feld hinzuzuf\u00fcgen.": "Click on the map to add a new field.",
    "Klicke in der Karte um eine Trennlinie zu zeichnen. Klicke zwei mal schnell hintereinander um das Zeichnen zu beenden.": "Click on the map to draw a dividing line. Double click to finish drawing.",
    "Klicke in die Karte, um die Auswahl zu ver\u00e4ndern.": "Click on the map to change the selection.",
    "Klicken oder Zip-Datei hier ablegen.": "Click or drag and drop a ZIP file here",
    "KMZ": "KMZ",
    "Kombinieren": "Combine",
    "Kommentar": "Comment",
    "Konflikt": "Conflict",
    "Kontrahiert": "Contracted",
    "Kontakt E-Mail": "Contact email",
    "Kornqualit\u00e4t": "Grain quality",
    "Konventionell": "Conventional",
    "Kosten senken": "Reduce costs",
    "Kostenrechnung": "Cost accounting",
    "Kultur": "Crop",
    "Kultur f\u00fcr alle Felder \u00fcbernehmen": "Apply crop for all fields",
    "Kultur in der Datenquelle (aus dem Import)": "Crop in the data source (from import)",
    "Kultur:": "Crop:",
    "Kulturen": "Crops",
    "Kulturen & Greening-Kennzahlen": "Crops & Greening Key Figures",
    "Kulturen ohne Erntema\u00dfnahmen": "Crops without harvesting activities",
    "Kulturen, Feldst\u00fccknutzung, Anbaudiversivizierung und \u00f6kologische Vorrangfl\u00e4chen.": "Crops, field plot use, cropping diversification, and ecological priority areas.",
    "Kulturverh\u00e4ltnis": "Crop ratio",
    "Kunde": "Customer",
    "Kunden": "Customers",
    "Kundennummer": "Customer number",
    "Kundenverwaltung": "Customer management",
    "Kundenzufriedenheit erh\u00f6hen": "Increase customer satisfaction",
    "K\u2082O-Klasse": "K\u2082O class",
    "LAI - Blattfl\u00e4chenindex": "LAI - Leaf area index",
    "Lade hoch ...": "Uploading ...",
    "Lade..": "Loading ...",
    "Lade...": "Loading...",
    "Drohnenbilder hochladen, um ein neues Assessment zu starten": "Upload drone images to start a new assessment",
    "Lager": "Storage",
    "Lagerbewegungen": "Stock movement",
    "Lagerbewegung anlegen": "Create stock movement",
    "Lagerst\u00e4nde": "Stock",
    "Lagerstand anwenden": "Apply leftover storage",
    "Lagerstand zu niedrig": "Stock level too low",
    "Land": "Country",
    "Grundstückskataster": "Land cadastres",
    "Landwirt": "Farmer",
    "Lass uns zusammen arbeiten!": "Let's work together!",
    "Leg deine Datei hier ab": "Drop your file here",
    "Legendentitel": "Legend title",
    "Legende": "Legend",
    "Leider k\u00f6nnen f\u00fcr dieses Feld keine Wetterdaten geladen werden.": "Unfortunately, weather data cannot be loaded for this field.",
    "Link": "Link",
    "Link kopieren": "Copy link",
    "Link in die Zwischenablage kopieren": "Copy link to clipboard",
    "Link zu AGES Register": "Link to AGES register",
    "Link zur Datei": "Link to the file",
    "Liste aller Felder gruppiert nach Kulturen.": "List of all fields grouped by crops.",
    "Liste aller Feldgruppen und Felder im gew\u00e4hlten Erntejahr.": "List of all field groups and fields in the selected crop year.",
    "Liste aller Feldst\u00fccke und Schl\u00e4ge im gew\u00e4hlten Erntejahr.": "List of all field groups and fields in the selected crop year.",
    "Liter pro m\u00b2 Niederschlag im {month} {year}": "Liters per m\u00b2 rainfall in {month} {year}",
    "Liter pro m\u00b2 Niederschlag in {year}": "Liters per m\u00b2 rainfall in {year}",
    "Live check l\u00e4uft...": "Live check in progress...",
    "Login": "Login",
    "Login mit MyJohnDeere": "Login with MyJohnDeere",
    "Login mit CNH Platformen": "Login with CNH platforms",
    "Lohnunternehmer": "Contractor",
    "L\u00f6schen": "Delete",
    "MSAVI2 - Biomasse": "MSAVI2 - Biomass",
    "MAISBUTLER Anfrage": "MAISBUTLER demand",
    "Mais": "Maize",
    "Mahlen": "Milling",
    "Manuelle Eingabe": "Manual values",
    "Maschine": "Equipment",
    "Maschine ausw\u00e4hlen": "Select equipment",
    "Maschine entfernen": "Remove equipment",
    "Maschine hinzuf\u00fcgen": "Add equipment",
    "Maschinen": "Machines",
    "Maschinen & Ger\u00e4te": "Machinery and equipment",
    "Maschinen & Ger\u00e4te Kategorien": "Machinery & equipment categories",
    "Maschinen & Ger\u00e4te Listen": "Machinery & equipment lists",
    "Max. Dateigr\u00f6\u00dfe: {maxFileSize} MB": "Max. file size: {maxFileSize} MB",
    "Material": "Product",
    "Materialauswahl": "Select product",
    "Maximale Anzahl von Anwendungen:": "Max. number of treatments:",
    "Maximale Anzahl von Anwendungen pro Erntejahr:": "Max. number of treatments per crop year:",
    "Maximale Saatst\u00e4rke": "Maximum seed rate",
    "Maximale Menge": "Maximum amount",
    "Maximale Reduktion der Produkt-Konzentration": "Maximum reduction of product concentration",
    "Maximum:": "Maximum:",
    "Maximale Anwendungsmenge überschritten!": "Maximum amount of application exceeded!",
    'Ma\u00dfnahme erfolgreich verschoben nach {cropYear} auf {companyName}': 'Activity successfully moved to {cropYear} on {companyName}',
    'Ma\u00dfnahmen erfolgreich verschoben nach {cropYear} auf {companyName}': 'Activities successfully moved to {cropYear} on {companyName}',
    "Ma\u00dfnahme konnte nicht wieder hergestellt werden.": "Activity could not be restored.",
    "Ma\u00dfnahme hinzufügen": "Add activity",
    "Ma\u00dfnahme und Datum ausw\u00e4hlen": "Select activity type and date",
    "Ma\u00dfnahmen": "Activities",
    "Ma\u00dfnahmen Neu - Work In Progress": "Activities new - Work in progress",
    "Ma\u00dfnahmen auf beide Felder kopieren": "Copy activities to both fields",
    "Ma\u00dfnahmen auf {fieldName} ({fieldSize} ha) \u00fcbernehmen": "Apply activities to {fieldName} ({fieldSize} ha)",
    "Ma\u00dfnahmen buchen": "Create activities",
    "Ma\u00dfnahme duplizieren": "Duplicate activity",
    "Ma\u00dfnahmen duplizieren": "Duplicate activities",
    "Ma\u00dfnahme erfolgreich dupliziert": "Activity successfully duplicated",
    "Ma\u00dfnahmen erfolgreich dupliziert": "Activities successfully duplicated",
    "Ma\u00dfnahmen importieren": "Import activities",
    "Ma\u00dfnahme konnte nicht dupliziert werden": "Activity could not be duplicated",
    "Ma\u00dfnahmen konnten nicht dupliziert werden": "Activities could not be duplicated",
    "Ma\u00dfnahmen l\u00f6schen": "Delete activities",
    "Ma\u00dfnahme konnte nicht erfolgreich verschoben werden nach {cropYear} auf {companyName}": "Activity could not be moved to {cropYear} on {companyName}",
    "Ma\u00dfnahmen konnten nicht erfolgreich verschoben werden nach {cropYear} auf {companyName}": "Activities could not be moved to {cropYear} on {companyName}",
    "Ma\u00dfnahmen mit ISOXML Taskdata importieren": "Import activities with ISOXML Taskdata",
    "Ma\u00dfnahmen speichern": "Save activities",
    "Ma\u00dfnahmen teilen": "Share activities",
    "Ma\u00dfnahme verschieben": "Move activity",
    "Ma\u00dfnahmen verschieben": "Move activities",
    "Ma\u00dfnahmen wiederherstellen": "Restore activity",
    "Ma\u00dfnahmen wiederherstellen, die in den letzten 90 Tagen gel\u00f6scht wurden": "Restore activities that have been deleted in the last 90 days",
    "Ma\u00dfnahmen \u00fcbernehmen": "Apply activities",
    "Maßnahmen von CNH-Industrial importieren": "Import activities from CNH-Industrial",
    "Mapping zur\u00fccksetzen": "Reset mapping",
    "MBI": "MBI",
    "MBI - Mehrjährige Biomasse": "MBI - Perennial biomass",
    "mechanische Pflegema\u00dfnahmen": "mechanical maintenance measures",
    "Mehr": "More",
    "Mehr anzeigen": "Show more",
    "Mehr erfahren": "Learn more",
    "Mehrfachantrag (MFA)": "Mehrfachantrag (MFA)",
    "Mein Profil": "My profile",
    "Meine aktuellen Favoriten": "My recent favourites",
    "Meine Rechnungen": "My invoices",
    "Meine Vertr\u00e4ge": "My contracts",
    "Meinen pers\u00f6nlichen Berater kontaktieren...": "Contact my personal adviser...",
    "Menge": "Amount",
    "Menge (t)": "Amount (t)",
    "Menge in kg\/ha": "Amount in kg\/ha",
    "Menge teilfl\u00e4chen-spezifisch verteilen": "Distribute the amount per zone",
    "Men\u00fc": "Menu",
    "Men\u00fc einklappen": "Close menu",
    "Messen beenden": "Close measuring",
    "Messen von Strecken": "Measure distances",
    "Mg": "Mg",
    "Mg-Klasse": "Mg class",
    "Mindestabstand": "Min. Distance",
    "Minerald\u00fcnger": "Mineral fertilizer",
    "Minerald\u00fcngung": "Mineral fertilzation",
    "Minimum:": "Minimum:",
    "Minimale Saatst\u00e4rke": "Minimum seed rate",
    "Minimale & maximale Saatst\u00e4rke": "Minimum & maximum seed rate",
    "Min/max Saatst\u00e4rke \u00e4ndern": "Change min/max seed rate",
    "Mit diesem Schieberegler kannstt assignment according to region) du kalkulierbare Verluste ber\u00fccksichtigen und die Saatstärke pauschal korrigieren (1-15%). Pflanzenverluste k\u00f6nnen beispielsweise auftreten durch:": "With this slider you can take calculable losses into account and correct the seed rate (1-15%). Plant losses can occur, for example, due to:",
    "Mit Farmdok können Sie die Dokumentation der durchgeführten Feldarbeiten direkt von der CNH-Plattform laden und automatisch Maßnahmen in Farmdok erstellen. Alles, was du dazu brauchst, ist ein Account bei New Holland MyPLM® Connect, Case AFS Connect oder STEYR S-FLEET und ein geeingetes ISOBUS Display auf deinem Traktor.": "With Farmdok, you can load documentation of field work performed directly from the CNH platform and automatically create activities in Farmdok. All you need is an account with New Holland MyPLM® Connect, Case AFS Connect or STEYR S-FLEET and a set-up ISOBUS display on your tractor.",
    "Mit FARMDOK kannst du Auftr\u00e4ge bzw. Applikationskarten direkt an deine John Deere Maschinen schicken. Daf\u00fcr ben\u00f6tist du lediglich einen Account auf MyJohnDeere.com und musst deine Maschinen mit diesem Account verkn\u00fcpfen. Du kannst die Applikationskarte auch ohne Account \u00fcbertragen indem du sie herunterl\u00e4dst und h\u00e4ndisch mit einem USB-Stick \u00fcbertr\u00e4gst.": "With FARMDOK you can send tasks or application maps directly to your John Deere machines. You need a MyJohnDeere.com account and connect your machines with it. Alternatively you can download the application map and transfer it to your machines via USB drive.",
    "Mit Google anmelden": "Login with Google",
    "Mittel": "Medium",
    "mittel": "medium",
    "Mittlere Dosierung (kg\/ha)": "Medium dosage (kg\/ha)",
    "Mittlere Dosierung in kg\/ha \u00fcber alle ausgew\u00e4hlten Fl\u00e4chen, N\u00e4hrstoffe in kg\/ha": "Average dosage in kg\/ha for all selected areas,\nnutrient values in kg\/ha",
    "Monate": "Months",
    "Monitoring": "Monitoring",
    "Variable Pflanzenschutzkarte": "Variable spraying map",
    "Vermehrungsgemeinschaft": "Multiplier group",
    "Vermehrungsgemeinschaften": "Multiplier groups",
    "Vermehrungsgemeinschaft erstellen": "Create multiplier group",
    "Vermehrungsgemeinschaft bearbeiten": "Edit multiplier group",
    "Vermehrungsgemeinschaft löschen": "Delete multiplier group",
    "MyJohnDeere": "MyJohnDeere",
    "MyJohnDeere \u00f6ffnen": "Open MyJohnDeere",
    "M\u00e4hdrusch": "Combining",
    "M\u00f6chtest Du mit diesen Werten fortsetzten oder die Einstellungen auf die empfohlenen Werte zur\u00fccksetzen?": "Do you want to continue with these values or reset the settings to the recommended values?",
    "M\u00f6chtest du fortfahren?": "Would you like to continue?",
    "N": "N",
    "NDVI": "NDVI",
    "NDVI - Biomasse": "NDVI - Biomass",
    "NDWI - Wassergehalt": "NDWI - Water contents",
    "Nachname": "Last name",
    "nach Kultur": "by crop",
    "nach letzter Tätigkeit": "by last activity",
    "nach NDVI": "by NDVI",
    "nach MBI": "by MBI",
    "nach Sorte": "by variety",
    "N\u00e4chster Konflikt": "Next conflict",
    "N\u00e4chster Schritt": "Next step",
    "Name": "Name",
    "Name des Sender-Betriebs": "Name of the sender account",
    "Nein, Ma\u00dfnahmen an der Quelle l\u00f6schen.": "No, delete activities at the source.",
    "Nein, nur Felder kopieren.": "No, only copy fields.",
    "Nein": "No",
    "Neu": "New",
    "Neu laden": "Reload",
    "Neues Feld": "New field",
    "Neues Assessment starten": "Start new assessment",
    "Nicht alle importierten Kulturen konnten FARMDOK Kulturen zugeordnet werden.": "Not all imported crops could be mapped to FARMDOK crops.",
    "Nicht gepflegt": "Not set",
    "Nicht \u00fcbernehmen": "Do not apply",
    "Niederschlag": "Rainfall",
    "Kumulierter Niederschlag": "Accumulated precipitation",
    "Noch keine Eintr\u00e4ge vorhanden.": "No entries available yet.",
    "Noch keine Erntedaten f\u00fcr <strong>{name}<\/strong> verf\u00fcgbar.": "No harvesting data available for <strong>{name}<\/strong>.",
    "Noch keine Fruchtfolge vorhanden.": "No crop rotation available yet.",
    "Noch keine Ma\u00dfnahmen vorhanden.": "No activities available yet.",
    "Noch keine Notizen vorhanden.": "No notes available yet.",
    "NPK in %": "NPK in %",
    "Notizen": "Notes",
    "Notizen und Arbeitsanweisungen": "Notes and work instructions",
    "Nr": "No.",
    "Nummer": "Number",
    "Nutzung durch": "User",
    "Nutzungsart": "Type of use",
    "Nutzungsarten": "Types of usage",
    "Nuzungsart in der Datenquelle (aus dem Import)": "Kind of use in the data source (from the import)",
    "N\u00e4hrstoffbilanz": "Nutrient balance",
    "N\u00e4hrstoffbilanz teilen": "Share nutrient balance",
    "N\u00e4hrstoffe eingeben": "Enter nutrient values",
    "N\u00e4hrstoffmenge": "Nutrient amount",
    "N\u00e4hrstoffmenge ändern": "Change nutrient amount",
    "N\u00e4hrstoffvergleich": "Nutritient comparison",
    "N-Rate 1. Gabe (kg/ha)": "N-rate 1st application (kg/ha)",
    "N-Rate 2. Gabe (kg/ha)": "N-rate 2st application (kg/ha)",
    "OK": "OK",
    "Obst und Hopfen": "Fruits and hops",
    "Offene Vertr\u00e4ge": "Open calls",
    "Optionen": "Options",
    "Optionen schlie\u00dfen": "Close options",
    "Organisation": "Organisation",
    "Organisation verbessern": "Improve organization",
    "Originalgr\u00f6\u00dfe": "Original size",
    "Ort": "Location",
    "Other": "Other",
    "P": "P",
    "PDF Herunterladen": "Download PDF",
    "PLZ": "Postal code",
    "Packungen": "packages",
    "Papierlos arbeiten": "Work paperless",
    "Partner": "Partners",
    "Partner-Nummer": "Partner number",
    "Partner-Nummer \u2013 Siehe Zugangsdaten auf <a href=\"https:\/\/ris.agrana.com\" target=\"_blank\">https:\/\/ris.agrana.com<\/a>": "Partner number \u2013\u00a0see login data at <a href=\"https:\/\/ris.agrana.com\" target=\"_blank\">https:\/\/ris.agrana.com<\/a>",
    "Partner-Nummer, Kunden-Nummer, CRM Id": "Partner Number, Customer Number, CRM Id",
    "Partner-Status": "Partner status",
    "Partner w\u00e4hlen": "Select partner",
    "Parzellen": "Plot",
    "Parzellen Nr.": "Plot number",
    "Parzellennummer suchen": "Search parcel number",
    "Passende Kulturen": "Matching crops",
    "Passende Sorten": "Matching varieties",
    "Passwort": "Password",
    "Passwort speichern": "Save password",
    "Passwort vergessen?": "Forgot password?",
    "Passwort zur\u00fccksetzen": "Reset password",
    "Pause": "Break",
    "Permissions": "Permissions",
    "Personen": "People",
    "Pers\u00f6nliche Einstellungen": "Personal settings",
    "PFLANZENSCHUTZ": "SPRAYING",
    "Pflanzenschutz": "Plant protection",
    "Pflanzenschutzmittelkatalog \u00f6ffnen": "Open plant protection",
    "Pflanzenschutzmittel": "Plant protection products",
    "Pflichtfeld": "Required",
    "Planung": "Planning",
    "Plattform": "Platform",
    "Plattform öffnen": "Open platform",
    "Positionen senden": "Send locations",
    "Postleitzahl": "Postal code",
    "Precision Farming": "Precision farming",
    "Premium": "Premium",
    "Probleme? Bitte schreib uns an support@farmdok.com und \u00fcbermittle die verwendeten Importdateien.": "Problems? Please write to us at support@farmdok.com and submit the import files used.",
    "Produkt": "Product",
    "Produkt ausw\u00e4hlen": "Select product",
    "Produkt entfernen": "Remove product",
    "Produkt hinzuf\u00fcgen": "Add product",
    "Produkte": "Products",
    "Produkt vor Menge einstellen": "Set product before setting amount",
    "Produkt w\u00e4hlen": "Select product",
    "Produkt- & Maschinenauswahl": "Select product & equipment",
    "Produktivit\u00e4t steigern": "Increase productivity",
    "Produktkombination": "Product combination",
    "Profil": "Profile",
    "Prognose": "Forecast",
    "Prüfstand in m": "Distance in m",
    "P\u2082O\u2085-Klasse": "P\u2082O\u2085 class",
    "Randbereich korrigiert": "Border area corrected",
    "Randbereich nicht korrigiert": "Border area not corrected",
    "REIP - Stickstoffgehalt": "REIP - Nitrogen content",
    "Referenz oder Freigabegrund": "Reference or share reason",
    "Referenz-Nummer": "Reference number",
    "Reine Kartoffel (t)": "Pure potato (t)",
    "Reine R\u00fcbe (t)": "Pure beets (t)",
    "Reinekartoffel: {amountPotato} St\u00e4rkegehalt: {amountStarch}": "Pure potatoes: {amountPotato} Starch content: {amountStarch}",
    "Report": "Report",
    "Reports": "Reports",
    "R\u00fcstzeit": "Set-up time",
    "Risikokategorie": "Risk category",
    "Saatbau Mais Aussaatkarte": "Saatbau maize seeding map",
    "Saatbett-Bedingungen (Scholligkeit, Wasserverf\u00fcgbarkeit, …)": "seedbed conditions (pout, water availability, ...)",
    "Saatgut": "Seeds",
    "Saatdatum": "Seeding date",
    "Saatgut jetzt bestellen SESAM24": "Order seeds now SESAM24",
    "Saatst\u00e4rke in K\u00f6rner/ha": "Seed rate in grains/ha",
    "Saatst\u00e4rke": "Seed strength",
    "Saatzeitpunkt au\u00dferhalb des Optimums": "sowing time outside the optimum",
    "Sales": "Sales",
    "Satellit": "Satellite",
    "Satellitenbild": "Satellite image",
    "Satellitenbilder": "Satellite images",
    "Satellitenbilder am gew\u00e4hlten Datum anzeigen": "Show satellite images for selected date",
    "Satellitenindex": "Satellite index",
    "Schlag": "Field",
    "Schlag Nr": "Field No",
    "Schlagblatt": "Field record",
    "Schlagblatt f\u00fcr {fieldName}": "Field record for {fieldName}",
    "Schlagblatt teilen": "Share field record",
    "Schlie\u00dfen": "Close",
    "Schnee": "Snow",
    "Schritt zurück": "Previous step",
    "Sch\u00e4dling ausw\u00e4hlen": "Select pest",
    "Seite drucken": "Print page",
    "Seite verlassen": "Leave",
    "Sekund\u00e4rrohstoffe": "Secondary raw materials",
    "Selektierte Felder exportieren": "Export selected fields",
    "Sende Applikationskarte": "Send application map",
    "Sende die Applikationskarte direkt an die Cloud-Plattform deines Maschinenherstellers.": "Send your application map directly to the cloud platform of your machines' manufacturer.",
    "Sende deinem Partner einen Link, der ihn direkt zum Vertrag führt. Er kann dort mit wenigen Klicks Felder freigeben.": "Send your partner a link that takes them directly to the contract. They can contract fields there with a few clicks.",
    "Senden": "Send",
    "Senden an": "Send to",
    "Sender": "Sender",
    "Sender-Referenz": "Sender reference",
    "Setze das Feld \"aktiv\", wenn es ab sofort im angezeigten Erntejahr {current} bewirtschaftet werden soll. Vorangehende Erntejahre werden auf diesem Feld abgeschlossen.": "Set the field \"active\" if it is to be farmed from now on in the displayed crop year {current}. Previous crop years will be completed for this field.",
    "Setze das Feld auf \"abgeschlossen\", sobald die Ernte f\u00fcr das Erntejahr {current} beendet wurde. Das Feld wird automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.": "Set the field to \"completed\" once the harvest for crop year {current} is finished. The field will be automatically created and set \"active\" in the next crop year {next}.",
    "Setze das Feld auf \"geplant\", wenn das Erntejahr {current} auf diesem Feld noch nicht begonnen hat.": "Set the field to \"planned\" if the crop year {current} has not yet started on this field.",
    "Setze dein neues Passwort": "Set your new password",
    "Setze den Status auf \"geplant\", wenn das Erntejahr {current} auf den ausgew\u00e4hlten Feldern noch nicht begonnen hat.": "Set the status to \"planned\" if the crop year {current} has not yet started on the selected fields.",
    "Setze die ausgew\u00e4hlten Felder \"aktiv\", wenn sie ab sofort im angezeigten Erntejahr {current} bewirtschaftet werden sollen. Vorangehende Erntejahre werden auf diesen Feldern abgeschlossen.": "Set the selected fields \"active\" if they are to be farmed from now on in the displayed crop year {current}. Previous crop years will be completed on these fields.",
    "Setze die ausgew\u00e4hlten Felder auf \"abgeschlossen\", sobald die Ernte f\u00fcr das Erntejahr {current} beendet wurde. Die Felder werden automatisch im n\u00e4chsten Erntejahr {next} angelegt und \"aktiv\" gesetzt.": "Set the selected fields to \"completed\" once the harvest for crop year {current} is finished. The fields will be automatically created and set \"active\" in the next crop year {next}.",
    "Setze mittlere Dosierung in kg/ha": "Set average amount in kg/ha",
    "Seitenausrichtung": "Orientation",
    "Shapefile": "Shapefile",
    "Shop": "Shop",
    "Sichtbare Eintr\u00e4ge": "Visible entries",
    "Sie sind nun im Erntejahr {current} aktiv und werden in den vorangehenden Erntejahren auf abgeschlossen gestellt.": "They are now active in crop year {current} and are set to complete in previous crop years.",
    "Sie wurden f\u00fcr {current} auf geplant gestellt.": "They were set to planned for {current}.",
    "Sie wurden im Erntejahr {current} abgeschlossen und werden in {next} aktiv gestellt.": "They were completed in the {current} crop year and will be set to active in {next}.",
    "Sollen bereits gebuchte Ma\u00dfnahmen f\u00fcr das neue Feld \u00fcbernommen werden?": "Should activities that have already been recorded be transferred to the new field?",
    "Sollen die Fl\u00e4chen aus dem Vorjahr \u00fcbernommen werden?": "Should the areas from the previous year be taken over?",
    "Sollen die Fl\u00e4chen aus dem nachfolgenden Erntejahr \u00fcbernommen werden?": "Should the land be taken over from the following crop year?",
    "Sollte die E-Mail nicht auffindbar sein, {linkStart}klicke hier, um sie nochmal zu senden.{linkEnd}": "If the e-mail cannot be found, {linkStart}click here to resend it.{linkEnd}",
    "Sonstige": "Other operations",
    "Sonstige Gr\u00fcnlandarbeiten": "Other grassland work",
    "Sonstige Hilfsstoffe und D\u00fcnger": "Other auxiliary materials and fertilisers",
    "Sonstige organische D\u00fcngemittel": "Additional farm fertiliser",
    "Sorte": "Variety",
    "Sorte in der Datenquelle (aus dem Import)": "Variety in the data source (from import)",
    "Sorte:": "Variety:",
    "Sorten": "Varieties",
    "Sorte w\u00e4hlen": "Select Variety",
    "Sortieren: A {arrowRight} Z": "Sort: A {arrowRight} Z",
    "Sortieren: Z {arrowRight} A": "Sort: Z {arrowRight} A",
    "Sortieren: absteigend": "Sort: descending",
    "Sortieren: aufsteigend": "Sort: ascending",
    "Spaltenreihenfolge \u00e4ndern": "Change column sequence",
    "Spalten\u00fcberschrift aus Datei": "Column header from file",
    "Speichern": "Save",
    "Spritzbrühe": "Spray mix",
    "Standard": "Default",
    "Standard - Shapefile": "Default - Shapefile",
    "Standardeinheit {amount} K\u00f6rner je Packung": "Standard unit {amount} grains per package",
    "Status": "Status",
    "Startdatum": "Start date",
    "Start": "Start",
    "Steig auf www.eama.at mit Betriebsnummer und Pincode (oder Handysignatur) ein.": "Login on www.eama.at with company number and pin code (or cell phone signature).",
    "Steige um auf FARMDOK PERFORMANCE und du erh\u00e4ltst Zugang zu FARMDOK Monitoring. Die Anwendung erm\u00f6glicht es Vegetations\u00adunterschiede teilfl\u00e4chenspezifisch in der Karte darzustellen und den zeitlichen Verlauf zu analysieren.": "Upgrade to FARMDOK PERFORMANCE and you will get access to FARMDOK crop monitoring. This module allows visualizing site-specific vegetation differences, analyzing time series of satellite images and creating application maps from the satellite data.",
    "Steige um auf FARMDOK PERFORMANCE und du erh\u00e4ltst Zugang zu Freigegebenen Feldern.": "Switch to FARMDOK PERFORMANCE and get access to Shared Fields.",
    "Stoffstrombilanz": "Substance flow",
    "Storniert": "Canceled",
    "Stra\u00dfe": "Street",
    "Stra\u00dfe, Hausnummer, T\u00fcr": "Street, number, doornumber",
    "St\u00e4rkegehalt (t)": "Starch contents (t)",
    "St\u00e4rkegehalt gesamt (t)": "Total starch amount (t)",
    "St\u00e4rkegehalt gesamt: {totalStarch} t": "Total starch amount: {totalStarch} t",
    "Unterebene": "Sublayer",
    "Unterebenen": "Sublayers",
    "Suchbegriff eingeben ...": "Enter search term ...",
    "Suche zur\u00fccksetzen": "Reset search",
    "Suchen": "Search",
    "Summe": "Sum",
    "Summe Gr\u00f6\u00dfe: {sizeTotalFormatted} ha": "Total size: {sizeTotalFormatted} ha",
    "Summe Hektar: {processedAreaTotalFormatted} ha": "Sum hectare: {processedAreaTotalFormatted} ha",
    "Superuser": "Super user",
    "Tabelle zur\u00fccksetzen": "Reset table",
    "Tage": "Days",
    "Telefonnummer": "Phone number",
    "Temperatur": "Temperature",
    "tierische Sch\u00e4den (Bsp.: Wild, V\u00f6gel, Insekten, …)": "animal damage (e.g. game, birds, insects, …)",
    "Tierkategorien und N\u00e4hrstoffanfall": "Animal categories and amount of nutrients",
    "Tippen um zu suchen": "Type to search",
    "Trennlinie fertigstellen": "Finish dividing line",
    "Trockengebiet": "Dry region",
    "T\u00e4tigkeit": "Activity type",
    "T\u00e4tigkeit w\u00e4hlen": "Choose activity type",
    "T\u00e4tigkeiten": "Activity types",
    "UBB": "UBB",
    "Um Polygone kombinieren zu können, müssen Sie mindestens 2 Polygone ausgewählt haben.": "To merge polygons, you need to have at least 2 polygons selected",
    "Umweltgerechte und biodiversit\u00e4tsf\u00f6rdernde Bewirtschaftung.": "Environmentally sound and biodiversity enhancing management.",
    "Unbekannter Fehler": "Unknown error",
    "Unbekannter Fehler. Bitte \u00fcberpr\u00fcfe deine Internetverbindung.": "Unknown error. Please check your internet connection.",
    "Unerwarteter Fehler.": "Unexpected error.",
    "Ung\u00fcltige Trennlinie": "Invalid separator line",
    "Ung\u00fcltiger Wert.": "Invalid value.",
    "Upload": "Upload",
    "Upload verlassen": "Cancel upload",
    "Upload fehlgeschlagen! Die Datei scheint defekt zu sein. Probiere den Export aus der Quellapplikation zu einem sp\u00e4teren Zeitpunkt nochmal. Sollte das Problem weiterhin auftreten, sende die Datei bitten an den Support.": "upload failed! The file seems to be corrupted. Try exporting it from the source application again at a later time. If the problem remains, please send the file to  support.",
    "Upload in Arbeit ... DIESES FENSTER NICHT SCHLIESSEN!": "Upload in progress ... DO NOT CLOSE THIS WINDOW!",
    "Users and Companies": "Users and companies",
    "Vegetation": "Vegetation",
    "Datum der Vegetationskarte": "Vegetation map date",
    "Datura-Kontrollzentrum": "Datura control center",
    "Drohnenassessments": "Drone assessments",
    "Drohnenbilder hochladen": "Upload drone images",
    "Vegetationsmonitoring": "Crop monitoring",
    "Verbindung trennen": "Disconnect from platform",
    "Verbindung trennen fehlgeschlagen.": "Disconnection failed.",
    "Verbindung zu CNH Industria": "Connect to CNH Industrial",
    "Verbindung zu MyJohnDeere trennen": "Disconnect from MyJohnDeere",
    "Vereinbarungen zur Fl\u00e4chenmeldung": "Terms and agreements for field transmission",
    "Verf\u00fcgbare Maschinen": "Available machines",
    "Verkleinern": "Reduce",
    "Verlustausgleich (%)": "Loss compensation (%)",
    "Verlauf": "Job history",
    "Job history öffnen": "Open job history",
    "verschiedene": "various",
    "Verstecken": "Hide",
    "Verteilung auf dem Feld": "Distribution on the field",
    "Vertrag": "Contract",
    "Vertrag w\u00e4hlen": "Select contract",
    "Vertragsnehmer": "Contractor",
    "Vertragsproduktion": "Contract farming",
    "Vervollst\u00e4ndige die Ernte-Ma\u00dfnahmen und klicke dann hier, um sie in FARMDOK zu buchen.": "Complete the harvesting activities and click here to save them in FARMDOK.",
    "Verwalte hier deine Fl\u00e4chen": "Manage your fields here",
    "Verwaltete Verträge": "Managed contracts",
    "Verwaltung": "Administration",
    "Verwendbar bis:": "Use until:",
    "Verwendungszweck": "Purpose",
    "Verwerfen": "Discard",
    "Verwirkliche deinen Erfolg am Feld!": "Realize your success on the field!",
    "Von der Aussaat bis zur Ernte, alles \u00fcber deine Felder an einem Ort.": "From sowing to harvesting, everything about your fields in one place.",
    "Von dort kannst du sie vom Terminal deines Traktors abrufen ohne einen USB-Stick.": "You can select them directly on your tractor's terminal without the need of a USB drive.",
    "Voraussetzung: Einverst\u00e4ndnis in eAMA abgeben!": "Prerequisite: Submit consent in eAMA!",
    "Vorfrucht": "Preceding crop",
    "Vorfrucht in der Datenquelle (aus dem Import)": "Previous crop in the data source (from import)",
    "Vorlage im Format .CSV {linkStart}herunterladen{linkEnd}": "{linkStart}Download template{linkEnd} in .CSV format",
    "Vorname": "First name",
    "Vorschau": "Preview",
    "Vordefinierte Notizen": "Pre-defined notes",
    "Warnhinweise gefunden: {count}": "Warnings found: {count}",
    "Wartezeit bis zur Ernte:": "Waiting period until harvest:",
    "Wartezeit zwischen Anwendungen:": "Waiting period between treatments:",
    "Wasserschutzgebiet": "Water protection zone",
    "Was ist dein Beruf?": "What is your profession?",
    "Was machst du?": "What are you doing?",
    "Was sind deine Ziele?": "What are your objectives?",
    "Wechsle zum Reiter Kundendaten, Men\u00fcpunkt Datenfreigabe > \u00dcbersicht.": "Switch to the Customer Data tab, menu item Data Sharing > Overview.",
    "Wegzeit": "Driving time",
    "Wein": "Wine",
    "Weiter": "Continue",
    "Weitere Dateien durch Ablegen hinzuf\u00fcgen": "Add more files by drop",
    "Welche Kulturen baust du an?": "What crops are you growing?",
    "Wert": "Value",
    "Wert muss zwischen {min} und {max} sein": "Value must be between {min} and {max}",
    "Weniger anzeigen": "Show less",
    "Wenn du den Lagerstand sehen m\u00f6chtest, f\u00fchre eine Lagerbewegung durch.": "Please enter a stock movement for this product, if you want to see the stock level here.",
    "Werte:": "Values:",
    "Wetter f\u00fcr:": "Weather for:",
    "Wetterdiagramm": "Weather diagram",
    "Wie bewirtschaftest du?": "How do you farm?",
    "Wie m\u00f6chtest du importieren?": "How do you want to import?",
    "Wie viele Hektar werden bewirtschaftet?": "How many hectares are farmed?",
    "Wie viele Kunden werden betreut?": "How many customers do you have?",
    "Wie viele Mitarbeiter gibt es?": "How many employees do you have?",
    "Wiederherstellen": "Restore",
    "Willst du Ernte-Ma\u00dfnahmen in FARMDOK erstellen? Aktiviere die Kontrollk\u00e4stchen in der ersten Spalte, dann klicke hier um zum n\u00e4chsten Schritt zu gehen.": "Do you want to create harvesting activities in FARMDOK? Activate the checkboxes and click here to proceed.",
    "Willst du ein neues Erntejahr {newYear} hinzuf\u00fcgen?": "Do you want to add a new crop year {newYear}?",
    "Willst du wirklich das gew\u00e4hlte Feldst\u00fcck l\u00f6schen?": "Do you really want to delete the selected field group?",
    "Willst du wirklich {count} Feldst\u00fccke l\u00f6schen?": "Do you really want to delete {count} field groups?",
    "Willst du wirklich {name} l\u00f6schen?": "Do you really want to delete {name}?",
    "Winterdienst": "Winter service",
    "Wintergetreide {n}. Gabe": "Winter grain {n}. application",
    "Wir f\u00fchlen uns dazu verpflichtet dir ma\u00dfgeschneiderte Unterst\u00fctzung zu bieten!": "We are committed to providing you with customized support!",
    "Wir haben dir eine E-Mail an {email} gesendet.": "We sent you an e-mail to {email}.",
    "Wir senden dir ein E-Mail zu, mit Hilfe dessen du ein neues Passwort setzen kannst.": "We will send you an e-mail with a link that allows you to set a new password.",
    "Wirtschaftsd\u00fcnger": "Farm fertiliser",
    "Wolken": "Clouds",
    "W\u00e4hle das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder kopiert werden sollen.": "Select the target crop year where the selected fields should be copied to.",
    "W\u00e4hle das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder verschoben werden sollen.": "Select the target crop year where the selected fields should be moved to.",
    "W\u00e4hle das Ziel-Erntejahr, wohin {field} kopiert werden soll.": "Select the target crop year where {field} should be copied to.",
    "W\u00e4hle das Ziel-Erntejahr, wohin {field} verschoben werden soll.": "Select the target crop year where {field} should be moved to.",
    "W\u00e4hle dein Land und melde dich an.": "Choose your country and sign up.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder kopiert werden sollen.": "Select the target farm and crop year where to copy the selected fields.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gew\u00e4hlten Felder verschoben werden sollen.": "Select the target farm and crop year where to move the selected fields.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin {field} kopiert werden soll.": "Select the target farm and crop year where {field} should be copied.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin {field} verschoben werden soll.": "Select the target farm and crop year where {field} should be moved to.",
    "W\u00e4hle den Ziel-Betrieb und das Ziel-Erntejahr, wohin die gew\u00e4hlten Maßnahmen verschoben werden sollen.": "Select the target farm and crop year where the selected activities should be moved to.",
    "W\u00e4hle die Felder in der Karte oder in der Liste.": "Select the fields in the map or the filter below to get started.",
    "W\u00e4hle die Felder, auf denen du Ernte-Ma\u00dfnahmen erstellen m\u00f6chtest, vervollst\u00e4ndige die Informationen und klicke dann hier, um zu speichern.": "Select the fields where you want to create harvesting activities, then complete the informations and click here to save.",
    "W\u00e4hle die Maschine an die du den Auftrag bzw. die Applikationskarte senden m\u00f6chtest. W\u00e4hle <b>MyJohnDeere<\/b> falls du die Daten in die MyJohnDeere Plattform senden m\u00f6chtest.": "Select the machine, you want to send the application map\u00a0to. Select <b>MyJohnDeere<\/b> if you want to send the data to the MyJohnDeere platform.",
    "W\u00e4hle ein Dateiformat abh\u00e4ngig vom Terminal, auf dem die Applikationskarte verwendet werden soll.": "Select a file format matching the terminal where you want to use the application map.",
    "W\u00e4hle ein Produkt": "Select a product",
    "W\u00e4hle eine Option um fortzufahren": "Choose an option to continue",
    "W\u00e4hle einen Betrieb um deine Berichte anzuzeigen.": "Select one farm to view your reports.",
    "W\u00e4hle mindesten ein Feld mit dem Kontrollk\u00e4stchen in der ersten Spalte. Alle ausgew\u00e4hlten Felder m\u00fcssen eine Kultur haben.": "Select at least one field via the checkbox. A crop is required for selected fields.",
    "W\u00e4hle zumindest 1": "Select at least 1",
    "W\u00e4hle zumindest 2": "Select at least 2",
    "W\u00e4hle \u201cStandard\u201d wenn dein Terminal-Hersteller nicht in der Liste angef\u00fchrt ist.": "Select \"Default\" if the manufacturer of your terminal is not listed.",
    "W\u00e4hle den ausf\u00fchrenden Betrieb": "Select the operating farm.",
    "W\u00e4hlen": "Select",
    "W\u00e4hlen ...": "Select ...",
    "W\u00e4hlen...": "Select...",
    "Wählen Sie Polygone in der Karte aus, um Felder zu erstellen. Sie können mehrere Polygone zusammenführen, wenn sie mehrere Polygone zu einem Feld zusammenführen möchten.": "Select polygons in the map to create fields. You can merge multiple polygones if you want to create one field.",
    "Wähle ein Feld in der Karte und gib den Prüfabstand ein. Die Feldkontur wird um den Prüfabstand nach außen verschoben und alle Felder gekennzeichnet, die vom Prüfabstand geschnitten werden.": "Select a field in the map and enter the distance to be validated. The field contour is shifted outwards by this distance and all fields that are intersected by the distance are highlighted.",
    "Wärmesumme": "Heat units",
    "Qualit\u00e4t": "Quality",
    "Qualit\u00e4tsd\u00fcngung (3. Gabe)": "Quality fertilization (3. application)",
    "Querformat": "Landscape",
    "XLS": "XLS",
    "ZIEHE DIE DATEI HIER HER": "DRAG AND DROP THE FILE HERE",
    "Zeichnen beenden": "Close drawing",
    "Zeichnen eines Polygons": "Draw a polygon",
    "Zeichnen einer Polyline": "Draw a polyline",
    "Zeichnen Sie Polygone oder Linien in die Karte, um Ihr GIS-Objekt darzustellen.": "Draw polygons or lines in the map to represent your GIS-Object.",
    "Zeitraum von {periodStart} bis {periodEnd}": "Contracting period {periodStart} to {periodEnd}",
    "Zeitraum:": "Time period:",
    "Zeitstempel": "Timestamps",
    "Ziehe die exportierte(n) Datei(en) mit der Maus in das wei\u00dfe Rechteck mit dem strichlierten Rahmen (Drag &amp; Drop).": "Drag and drop the exported file(s) into the white rectangle with the dashed frame.",
    "Ziel ausw\u00e4hlen": "Select target",
    "Ziel N-Rate (kg/ha)": "Target N-Rate (kg/ha)",
    "Ziel-Betrieb": "Target farm",
    "Ziel-Erntejahr": "Target crop year",
    "Zielbetrieb: In welchen Betrieb sollen die Daten importiert werden?": "Target farm: To which farm should the data be imported?",
    "Zielertrag (kg/ha)": "Target yield (kg/ha)",
    "Zone": "Zone",
    "Zonen": "Zones",
    "Zonen Ergebnis": "Zones result",
    "Zonen erstellen": "Create zones",
    "Zonen basiert auf {indexType}, Anzahl der Zonen: {quantisationCode}": "Zones based on {indexType}, Number of zones: {quantisationCode}",
    "Zonenkarte erstellen": "Create zone map",
    "Zonierung": "Zoning",
    "Zu den Berichten": "To reports",
    "Zu den offenen Vertr\u00e4gen": "View open calls",
    "Zu meinen Vertr\u00e4gen": "View my contracts",
    "Zuckergehalt (t)": "Sugar contents (t)",
    "Zugelassen bis:": "Approved until:",
    "Zugeordnet": "Assigned",
    "Zuletzt aktualisiert": "Last update",
    "Zuletzt verwendet": "Last used",
    "Zum Bericht": "View report",
    "Zum Vertrag": "View contract",
    "Zum Import in dein ISOBUS-Terminal": "To import into your ISOBUS terminal",
    "Zur Startseite": "Back to your home page",
    "Zur\u00fcck": "Back",
    "Zurück zu": "Back to",
    "Zur\u00fcck zum Login": "Back to login",
    "Zur\u00fcck zum Login.": "Back to login.",
    "Zur\u00fcck zur Dateien-\u00dcbersicht": "Back to the files page",
    "Zur\u00fcck zu Felder": "Back to the Fields",
    "Zur\u00fcck zur Feldst\u00fccke-Startseite": "Back to the field groups page",
    "Zur\u00fcck zu Ma\u00dfnahmen": "Back to the Activities",
    "Zur\u00fcck zur Ma\u00dfnahmen-Startseite": "Back to the activities page",
    "Zur\u00fcck zur Vertragsansicht": "Back to the contract page",
    "Zur\u00fcck zur Vertrags-\u00dcbersicht": "Back to the contracts overview",
    "Zur\u00fcck zur Verwaltungs-\u00dcbersicht": "Back to the administration overview",
    "Zur\u00fccksetzen": "Reset",
    "Zusammenarbeit verbessern": "Improve collaboration",
    "Zusammenfassung je Schlag": "Summary per field",
    "Zusammenführen von Polygonen": "Merge polygons",
    "Zwischenfrucht": "Catch crop",
    "aus Import": "From import",
    "bearbeitete Fl\u00e4che (ha)": "cultivated field (ha)",
    "eAMA Online Fl\u00e4chen Import": "eAMA Online field import",
    "eAMA PIN": "eAMA PIN",
    "eAMA \u00f6ffnen": "Open eAMA",
    "eAMA-Betriebsnummer": "eAMA company number",
    "empfohlen": "recommended",
    "f\u00fcr Minerald\u00fcnger": "for mineral fertilizer",
    "ha": "ha",
    "in FARMDOK": "in FARMDOK",
    "je mehr desto mehr": "The more, the more",
    "je weniger desto mehr": "The less, the more",
    "keine Angabe": "Not specified",
    "Keine gültigen Schadfaktoren für Mittel <strong>{pesticide}</strong> und <strong>{culture}</strong> gewählt": "Selected amount for agents {pesticide} and {culture} is not amount",
    "kg\/ha": "kg\/ha",
    "manuelle Dosierung": "Manual dosage",
    "oder klicke Datei auswählen oder Ordner auswählen, um eine Datei hinzuzufügen.": "or click {0}Select File{0} or {0}select directory{0} to add a file.",
    "pH": "pH",
    "t": "t",
    "und": "and",
    "{countSelected} von {countAvailable} Betrieben ausgew\u00e4hlt": "{countSelected} of {countAvailable} farms selected",
    "{count} Felder": "{count} fields",
    "{count} Felder ohne Erntema\u00dfnahme": "{count}\u00a0fields without harvest activity",
    "{count} von {total} Feldern mit Erntema\u00dfnahme": "{count} of {total} fields with harvest activity",
    "{field} ist ein Pflichtfeld.": "{field} is required.",
    "{field} muss mindestens {length} Zeichen lang sein.": "{field} needs to be minimum {length} characters long.",
    "{name}: {sizeFormatted} ha": "{name}: {sizeFormatted} ha",
    "{number} Fl\u00e4chen ({area} ha) an {partner} gesendet.": "{number} fields ({area} ha) transmitted to {partner}.",
    "{seedrateMin} bis {seedrateMax} K\u00f6rner pro Hektar": "{seedrateMin} to {seedrateMax} seeds per hectare",
    "{size} ha": "{size} ha",
    "{value} %": "{value} %",
    "\u00b0C W\u00e4rmesumme in {year}": "\u00b0C heat total in {year}",
    "\u00dcberspringen": "Skip",
    "\u201ehoch\u201c ergibt mehrere, kleine Teilfl\u00e4chen; \u201eniedrig\u201c weniger, gr\u00f6\u00dfere": "\"high\" results in more and smaller subareas; \"low\" results in less and larger subareas",
    "\u00dcbrig in": "Available on",
    "\u00dcbrig am Betrieb (kein spezifisches Lager)": "Available on the farm (no specific storage)",
    "UploadPending": "Pending",
    "UploadExpired": "Expired",
    "Calculating": "In progress",
    "Finished": "Completed",
    "Failed": "Failed",
  };
}
