import { Product } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';

import { findFamilyOfProduct } from '../store/compatibleProductsFilter';

export default class ProductService {
  private readonly mineralFertilizers: Data<Product>;

  private readonly companyFertilizers: Data<Product>;

  private readonly secondaryFertilizers: Data<Product>;

  private readonly herbizides: Data<Product>;

  private readonly crops: Data<Product>;

  private readonly miscellaneous: Data<Product>;

  private readonly harvests: Data<Product>;

  private readonly seeds: Data<Product>;

  private readonly otherProductsAndFertilizers: Data<Product>;

  private readonly waads: Data<Product>;

  constructor(
    mineralFertilizers: Data<Product>,
    companyFertilizers: Data<Product>,
    secondaryFertilizers: Data<Product>,
    herbizides: Data<Product>,
    crops: Data<Product>,
    miscellaneous: Data<Product>,
    harvests: Data<Product>,
    seeds: Data<Product>,
    otherProductsAndFertilizers: Data<Product>,
    waads: Data<Product>,
  ) {
    this.mineralFertilizers = mineralFertilizers;
    this.companyFertilizers = companyFertilizers;
    this.secondaryFertilizers = secondaryFertilizers;
    this.herbizides = herbizides;
    this.crops = crops;
    this.miscellaneous = miscellaneous;
    this.harvests = harvests;
    this.seeds = seeds;
    this.otherProductsAndFertilizers = otherProductsAndFertilizers;
    this.waads = waads;
  }

  public get allProducts(): Product[] {
    return [
      ...Object.values(this.mineralFertilizers),
      ...Object.values(this.companyFertilizers),
      ...Object.values(this.secondaryFertilizers),
      ...Object.values(this.herbizides),
      ...Object.values(this.crops),
      ...Object.values(this.miscellaneous),
      ...Object.values(this.harvests),
      ...Object.values(this.seeds),
      ...Object.values(this.otherProductsAndFertilizers),
      ...Object.values(this.waads),
    ];
  }

  public findProductById(id: string): Product | undefined {
    if (this.companyFertilizers[id]) return this.companyFertilizers[id];
    if (this.mineralFertilizers[id]) return this.mineralFertilizers[id];
    if (this.secondaryFertilizers[id]) return this.secondaryFertilizers[id];
    if (this.herbizides[id]) return this.herbizides[id];
    if (this.crops[id]) return this.crops[id];
    if (this.miscellaneous[id]) return this.miscellaneous[id];
    if (this.harvests[id]) return this.harvests[id];
    if (this.seeds[id]) return this.seeds[id];
    if (this.otherProductsAndFertilizers[id]) return this.otherProductsAndFertilizers[id];

    if (this.waads[id]) return this.waads[id];

    return undefined;
  }

  public getProductById(id: string): Product {
    const product = this.findProductById(id);
    if (!product) throw new Error(`Product with id ${id} not found`);
    return product;
  }

  public findProductOfCompany(id: string, companyId: string): Product | undefined {
    const product = this.findProductById(id);
    if (!product || !product.companyId) return undefined;

    if (product.companyId === companyId) return product;

    const family = findFamilyOfProduct(product, this.allProducts);
    return family.find((candidate) => candidate.companyId === companyId);
  }

  /**
   * @deprecated use product getter getProductOrSibling instead
   * @returns the product with the given id or the related product of the given company
   */
  public getProductOrSibling(id: string, companyId: string): Product {
    const product = this.getProductById(id);

    if (product.companyId == null) return product;

    const productOfCompany = this.findProductOfCompany(id, companyId);
    if (!productOfCompany) throw new Error(`Product of company with id ${id} not found`);
    return productOfCompany;
  }
}
