import { GetterTree } from 'vuex';

import ActivityEquipmentService from '@/activities/services/ActivityEquipmentService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import ActivityService from '@/activities/services/ActivityService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import CompanyService from '@/auth/services/CompanyService';
import EquipmentService from '@/equipments/services/EquipmentService';
import FieldService from '@/fields/services/FieldService';
import ProductService from '@/products/services/ProductService';
import { RootState } from '@/store/types';

import ActivityRuleViolationService from '../activityRuleViolations/services/ActivityRuleViolationService';
import ViolationService from '../services/ViolationService';
import { Violation, ViolationNotChecked } from '../types';
import { RuleCheckState } from './types';

export type Getters = {
  violations: (activityId?: string, includeDeletedActivities?: boolean) => Array<Violation | ViolationNotChecked>;
};

const moduleGetters: GetterTree<RuleCheckState, RootState> = {
  violations:
    (state, getters, rootState, rootGetters) =>
    (activityId?: string, includeDeletedActivities = false) => {
      const rules = state.rules.data;
      const activityRuleViolations = state.activityRuleViolations.data;
      const regionCodes: string[] = rootGetters['auth/currentCompaniesRegionCodes'];
      if (regionCodes.length > 1) throw new Error('Multiple region codes not supported');

      const fieldService = new FieldService(rootState.fields.data, rootGetters['products/findProductById']);
      const equipmentService = new EquipmentService(rootState.equipments.data);
      const activityEquipmentService = new ActivityEquipmentService(equipmentService);
      const companyService = new CompanyService(rootState.auth.companiesById);
      const productService = new ProductService(
        rootState.products.mineralFertilizers.data,
        rootState.products.companyFertilizers.data,
        rootState.products.secondaryFertilizers.data,
        rootState.products.herbizides.data,
        rootState.products.crops.data,
        rootState.products.miscellaneous.data,
        rootState.products.harvests.data,
        rootState.products.seeds.data,
        rootState.products.otherProductsAndFertilizers.data,
        rootState.products.waads.data,
      );
      const activityProductService = new ActivityProductService(
        rootState.units.data,
        productService,
        calculateAmountsAndUnits,
      );
      const activityService = new ActivityService(
        rootState.activities.data,
        fieldService,
        activityEquipmentService,
        activityProductService,
        companyService,
      );
      const activityRuleViolationService = new ActivityRuleViolationService(activityRuleViolations);
      const violations = new ViolationService(
        rules,
        activityService,
        activityRuleViolationService,
        regionCodes[0],
      ).getViolations(activityId, includeDeletedActivities);
      return violations ?? [];
    },
};

export default moduleGetters;
