
import { Activity } from 'farmdok-rest-api';
import Handsontable from 'handsontable';
import _isEqual from 'lodash.isequal';
import { PropType, defineComponent } from 'vue';

import ActivityProductService from '@/activities/services/ActivityProductService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import ProductService from '@/products/services/ProductService';
import { Field } from '@/shared/api/rest/models';
import { Data } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

import SecondStep from '../components/SecondStep.vue';
import FindService from '../services/FindService';
import { Getters as CreateEditActivityGetters } from '../store/getters';

type TableDataFieldSidebar = Field & { processedAreaOrFieldSize: number };

export default defineComponent({
  name: 'SecondStepContainer',
  components: {
    SecondStep,
  },
  props: {
    customColumnDefinition: {
      type: Object as PropType<{ international: Handsontable.ColumnSettings[] } | null>,
      default: null,
    },
  },
  computed: {
    selectedFields(): string[] | null {
      return this.$store.getters[
        `activities/createEditActivity/selectedFields`
      ] as CreateEditActivityGetters['selectedFields'];
    },
    sumProcessedArea(): number | null {
      return this.$store.getters[
        `activities/createEditActivity/sumProcessedArea`
      ] as CreateEditActivityGetters['sumProcessedArea'];
    },
    activities(): Activity[] {
      return (this.$store.state as RootState).activities.createEditActivity.activities;
    },
    customTableData(): Data<TableDataFieldSidebar> {
      const productService = new ProductService(
        this.$store.state.products.mineralFertilizers.data,
        this.$store.state.products.companyFertilizers.data,
        this.$store.state.products.secondaryFertilizers.data,
        this.$store.state.products.herbizides.data,
        this.$store.state.products.crops.data,
        this.$store.state.products.miscellaneous.data,
        this.$store.state.products.harvests.data,
        this.$store.state.products.seeds.data,
        this.$store.state.products.otherProductsAndFertilizers.data,
        this.$store.state.products.waads.data,
      );
      const activityProductService = new ActivityProductService(
        this.$store.state.units.data,
        productService,
        calculateAmountsAndUnits,
      );
      const findService = new FindService(this.activities, activityProductService);
      const fields = (this.$store.state as RootState).fields.data;

      const tableDataFieldSidebar: Data<TableDataFieldSidebar> = {};
      Object.entries(fields).forEach(([fieldId, field]) => {
        const activity = findService.findActivityByFieldId(field.id);
        tableDataFieldSidebar[fieldId] = {
          ...field,
          processedAreaOrFieldSize: activity?.processedArea ?? field.fieldSize,
        };
      });

      return tableDataFieldSidebar;
    },
  },
  methods: {
    updateSelectedFields(selected: string[]) {
      if (!_isEqual(selected, this.selectedFields)) {
        this.$store.dispatch(`activities/createEditActivity/updateActivitiesBasedOnSelectedFields`, selected);
      }
    },
    updateCell({ guid, columnKey, value }: { guid: string; columnKey: string; value: any }): void {
      if (columnKey === 'processedAreaOrFieldSize') {
        this.$store.dispatch(`activities/createEditActivity/updateActivitiesBasedOnProcessedArea`, {
          fieldId: guid,
          processedArea: value,
        });
      }
    },
  },
});
