export default class TableColumnsService {
    constructor(columns) {
        this.columns = columns;
    }
    getColumnsForHandsontable(userColumnSettings) {
        return this.columns
            .filter(TableColumnsService.filterUnavailableColumns)
            .map(TableColumnsService.addUserColumnSettings(userColumnSettings));
    }
    getAvailableColumns() {
        return this.columns.filter(TableColumnsService.filterUnavailableColumns);
    }
    static filterUnavailableColumns(column) {
        if (column.requiredFeatures) {
            return column.requiredFeatures();
        }
        return true;
    }
    static addUserColumnSettings(userColumnSettings) {
        return (column, columnIndex) => {
            var _a, _b, _c;
            if (typeof column.data !== 'string')
                throw new Error('column.data is not a string');
            const userSettings = (_a = userColumnSettings[column.data]) !== null && _a !== void 0 ? _a : {
                visualColumn: columnIndex,
                physicalColumn: columnIndex,
            };
            return Object.assign(Object.assign(Object.assign({}, column), userSettings), { hidden: (_c = (_b = userSettings.hidden) !== null && _b !== void 0 ? _b : column.hidden) !== null && _c !== void 0 ? _c : false });
        };
    }
}
