var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import { createMoveActivitiesForRequestParameters, createMoveActivityParametersWithResult, } from './actionUtils/actionUtils';
const actions = {
    toProcessOrder({ state, commit }, { activityIds }) {
        return __awaiter(this, void 0, void 0, function* () {
            const destinationProcessOrderId = state.selectedProcessOrderId;
            if (!destinationProcessOrderId) {
                throw new Error('DestinationProcessOrderId is not defined');
            }
            const moveActivities = createMoveActivitiesForRequestParameters(state.moveActivityParametersWithResult, activityIds);
            const requestParameters = {
                activityMoveToProcessOrderRequest: {
                    destinationProcessOrderId,
                    activities: moveActivities,
                },
            };
            const { activitiesApi } = Vue.prototype.$api;
            let apiResponse;
            try {
                const response = yield activitiesApi.activityMoveToProcessOrder(requestParameters);
                apiResponse = response.data;
                commit('setMoveActivityParametersWithResult', { moveActivityParametersWithResult: response.data.data });
            }
            catch (error) {
                if (error.response) {
                    apiResponse = error.response.data;
                    if ((apiResponse === null || apiResponse === void 0 ? void 0 : apiResponse.status) === 'conflict') {
                        commit('increaseTrials');
                    }
                }
            }
            finally {
                if (apiResponse === null || apiResponse === void 0 ? void 0 : apiResponse.data) {
                    const moveActivityParametersWithResult = createMoveActivityParametersWithResult(apiResponse.data);
                    commit('setMoveActivityParametersWithResult', moveActivityParametersWithResult);
                }
            }
        });
    },
};
export default actions;
