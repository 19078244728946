<template>
  <ModalWrapper
    ref="modal"
    class="modal-send-to-john-deere"
    size="xl"
    :visible="visible"
    :title="$t('Applikationskarte an John Deere senden')"
    @change="(value) => $emit('change', value)"
  >
    <div id="modal-send-to-john-deere" class="modal-send-to-john-deere__main mt-3">
      <HeaderDarkGray>
        {{ $t('Deine Organisationen in MyJohnDeere') }}
      </HeaderDarkGray>
      <Loading v-if="fetchingOrganizations" />
      <div v-else-if="organizations == null && urlGetCode != null" class="m-3">
        <p>
          <!-- eslint-disable max-len -->
          {{
            $t(
              'Mit FARMDOK kannst du Aufträge bzw. Applikationskarten direkt an deine John Deere Maschinen schicken. Dafür benötist du lediglich einen Account auf MyJohnDeere.com und musst deine Maschinen mit diesem Account verknüpfen. Du kannst die Applikationskarte auch ohne Account übertragen indem du sie herunterlädst und händisch mit einem USB-Stick überträgst.',
            )
          }}
          <!-- eslint-enable max-len -->
        </p>
        <BButton variant="primary" @click="openGetCodePage">
          {{ $t('Login mit MyJohnDeere') }}
        </BButton>
      </div>
      <div v-else-if="organizations != null">
        <div class="m-3">
          <!-- eslint-disable max-len -->
          <span
            v-html="
              $t(
                'Du bist auf MyJohnDeere mit <u>{userEmail}</u> eingeloggt. Falls du mehr als eine Organisation auf MyJohnDeere hast wähle bitte die passende aus der Liste.',
                { userEmail: userEmail },
              )
            "
          ></span>
          <!-- eslint-enable max-len -->
        </div>

        <BFormGroup :label="$t('Organisation')" label-for="name" label-cols="4" class="m-3">
          <Dropdown :items="selectedOrganizationDropdownItems" @select="(value) => setSelectOrganization(value)" />
        </BFormGroup>
        <div class="m-3">
          <BLink href="https://files.deere.com/" target="_blank">
            {{ $t('MyJohnDeere öffnen') }}
          </BLink>
        </div>
        <div class="m-3">
          <BLink @click="disconnect" id="modal-send-to-john-deere__disconnect">
            {{ $t('Verbindung zu MyJohnDeere trennen') }}
          </BLink>
          <BTooltip
            target="modal-send-to-john-deere__disconnect"
            container="modal-send-to-john-deere"
            placement="bottom"
            delay="500"
            trigger="hover"
          >
            <!-- eslint-disable max-len -->
            {{
              $t(
                'Falls du einen anderen MyJohnDeere Account verwenden möchtest logge dich bitte auf MyJohnDeere.com aus. Dann trenne die Verbindung in FARMDOK. Danach kannst du dich mit dem neuen Account verbinden.',
              )
            }}
            <!-- eslint-enable max-len -->
          </BTooltip>
        </div>
        <HeaderDarkGray>
          {{ $t('Deine Maschinen in MyJohnDeere.com') }}
        </HeaderDarkGray>
        <div class="m-3">
          <div class="mb-3">
            <!-- eslint-disable max-len -->
            <span
              v-html="
                $t(
                  'Wähle die Maschine an die du den Auftrag bzw. die Applikationskarte senden möchtest. Wähle <b>MyJohnDeere</b> falls du die Daten in die MyJohnDeere Plattform senden möchtest.',
                )
              "
            ></span>
            <!-- eslint-enable max-len -->
          </div>
          <BFormGroup :label="$t('Verfügbare Maschinen')" label-for="name" label-cols="4">
            <Dropdown :items="machinesDropdownItems" @select="(selected) => (selectedMachine = selected.id)" />
          </BFormGroup>
        </div>
        <HeaderDarkGray>
          {{ $t('Auftrag/Applikationskarte') }}
        </HeaderDarkGray>
        <div class="m-3">
          <BFormGroup :label="`${$t('Dateiname')} (.zip)`" label-for="name" label-cols="4">
            <BaseInput v-model="fileName" :placeholder="$t('Dateiname')" class="form-control font-weight-bold" />
          </BFormGroup>
          <BFormGroup :label="$t('Dateityp')" label-for="name" label-cols="4">
            <BaseInput v-model="fileType" disabled class="form-control font-weight-bold" />
          </BFormGroup>
          <BFormGroup :label="$t('Datum')" label-for="name" label-cols="4">
            <BaseInput v-model="fileDate" disabled class="form-control font-weight-bold" />
          </BFormGroup>
        </div>
      </div>
      <p v-if="errorUserMessage != null" class="text-danger">
        {{ errorUserMessage }}
      </p>
      <p v-if="selectedOrganization != null && selectedOrganization.length === 0" class="text-danger">
        {{ $t('Keine Organisation in deinem John Deere Account vorhanden.') }}
      </p>
      <p v-if="uploadSuccess" class="text-primary">
        {{ $t('Dateien erfolgreich an John Deere gesendet.') }}
      </p>
    </div>
    <template #map-container>
      <div class="modal-send-to-john-deere__image-container">
        <div>
          <BImg fluid :src="require('@/assets/images/JD_Logo_884x663.png')" />
        </div>
        <div style="max-width: 430px">
          <BImg fluid-grow :src="require('@/assets/images/JD_Tractor_Green.png')" />
        </div>
      </div>
    </template>
    <template #buttons>
      <BButton
        v-if="!uploadSuccess"
        variant="primary"
        class="mr-3"
        :disabled="uploadingFiles || selectedOrganization == null || selectedOrganization.length === 0"
        @click="sendToBackend"
      >
        <span>{{ $t('Senden') }}</span>
      </BButton>
      <BButton v-if="!uploadSuccess" variant="white" class="text-dark" @click="() => $refs.modal.hide()">
        <span>{{ $t('Abbrechen') }}</span>
      </BButton>
      <BButton v-if="uploadSuccess" variant="white" class="text-dark" @click="() => $refs.modal.hide()">
        <span>{{ $t('Schließen') }}</span>
      </BButton>
    </template>
  </ModalWrapper>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapState } from 'vuex';

import Dropdown from '@/shared/components/Dropdown.vue';
import HeaderDarkGray from '@/shared/components/HeaderDarkGray.vue';
import Loading from '@/shared/components/Loading.vue';
import ModalWrapper from '@/shared/components/ModalWrapper.vue';
import BaseInput from '@/shared/components/form/BaseInput.vue';

import applicationMapAsShape from '../mixins/applicationMapAsShape';
import getDatabaseId from './export/companyUtils';

const SEND_TO_TERMINAL = 'SEND_TO_TERMINAL';

export default {
  name: 'ModalOverlaySendToJohnDeere',
  components: {
    HeaderDarkGray,
    Loading,
    BaseInput,
    Dropdown,
    ModalWrapper,
  },
  mixins: [applicationMapAsShape],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    workflowKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      organizations: null,
      machines: null,
      machinesAxiosSource: null,
      fetchingOrganizations: false,
      selectedOrganization: null,
      selectedMachine: null,
      urlGetCode: null,
      userEmail: null,
      uploadingFiles: false,
      errorUserMessage: null,
      uploadSuccess: false,
      fileName: null,
      fileDate: moment().format('DD.MM.YYYY'),
      fileType: 'Prescription',
    };
  },
  computed: {
    ...mapState({
      currentCompanies(state) {
        return state.auth.currentCompanies;
      },
    }),
    selectedOrganizationDropdownItems() {
      const selected = {
        id: null,
        name: '',
      };
      if (this.selectedOrganization !== null) {
        selected.id = this.selectedOrganization;
        selected.name = this.selectedOrganizationById[this.selectedOrganization].name;
      }

      const data = [];
      let items = [];
      if (this.organizations != null) {
        items = this.organizations.map((organization) => ({ id: organization.id, name: organization.name }));
      }
      data.push({
        name: this.$t('Organisation'),
        id: 'organizations',
        items,
        sort: true,
      });

      return {
        data,
        loading: this.machinesAxiosSource != null,
        selected,
      };
    },
    machinesDropdownItems() {
      const selected = {
        id: null,
        name: '',
      };
      if (this.selectedMachine === SEND_TO_TERMINAL) {
        selected.id = SEND_TO_TERMINAL;
        selected.name = this.$t('MyJohnDeere');
      } else if (this.selectedMachine != null) {
        selected.id = this.selectedMachine;
        selected.name = this.machinesById[this.selectedMachine].name;
      }

      const data = [
        {
          name: this.$t('Plattform'),
          id: 'myjohndeere',
          items: [{ id: SEND_TO_TERMINAL, name: this.$t('MyJohnDeere') }],
          sort: false,
        },
      ];
      let items = [];
      if (this.machines != null) {
        items = this.machines.map((machine) => ({ id: machine.id, name: machine.name }));
      }
      data.push({
        name: this.$t('Maschinen'),
        id: 'machines',
        items,
        sort: true,
      });

      return {
        data,
        loading: this.machinesAxiosSource != null,
        selected,
      };
    },
    selectedOrganizationById() {
      if (this.organizations == null) {
        return null;
      }
      return this.organizations.reduce(
        (organizations, organization) => ({
          ...organizations,
          [organization.id]: organization,
        }),
        {},
      );
    },
    machinesById() {
      if (this.machines == null) {
        return null;
      }
      return this.machines.reduce(
        (machines, machine) => ({
          ...machines,
          [machine.id]: machine,
        }),
        {},
      );
    },
    companyDatabaseId() {
      return getDatabaseId(this.currentCompanies[0]);
    },
  },
  async created() {
    this.onFocus = () => this.updateOrganizations();
    window.addEventListener('focus', this.onFocus);
    this.fileName = this.getApplicationMapName();
    await this.updateOrganizations();
    await this.updateMachines();
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.onFocus);
  },
  methods: {
    setSelectOrganization(selected) {
      this.selectedOrganization = selected.id;
      this.updateMachines();
    },
    async updateOrganizations() {
      if (this.fetchingOrganizations) {
        return;
      }
      this.fetchingOrganizations = true;
      try {
        const { data } = await axios.get(`/admin/comp/${this.companyDatabaseId}/johnDeere/userData`);
        if (data != null && data.data != null && typeof data.data.url === 'string') {
          this.urlGetCode = data.data.url;
        } else if (data != null && data.data != null && Array.isArray(data.data.organizations)) {
          this.organizations = data.data.organizations;
          if (this.organizations.length > 0) {
            this.selectedOrganization = this.organizations[0].id;
            window.removeEventListener('focus', this.onFocus);
          }
          if (typeof data.data.user === 'string') {
            this.userEmail = data.data.user;
          }
        } else {
          this.organizations = null;
        }
        this.fetchingOrganizations = false;
      } catch (e) {
        console.error(e);
        this.organizations = null;
        this.fetchingOrganizations = false;
      }
    },
    async updateMachines() {
      if (this.machinesAxiosSource != null) {
        this.machinesAxiosSource.cancel();
      }
      this.errorUserMessage = null;
      this.machinesAxiosSource = axios.CancelToken.source();
      this.machines = [];
      this.selectedMachine = SEND_TO_TERMINAL;

      if (this.organizations == null) {
        this.machinesAxiosSource = null;
        return;
      }

      if (!this.selectedOrganization) {
        this.errorUserMessage = this.$t('Das Abrufen der Maschinenliste von John Deere fehlgeschlagen.');
        this.machinesAxiosSource = null;
        return;
      }

      try {
        const { data } = await axios.get(
          `/admin/comp/${this.companyDatabaseId}/johnDeere/getMachinesList?organizationId=${this.selectedOrganization}`,
          {
            cancelToken: this.machinesAxiosSource.token,
          },
        );
        if (data != null && data.data != null && Array.isArray(data.data.machines)) {
          this.machines = data.data.machines;
        }
        this.machinesAxiosSource = null;
      } catch (e) {
        if (!axios.isCancel(e)) {
          this.errorUserMessage = this.$t('Das Abrufen der Maschinenliste von John Deere fehlgeschlagen.');
        }
        this.machinesAxiosSource = null;
      }
    },
    openGetCodePage() {
      window.open(this.urlGetCode);
    },
    async sendToBackend() {
      if (this.uploadingFiles) {
        return;
      }
      this.errorUserMessage = null;

      const { file, filename } = await this.applicationMapAsShape('johndeere', this.fileName);
      const requestData = new FormData();
      requestData.append('file', file, filename);
      requestData.append('organizationId', this.selectedOrganization);

      try {
        this.uploadingFiles = true;
        // if there is a selected machine, transfer the file directly to the machine
        if (this.selectedMachine !== SEND_TO_TERMINAL) {
          requestData.append('machineId', this.selectedMachine);
          await axios.post(`/admin/comp/${this.companyDatabaseId}/johnDeere/fileTransfer`, requestData);
        } else {
          // otherwise, just upload the file to the JD File Manager
          await axios.post(`/admin/comp/${this.companyDatabaseId}/johnDeere/fileUpload`, requestData);
        }
        this.uploadSuccess = true;
        this.uploadingFiles = false;
      } catch (e) {
        if (
          e != null &&
          e.response != null &&
          e.response.data != null &&
          typeof e.response.data.errorUserMessage === 'string'
        ) {
          this.errorUserMessage = e.response.data.errorUserMessage;
        } else {
          this.errorUserMessage = this.$t('Fileupload zu John Deere fehlgeschlagen.');
        }
        this.uploadingFiles = false;
      }
    },
    async disconnect() {
      try {
        this.uploadingFiles = true;
        await axios.get(`/admin/comp/${this.companyDatabaseId}/johnDeere/disconnect`);
        this.$refs.modal.hide();
      } catch (e) {
        if (
          e != null &&
          e.response != null &&
          e.response.data != null &&
          typeof e.response.data.errorUserMessage === 'string'
        ) {
          this.errorUserMessage = e.response.data.errorUserMessage;
        } else {
          this.errorUserMessage = this.$t('Verbindung trennen fehlgeschlagen.');
        }
        this.uploadingFiles = false;
      }
    },
  },
};
</script>

<style scoped>
.modal-send-to-john-deere__main {
  min-height: 560px;
}

.modal-send-to-john-deere__image-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
