import axios, { CancelTokenSource } from 'axios';
import { type ActivityRuleViolation, type TrackLiveCheckRequestData, TracksApi } from 'farmdok-rest-api';
import debounce from 'lodash.debounce';
import moment from 'moment';
import Vue from 'vue';
import { type ActionContext, ActionTree } from 'vuex';

import { Api } from '@/plugins/farmdokRestApi';
import { RootState } from '@/store/types';

import baseWorkflowStore from '../../store/baseWorkflowStore';
import { POLYGON_STATES, ZONE_GENERATION_MODE_UPLOAD } from '../../store/baseWorkflowStore/common';
import { ApplicationMapsSprayingState, PlantProtection, SummaryData } from './types';

let multiPolySource: CancelTokenSource | null = null;

const actions: ActionTree<ApplicationMapsSprayingState, RootState> = {
  ...baseWorkflowStore.actions,
  // satellite images
  async setMultipolyTimestamp({ state, commit, getters }, polygonKey: string) {
    const polygon = state.polygons[polygonKey];
    const requestIdentifier = `${polygonKey}:${getters.coverageRatio}`;
    const multiPolyFromPolygon = state.multiPolyTimestamps.loaded[requestIdentifier];
    let multiPolyTimestamp = {};

    if (multiPolyFromPolygon) {
      multiPolyTimestamp = {
        key: requestIdentifier,
        data: multiPolyFromPolygon,
      };
    } else {
      const { coverageRatio } = getters;
      const timeStart = moment().startOf('day').subtract(1, 'year').unix();
      const timeEnd = moment().startOf('day').unix();
      const { data } = await axios.post('/admin/sen4/multiPolyTimestamps', {
        polygon: polygon.pathsForAxios,
        timeStart,
        timeEnd,
        coverageRatio,
      });
      multiPolyTimestamp = {
        key: requestIdentifier,
        data,
      };
      commit('addMultipolyTimestamp', multiPolyTimestamp);
    }

    if (
      Object.keys(state.multiPolyTimestamps.current)
        .map((key) => key.split(':')[0])
        .some((fieldId) => fieldId === polygonKey)
    ) {
      commit('unsetCurrentMultipolyTimestamp', `${polygonKey}:0`);
      commit('unsetCurrentMultipolyTimestamp', `${polygonKey}:2`);
    }

    commit('setCurrentMultipolyTimestamp', multiPolyTimestamp);

    const { timeArray } = getters.availableTimestamps;
    for (let i = 0; i < timeArray.length; i += 1) {
      if (state.selectedHeatmapTimestamp === timeArray[i]) {
        commit('setHeatmapTimestampSelectedIndex', i);
        break;
      }
    }
  },
  async loadHeatmaps(params) {
    await doLoadHeatmaps(params);
  },

  // fields
  async polygonSetState({ commit, dispatch }, { key, state: polygonState }) {
    commit('polygonSetState', { key, state: polygonState });
    if (polygonState === POLYGON_STATES.ACTIVE) {
      await dispatch('setMultipolyTimestamp', key);
    } else if (polygonState === POLYGON_STATES.INACTIVE) {
      commit('unsetCurrentMultipolyTimestamp', key);
    }
  },

  updateOverwrite({ commit }, { summaryData }: { summaryData: SummaryData }): void {
    commit('setOverwrite', summaryData);
  },

  resetOverwrite({ commit }): void {
    commit('setOverwrite', {
      reducedSprayMixPerZone: [],
      reducedVegetationPerZone: [],
    });
  },

  async liveCheck(
    context: ActionContext<ApplicationMapsSprayingState, RootState>,
    track: TrackLiveCheckRequestData['track'],
  ): Promise<ActivityRuleViolation[]> {
    const { tracksApi } = Vue.prototype.$api as Api;

    context.commit('setCheckingViolation', true);
    return doLiveCheck(context, tracksApi, track);
  },

  async setTask({ commit, rootState, dispatch, state }, task) {
    await Promise.all([
      dispatch('auth/subscribe', undefined, { root: true }),
      dispatch('fields/subscribe', undefined, { root: true }),
    ]);
    commit('setTask', task);

    // set zoneGenerationMode
    if (task?.applicationMap?.additionalData?.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD) {
      commit('setZoneGenerationMode', ZONE_GENERATION_MODE_UPLOAD);
    }

    // select fields
    const selectedFields: string[] = [];
    if (Array.isArray(task.applicationMap?.additionalData?.fields)) {
      // @ts-ignore // TODO fix this
      task.applicationMap.additionalData.fields.forEach((field) => {
        if (rootState.fields.data[field.id] != null) {
          selectedFields.push(field.id);
        }
      });
    } else if (Array.isArray(task.fields)) {
      // @ts-ignore // TODO fix this
      task.fields.forEach((taskField) => {
        const { guid } = taskField.field;
        if (rootState.fields.data[guid] != null) {
          selectedFields.push(guid);
        }
      });
    }
    commit('setSelectedFields', selectedFields);

    if (task.applicationMap?.additionalData?.selectedIndexType != null) {
      commit('setSelectedIndexType', task.applicationMap.additionalData.selectedIndexType);
    }
    if (task.applicationMap?.additionalData?.selectedHeatmapTimestamp != null) {
      commit('setHeatmapTimestamp', task.applicationMap.additionalData.selectedHeatmapTimestamp);
    }

    if (task.applicationMap?.additionalData?.selectedQuantisationCode != null) {
      commit('setSelectedQuantisationCode', task.applicationMap.additionalData.selectedQuantisationCode);
    }

    if (task.workingMeans != null) {
      commit('setWorkingMeans', task.workingMeans);
    }

    if (task.applicationMap?.geoJson != null) {
      if (task?.applicationMap?.additionalData?.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD) {
        commit('setUploadedZonesByFilename', task.applicationMap.geoJson);
      } else {
        commit('setHeatmaps', task.applicationMap.geoJson);
        const multiPolyRequestIdentifier = [
          ...state.selectedFields,
          state.selectedIndexType,
          state.selectedQuantisationCode,
        ].join('_');
        commit('setMultiPolyRequestIdentifier', multiPolyRequestIdentifier);
      }
    }

    commit('setPaginationStep', 2);

    if (task.applicationMap?.additionalData) {
      const { calculation } = task.applicationMap.additionalData;
      commit('setOverwrite', calculation.overwrite);
      commit('setSprayMix', calculation.sprayMix);
      commit('setReduction', calculation.reduction);
      commit('setPlantProtections', calculation.products);
    } else if (Array.isArray(task.workingMeans) && task.workingMeans.length > 0) {
      // @ts-ignore
      task.workingMeans.some((taskMaterial) => {
        if (taskMaterial.workingMean?.guid && taskMaterial.workingMean?.name) {
          commit('setPlantProtections', [
            {
              product: {
                id: taskMaterial.workingMean.guid,
                name: taskMaterial.workingMean.name,
              },
              pest: { id: '', name: '' },
              amount: taskMaterial.amount,
              unit: taskMaterial.unit.name,
            } as PlantProtection,
          ]);
          return true;
        }
        return false;
      });
    }
  },
};

/**
 * Debounced function to avoid subsequent requests, if loadHeatmaps is dispatched several times after one another
 */
const doLoadHeatmaps = debounce(
  async ({ state, commit, getters }: ActionContext<ApplicationMapsSprayingState, RootState>): Promise<void> => {
    if (multiPolySource != null) {
      multiPolySource.cancel();
    }

    const {
      selectedFields,
      selectedHeatmapTimestamp: satelliteTimestamp,
      polygons,
      selectedIndexType,
      selectedQuantisationCode,
      heatmaps,
      multiPolyTimestamps,
    } = state;

    const productIds: string[] = selectedFields
      .filter((guid: string) => !!polygons[guid])
      .filter((guid: string) => {
        const clientId = getters.toClientId(guid);
        return !heatmaps.current[clientId];
      });

    const satelliteMoment = moment.unix(satelliteTimestamp);
    const products = productIds.map((guid: string) => {
      const polygon = polygons[guid].pathsForAxios;
      const key = `${guid}:${getters.coverageRatio}`;
      const dbId =
        multiPolyTimestamps.loaded[key]?.availableData?.find((ts: { dbId: string; timestamp: number }) =>
          moment.unix(ts.timestamp).isSame(satelliteMoment, 'day'),
        )?.dbId ?? '';

      const clientId = getters.toClientId(guid);
      const fieldKey = guid;
      return selectedIndexType.includes('DNN_')
        ? {
            timestamp: satelliteTimestamp,
            polygon,
            clientId,
            fieldKey,
          }
        : {
            dbId,
            polygon,
            clientId,
            fieldKey,
          };
    });
    if (!products.length) {
      return;
    }

    multiPolySource = axios.CancelToken.source();
    let data: any = null;
    state.heatmaps.fetching = true;
    try {
      ({ data } = await axios.post(
        '/admin/sen4/multiPoly',
        {
          products,
          indexType: selectedIndexType,
          configSet: 'SET_A2',
          quantisationCode: selectedQuantisationCode,
        },
        { cancelToken: multiPolySource.token },
      ));
    } catch (e) {
      return;
    } finally {
      state.heatmaps.fetching = false;
    }
    multiPolySource = null;

    const newHeatmaps = {
      ...data.products,
    };

    commit('setHeatmaps', newHeatmaps);
  },
  100,
  { leading: false, trailing: true },
);

/**
 * Debounced function to avoid subsequent requests to execute live check on backend. Invokes the debounce call as soon as the function is called, debouncing subsequent calls.
 */
const doLiveCheck = debounce(
  async (
    { commit }: ActionContext<ApplicationMapsSprayingState, RootState>,
    tracksApi: TracksApi,
    track: TrackLiveCheckRequestData['track'],
  ) =>
    tracksApi
      .trackLiveCheck({
        trackLiveCheckRequest: {
          data: {
            track,
          },
        },
      })
      .then((r) => r.data.data ?? [])
      .then((violations) => {
        commit('setViolations', violations);
        return violations;
      })
      .finally(() => commit('setCheckingViolation', false)),
  5000,
  {
    leading: true,
    trailing: true,
    maxWait: 5000,
  },
);

export default actions;
