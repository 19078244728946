import PluginService from '../../../services/PluginService';
export default function mergeByColspanSettingFactory(hot) {
    function mergeByColspanSetting(changes, source) {
        if (!hot)
            return;
        if (source !== 'edit')
            return;
        changes === null || changes === void 0 ? void 0 : changes.forEach((change) => {
            if (!change)
                return;
            const [row, prop, oldValue, newValue] = change;
            if (prop === 'expand' && oldValue !== newValue) {
                mergeAllColumnsAfterExpandChange(row, oldValue, newValue, hot);
            }
            else {
                mergeColumn(row, prop, oldValue, newValue, hot);
            }
        });
    }
    return mergeByColspanSetting;
}
export function mergeAllColumnsAfterExpandChange(row, oldExpand, newExpand, hot) {
    const { columns } = hot.getSettings();
    if (!Array.isArray(columns))
        throw new Error('columns must be an array');
    columns.forEach((column, physicalColumn) => {
        if (typeof column.colspan === 'function') {
            const col = hot.toVisualColumn(physicalColumn);
            const columnValue = hot.getDataAtCell(row, col);
            const colspanOld = column.colspan(row, col, columnValue, oldExpand, hot);
            const colspanNew = column.colspan(row, col, columnValue, newExpand, hot);
            unmergeOldMergeNew(row, col, colspanOld, colspanNew, hot);
        }
    });
}
export function mergeColumn(row, prop, oldValue, newValue, hot) {
    const col = hot.propToCol(prop);
    const { columns } = hot.getSettings();
    if (!Array.isArray(columns))
        throw new Error('columns must be an array');
    const physicalCol = hot.toPhysicalColumn(col);
    const column = columns[physicalCol];
    if (!column || typeof column.colspan !== 'function')
        return;
    const expandValue = hot.getDataAtRowProp(row, 'expand');
    const colspanOld = column.colspan(row, col, oldValue, expandValue, hot);
    const colspanNew = column.colspan(row, col, newValue, expandValue, hot);
    unmergeOldMergeNew(row, col, colspanOld, colspanNew, hot);
}
export function unmergeOldMergeNew(row, col, colspanOld, colspanNew, hot) {
    const mergePlugin = new PluginService(hot).getMergePlugin();
    if (colspanOld === colspanNew)
        return; // do nothing if nothing changed
    if (colspanOld > 1) {
        // unmerge old colspan
        mergePlugin.unmerge(row, col, row, col + colspanOld - 1);
        hot.setCellMeta(row, col, 'colspan', 1); // manually reset colspan to 1 because unmerge does not do that
    }
    if (colspanNew > 1) {
        mergePlugin.merge(row, col, row, col + colspanNew - 1);
    }
}
