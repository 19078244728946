
import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck, faRulerHorizontal } from '@fortawesome/pro-light-svg-icons';
import { faCut, faDrawPolygon, faLayerGroup, faSlashForward } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faCircleNotch, faCrop, faObjectGroup, faPlus, faXmark } from '@fortawesome/pro-solid-svg-icons';
import area from '@turf/area';
import axios from 'axios';
import { BButton } from 'bootstrap-vue';
import {
  Activity,
  FieldUtilization,
  GeoObject,
  GeoObjectType,
  GeometryType,
  LandCadastre,
  LandCadastreSearchResult,
  MultiLineString,
  PlantVariety,
} from 'farmdok-rest-api';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import isEqual from 'lodash.isequal';
import reverse from 'lodash.reverse';
import numbro from 'numbro';
import { PropType, defineComponent } from 'vue';

import { TimestampsGps } from '@/activities/gpsLog/store/types';
import { ActivitiesGps } from '@/activities/gpsTrack/store/types';
import { Company } from '@/auth/store/types';
import GoogleMapsCircle from '@/fields/components/GoogleMapsCircle.vue';
import GoogleMapsLabel from '@/fields/components/GoogleMapsLabel.vue';
import GoogleMapsLine from '@/fields/components/GoogleMapsLine.vue';
import toGoogleMapsPath from '@/fields/utils/toGoogleMapsPath';
import toGoogleMapsPoint from '@/fields/utils/toGoogleMapsPoint';
import { HideableSubLayer } from '@/geoObjects/store/types';
import FieldNameLabel from '@/map/components/FieldNameLabel.vue';
import ReportLegend from '@/map/components/ReportLegend.vue';
import Orientation from '@/map/types';
import { Api } from '@/plugins/farmdokRestApi';
import { ActivityType, Field, Product } from '@/shared/api/rest/models';
import MapActions from '@/shared/components/MapActions.vue';
import SimpleTooltip from '@/shared/components/SimpleTooltip.vue';
import FormFieldInput from '@/shared/components/form/FormFieldInput.vue';
import MapDistanceLabel from '@/shared/components/map/MapDistanceLabel.vue';
import MapInfoBox from '@/shared/components/map/MapInfoBox.vue';
import SimpleMapLabel from '@/shared/components/map/SimpleMapLabel.vue';
import { DRAWING_PROPS, GOOGLE_MAPS_SETTINGS } from '@/shared/constants';
import { Data } from '@/shared/mixins/store/types';
import googleMapsApi from '@/shared/modules/googleMapsApi';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { checkLineIntersection } from '@/shared/modules/shapeHelpers';
import isUnique from '@/shared/modules/uniqueFilter';
import { TracksGps } from '@/tracks/store/types';

import { FieldColor, LayerItem, LayerType, SelectionSource } from '../types';
import calculateBoundsCenter from '../utils/calculateBoundsCenter';
import findLastActivityTypeOfField from '../utils/findLastActivityTypeOfField';
import isFieldInsideBounds from '../utils/isFieldInsideBounds';
import polygonToGeoJson from '../utils/polygonToGeoJson';
import isValidPolygon from '../utils/validatePolygon';
import HatchFieldOverlay from './HatchFieldOverlay.vue';
import MapFieldTooltip from './MapFieldTooltip.vue';
import MapMenu from './MapMenu.vue';
import MapOverlayButton from './MapOverlayButton.vue';
import MapUtilityButton from './MapUtilityButton.vue';
import ModalConfirm from './ModalConfirm.vue';
import ModalMapObjectsSubType from './ModalMapObjectsSubType.vue';

const selectedMarkerPath = require('../assets/map_marker_selected.png');
const newMarkerPath = require('../assets/map_marker_new.png');
const markerSearchResultPath = require('../assets/map_marker_search_result.png');

const A4_WIDTH = 210;
const A4_HEIGHT = 297;

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  strokeWeight: 3,
  scale: 5,
};

library.add(
  faCircleNotch,
  faCheck,
  faPlus,
  faCut,
  faDrawPolygon,
  faRulerHorizontal,
  faXmark,
  faObjectGroup,
  faCrop,
  faCircleCheck,
  faSlashForward,
  faLayerGroup,
);

const MIN_ZOOM_LOAD_CADASTRES = 16;
const MAX_ZOOM_LOAD_CADASTRES = 20;
const MIN_ZOOM_LOAD_CADASTRES_REPORT = 13;
const SYSTEM_ZOOM_CHANGE = 'systemZoomChange';

const styles: Record<string, google.maps.MapTypeStyle[]> = {
  default: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
  ],
  report: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#000000' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#FFFFFF' }],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#000000' }],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#FFFFFF' }],
    },
  ],
};

const COORDINATE_REGEX = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;

export default defineComponent({
  name: 'FieldsMap',
  components: {
    GoogleMapsLabel,
    GoogleMapsCircle,
    GoogleMapsLine,
    MapInfoBox,
    MapFieldTooltip,
    FormFieldInput,
    SimpleTooltip,
    MapActions,
    MapMenu,
    MapUtilityButton,
    MapOverlayButton,
    ModalConfirm,
    MapDistanceLabel,
    BButton,
    SimpleMapLabel,
    HatchFieldOverlay,
    ReportLegend,
    FieldNameLabel,
    ModalMapObjectsSubType,
  },
  props: {
    /**
     * A list of companies for which this feature is used.
     * If no fields are available, this will be used to center the map.
     */
    companies: {
      type: Array as PropType<Company[]>,
      default() {
        return [];
      },
    },
    /**
     * A list of customers by id (used to display in tooltip)
     */
    customers: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * A list of fields that will be displayed grayed out.
     */
    disabledFields: {
      type: Object as PropType<Data<Field>>,
      default() {
        return {};
      },
    },
    /**
     * A list of fields to display. This fields can be edited by the user and will be shown in primary color.
     * Also a marker is drawn and a click event is triggered for polygons + markers.
     */
    activeFields: {
      type: Object as PropType<Data<Field>>,
      default() {
        return {};
      },
    },
    /**
     * A list of fields to display.
     * They are displayed the same as activeFields.
     * Additionally, the tooltip will always be visible and the field name is rendered in an input field.
     * The polygon is set to editable and the marker to draggable.
     */
    editableFields: {
      type: Object as PropType<Data<Field>>,
      default() {
        return {};
      },
    },
    /**
     * A list of fields to display in default state
     */
    defaultFields: {
      type: Object as PropType<Data<Field>>,
      default() {
        return {};
      },
    },
    /**
     * Specify the bounds of the map after the initial rendering. Includes only the fields of the lists given here.
     */
    initialCenterIncludeDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Allow creating new fields (marker + polygon).
     */
    insertEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Show 'okay' button in as google map custom control.
     */
    buttonOk: {
      type: Boolean,
      default: false,
    },
    /**
     * Show 'add' button in as google map custom control.
     */
    buttonAdd: {
      type: Boolean,
      default: false,
    },
    /**
     * Crops by id.
     */
    crops: {
      type: Object,
      default: null,
    },
    /**
     * Varieties by id.
     */
    varieties: {
      type: Object as PropType<Data<PlantVariety>>,
      default: null,
    },
    /**
     * Activities by id.
     */
    activities: {
      type: Object as PropType<Data<Activity>>,
      default: null,
    },
    activityTypes: {
      type: Object as PropType<Data<ActivityType>>,
      default: null,
    },
    /**
     * Do not show any buttons beneath the marker for editable fields.
     */
    hideAllMapActions: {
      type: Boolean,
      default: false,
    },
    /**
     * Do not show discard button beneath the marker for editable fields.
     */
    hideMapActionsDiscard: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to true enabled drawing mode of a single line. Then tries to split the active field with this line.
     */
    splitFieldEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * This should be true when the current line can split the currently active field/polygon.
     * Get's updated by and update:splitFieldLineValid event.
     */
    splitFieldLineValid: {
      type: Boolean,
      default: undefined,
    },
    /**
     * Specify the available controls:
     *   - searchField: Input field to search for an address (placed on the top right)
     *   - mapTYpe: Switch between satellite and street map (placed bottom right)
     *   - zoom: + and - buttons to zoom in/out (placed bottom right)
     */
    controls: {
      type: Array as PropType<string[]>,
      default: () => ['searchField', 'mapType', 'zoom'],
    },
    /**
     * When set to true, when hovering over a polygon, the color changes to hovered state color
     */
    hoverEffectsEnabled: {
      type: Boolean,
      default: false,
    },
    /**
     * When set to true, a menu will be diplayed in the top left corner
     */
    showMenu: {
      type: Boolean,
      default: false,
    },
    featureFieldsNDVIMeanEnabled: {
      type: Boolean,
      default: false,
    },
    featureLandCadastresEnabled: {
      type: Boolean,
      default: false,
    },
    featureGISObjectsEnabled: {
      type: Boolean,
      default: false,
    },
    landCadastres: {
      type: Object as PropType<Data<LandCadastre>>,
      default: () => {},
    },
    fieldUtilization: {
      type: Object as PropType<Data<FieldUtilization>>,
      default: () => {},
    },
    newFieldsSize: {
      type: Number,
      default: 0,
    },
    newFields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    invalidNewFields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    invalidEditFields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    createFieldsActive: {
      type: Boolean,
      default: false,
    },
    editFieldsActive: {
      type: Boolean,
      default: false,
    },
    createMapObjectActive: {
      type: Boolean,
      default: false,
    },
    editMapObjectActive: {
      type: Boolean,
      default: false,
    },
    createReportActive: {
      type: Boolean,
      default: false,
    },
    geoObjects: {
      type: Object as PropType<Data<GeoObject>>,
      default: () => {},
    },
    currentMapObjectType: {
      type: String,
      default: GeoObjectType.IsolationZone,
    },
    /**
     * When set to true, default and selected fields will have a marker
     */
    showFieldMarkers: {
      type: Boolean,
      default: true,
    },
    captureRegionOrientation: {
      type: String as PropType<Orientation>,
      default: Orientation.LANDSCAPE,
    },
    selectionSource: {
      type: String as PropType<SelectionSource>,
      default: 'MAP',
    },
    bufferFields: {
      type: Array as PropType<google.maps.MVCArray<google.maps.LatLng>[]>,
      default: () => [],
    },
    checkDistanceActive: {
      type: Boolean,
      default: false,
    },
    fieldsToWarn: {
      type: Array as PropType<google.maps.MVCArray<google.maps.LatLng>[]>,
      default: () => [],
    },
  },
  data: (instance: any) => {
    const buttonOkElement = document.createElement('button');
    buttonOkElement.classList.add('map__control-button');
    const [check] = icon({ prefix: 'fas', iconName: 'check' }).node;
    buttonOkElement.append(check);
    buttonOkElement.addEventListener('click', () => {
      instance.$emit('ok');
    });

    const buttonAddElement = document.createElement('button');
    buttonAddElement.classList.add('map__control-button');
    const [svg] = icon({ prefix: 'fas', iconName: 'plus' }).node;
    buttonAddElement.append(svg);
    buttonAddElement.addEventListener('click', () => {
      instance.$emit('add');
    });

    return {
      GeoObjectType,
      FieldColor,
      GOOGLE_MAPS_SETTINGS,
      google: null as any,
      map: null as google.maps.Map | null,
      overlay: null as google.maps.OverlayView | null,
      mapAddressSearchTerm: '',
      buttonOkElement,
      buttonAddElement,

      polygonsByFieldId: {} as Record<string, google.maps.Polygon>,
      markersByFieldId: {} as Record<string, google.maps.Marker>,
      hoveredField: null as Field | null,
      tooltipField: null as Field | null,
      isHoveringTooltip: false,

      // New fields
      hideCreateTooltip: false,
      createTooltipX: 0,
      createTooltipY: 0,
      fetchingNewFieldContour: false,
      mapLoaderPosition: null as google.maps.Point | null,

      // Split field
      splitFieldDrawingManager: null as google.maps.drawing.DrawingManager | null,
      splitFieldMousedownLocation: null as google.maps.LatLng | null,
      splitFieldDrawingModeClickCount: 0,
      splitFieldDrawingModeMapActionsPosition: null as google.maps.Point | null,
      splitFieldPolyline: null as google.maps.Polyline | null,
      splitFieldNewFieldA: null as google.maps.Polygon | null,
      splitFieldNewFieldB: null as google.maps.Polygon | null,
      splitPolygonActive: false,

      // Draw polygon
      polygonDrawingManager: null as google.maps.drawing.DrawingManager | null,
      drawPolygonActive: false,

      drawing: DRAWING_PROPS,
      // Merge polygons
      mergePolygonsModalVisible: false,
      mergePolygonsActive: false,

      // Map Objects Sub Type
      mapObjectsSubTypeModalVisible: false,
      showSubLayerActive: false,

      // Measure distances
      polylineMeasureDistanceDrawingManager: null as google.maps.drawing.DrawingManager | null,
      measureDistancesActive: false,
      currentMeasurePolyline: null as google.maps.Polyline | null,
      distancePolylines: [] as google.maps.Polyline[],

      fieldColor: instance.showMenu ? FieldColor.CROP : FieldColor.DEFAULT,
      alwaysShowFieldNameTooltip: false,
      layers: [] as LayerItem[],
      cadastreDataLayer: null as google.maps.Data | null,
      fieldUtilizationDataLayer: null as google.maps.Data | null,

      cadastrePolygonFeatures: [] as google.maps.Data.Feature[],
      cadastreLabels: [] as HTMLDivElement[],

      // search
      searchByParcelNumberActive: false,
      parcelSearchPoints: [] as LandCadastreSearchResult[],
      searchResultMarkersById: {} as Record<string, google.maps.Marker>,
      searchResultLayer: null as google.maps.Data | null,
      parcelSearchEmpyResult: false,

      // geo objects
      drawMapObjectsActive: false,
      drawPolylineMapObjectsActive: false,
      mapObjectsDrawingManager: null as google.maps.drawing.DrawingManager | null,
      mapObjectPolygonsById: {} as Record<string, google.maps.Polygon | google.maps.Polyline>,
      currentMapObjectPolygon: null as google.maps.Polyline | null,
      showIsolationZones: false,
      showMultiplierGroups: false,

      // report
      aspectRatio: {
        portrait: { width: A4_WIDTH, height: A4_HEIGHT },
        landscape: { width: A4_HEIGHT, height: A4_WIDTH },
      },
      captureRegion: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
      showCaptureRegion: false,
      showTimestamps: false,
      pointsInFocus: [] as google.maps.LatLng[],
      fieldsLoaded: false,
      minZoomLoadCadastres: MIN_ZOOM_LOAD_CADASTRES,
      maxZoomLoadCadastres: MAX_ZOOM_LOAD_CADASTRES,
    };
  },
  computed: {
    mapObjectsSubTypes(): HideableSubLayer[] {
      return this.$store.getters['geoObjects/getIsolationZoneSubTypes'];
    },
    fieldTooltipField(): Field | null {
      return this.hoveredField ?? this.tooltipField;
    },
    showFieldTooltip(): boolean {
      return (
        (this.tooltipField != null && this.editableFields[this.tooltipField.id] == null) ||
        (this.hoveredField != null && this.editableFields[this.hoveredField.id] == null) ||
        this.isHoveringTooltip
      );
    },
    getLayerStrokeColor(): string {
      if (this.createReportActive) return 'black';
      if (this.parcelSearchPoints.length > 0) return 'grey';
      return 'white';
    },
    usedVarieties(): PlantVariety[] {
      const captureAreaBounds = this.getCaptureAreaBounds();

      const fieldsWithVarieties = Object.values(this.allFields).filter(
        (field) => isFieldInsideBounds(field, captureAreaBounds) && field.varietyId != null,
      );
      const varietyIds = new Set(fieldsWithVarieties.map((field) => field.varietyId));
      return Array.from(varietyIds, (varietyId) => this.varieties[varietyId]);
    },
    usedCrops(): Product[] {
      const captureAreaBounds = this.getCaptureAreaBounds();

      const fieldsWithCrops = Object.values(this.allFields).filter(
        (field) => isFieldInsideBounds(field, captureAreaBounds) && field.cropId != null,
      );
      const cropIds = new Set(fieldsWithCrops.map((field) => field.cropId));
      return Array.from(cropIds, (cropId) => this.crops[cropId!]);
    },
    allFields(): Data<Field> {
      return {
        ...this.disabledFields,
        ...this.defaultFields,
        ...this.editableFields,
        ...this.activeFields,
      };
    },
    isParcelSearchActive(): boolean {
      return this.parcelSearchPoints.length > 0;
    },
    isCadastreLayerActive(): boolean {
      return this.isLayerActive(this.layers.find((l) => l.type === LayerType.LAND_CADASTRES));
    },
    isFieldUtilizationLayerActive(): boolean {
      return this.isLayerActive(this.layers.find((l) => l.type === LayerType.FIELD_UTILIZATION));
    },
    cadastreLayerFeatures(): google.maps.Data.Feature[] {
      const features: google.maps.Data.Feature[] = [];
      this.cadastreDataLayer?.forEach((feature) => {
        features.push(feature);
      });
      return features;
    },
    visibleCadastreLayerFeatures(): google.maps.Data.Feature[] {
      const features: google.maps.Data.Feature[] = [];
      this.cadastreDataLayer?.forEach((feature) => {
        if (this.map?.getBounds()?.contains(calculateBoundsCenter(feature))) {
          features.push(feature);
        }
      });
      return features;
    },
    showSaveAndCancelOverlayButtons(): Boolean {
      return (
        (this.createFieldsActive || this.editFieldsActive || this.createMapObjectActive || this.editMapObjectActive) &&
        !this.drawPolygonActive &&
        !this.splitFieldEnabled &&
        !this.measureDistancesActive
      );
    },
    createFieldsSplitEnabled(): Boolean {
      return (
        Object.values(this.activeFields).length === 1 &&
        this.newFields.length === 1 &&
        Object.values(this.editableFields).length === 0
      );
    },
    /**
     * Calculates the size of the currently active field and the new fields.
     *
     * Constraint: only works if there is a single entry in this.activeFields.
     */
    splitFieldSizes(): Record<string, number | string> {
      const splitFieldSizes: Record<string, number | string> = {
        original: 0,
        fieldA: 0,
        fieldB: 0,
      };

      if (
        Object.values(this.activeFields).length === 1 &&
        this.polygonsByFieldId[Object.values(this.activeFields)[0].id] != null
      ) {
        const polygon = this.polygonsByFieldId[Object.values(this.activeFields)[0].id];
        splitFieldSizes.original = Math.floor(area(polygonToGeoJson(polygon))) / 10000;
      }

      if (this.splitFieldNewFieldA == null) {
        splitFieldSizes.fieldA = this.$t('Ungültige Trennlinie') ?? 'Ungültige Trennlinie';
      } else {
        splitFieldSizes.fieldA = Math.floor(area(polygonToGeoJson(this.splitFieldNewFieldA))) / 10000;
      }

      if (this.splitFieldNewFieldB == null) {
        splitFieldSizes.fieldB = this.$t('Ungültige Trennlinie') ?? 'Ungültige Trennlinie';
      } else {
        splitFieldSizes.fieldB = Math.floor(area(polygonToGeoJson(this.splitFieldNewFieldB))) / 10000;
      }

      Object.keys(splitFieldSizes).forEach((key) => {
        if (typeof splitFieldSizes[key] !== 'number' || splitFieldSizes[key] === 0) {
          return;
        }
        splitFieldSizes[key] = `${this.$t('{size} ha', {
          size: numbro(splitFieldSizes[key]).format({ mantissa: 4 }),
        })}`;
      });

      return splitFieldSizes;
    },
    visibleActivities(): ActivitiesGps[] {
      return this.$store.getters['activities/selectedActivityIds']
        .map((activityId: string) => this.$store.getters['activities/gpsTrack/findById'](activityId))
        .filter(notNullOrUndefined)
        .filter(this.hasCoordinates);
    },
    visibleTracks(): TracksGps[] {
      return this.$store.getters['activities/selectedActivities']
        .map((activity: Activity) => activity.trackId)
        .filter(notNullOrUndefined)
        .filter(isUnique)
        .map((trackId: string) => this.$store.getters['tracks/findById'](trackId))
        .filter(notNullOrUndefined)
        .filter(this.hasCoordinates);
    },
    visibleTimestamps(): TimestampsGps[] {
      return this.$store.getters['activities/selectedActivityIds']
        .map((activityId: string) => this.$store.getters['activities/gpsLog/findById'](activityId))
        .filter(notNullOrUndefined);
    },
  },
  async mounted() {
    const google = await googleMapsApi();
    this.google = google;
    const options = {
      tilt: 0,
      mapTypeId: google.maps.MapTypeId.HYBRID,
      disableDefaultUI: true,
      mapTypeControl: this.controls.includes('mapType'),
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: google.maps.ControlPosition.TOP_RIGHT,
        mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
      },
      zoomControl: this.controls.includes('zoom'),
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      fullscreenControl: false,
      styles: this.createReportActive ? styles.report : styles.default,
      isFractionalZoomEnabled: true,
    };
    if (this.$refs['map-container']) {
      this.map = new google.maps.Map(this.$refs['map-container'], options);
    } else {
      return;
    }
    this.map?.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.buttonOkElement);
    this.map?.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.buttonAddElement);

    this.overlay = new google.maps.OverlayView();

    if (this.overlay !== null) {
      this.overlay.draw = () => {};
    }

    this.overlay?.setMap(this.map);
    await this.initialize();
  },
  watch: {
    captureRegionOrientation(val) {
      this.displayCaptureRegion(true, val);
    },
    createReportActive(active: boolean) {
      this.showOrHideMarkers();
      this.loadCadastres();
      this.loadFieldUtilization();
      this.drawLayer(this.cadastreDataLayer!, this.landCadastres);
      this.drawLayer(this.fieldUtilizationDataLayer!, this.fieldUtilization);
      this.drawCadastreLabels();

      if (active) {
        this.drawLayer(this.cadastreDataLayer!, this.landCadastres);
        this.displayCaptureRegion(true, this.captureRegionOrientation);
        this.removeLabels();
        this.map?.setMapTypeId(google.maps.MapTypeId.ROADMAP);

        // add resize listener to change captureRegion on window resize
        window.addEventListener('resize', this.handleWindowResize, true);
        this.map?.setOptions({ styles: styles.report });
      } else {
        this.drawLayer(this.cadastreDataLayer!, this.landCadastres);
        this.drawCadastreLabels();
        this.showCaptureRegion = false;
        this.map?.setMapTypeId(google.maps.MapTypeId.SATELLITE);

        // remove resize listener
        window.removeEventListener('resize', this.handleWindowResize, true);
        this.map?.setOptions({ styles: styles.default });
      }
      this.setDrawings();
    },
    editFieldsActive() {
      this.setDrawings(false, true);
    },
    invalidEditFields() {
      this.setDrawings();
    },
    showIsolationZones() {
      this.drawMapObjects();
    },
    showMultiplierGroups() {
      this.drawMapObjects();
    },
    createMapObjectActive(val) {
      if (!val) {
        this.currentMapObjectPolygon?.setMap(null);
        this.currentMapObjectPolygon?.setOptions({ editable: false });
        this.currentMapObjectPolygon = null;
        this.drawMapObjectsActive = false;
        this.drawPolylineMapObjectsActive = false;
      } else {
        this.drawMapObjectsActive = true;
      }
    },
    editMapObjectActive(val) {
      if (!val) {
        this.currentMapObjectPolygon?.setMap(null);
        this.currentMapObjectPolygon?.setOptions({ editable: false });
        this.currentMapObjectPolygon = null;
        this.drawMapObjectsActive = false;
        this.drawPolylineMapObjectsActive = false;
      }
    },
    geoObjects() {
      this.drawMapObjects();
    },
    mapAddressSearchTerm(val) {
      this.parcelSearchEmpyResult = false;
      if (val === '') {
        this.resetParcelSearchResult();
      }
    },
    fieldUtilization() {
      this.drawLayer(this.fieldUtilizationDataLayer!, this.fieldUtilization);
    },
    landCadastres() {
      this.drawLayer(this.cadastreDataLayer!, this.landCadastres);
      this.drawCadastreLabels();
    },
    buttonOk() {
      this.updateButtons();
    },
    buttonAdd() {
      this.updateButtons();
    },
    insertEnabled(enabled) {
      if (enabled) {
        this.map?.setOptions({ draggableCursor: 'crosshair' });
      } else {
        this.map?.setOptions({ draggableCursor: 'default' });
      }
    },
    createFieldsActive(enabled) {
      if (enabled) this.onActiveLayersChanged(this.layers);
    },
    splitFieldEnabled(val, oldVal) {
      this.updateSplitMode(val !== oldVal);
    },
    drawPolygonActive(val, oldVal) {
      this.updateDrawingMode(val !== oldVal);
    },
    drawMapObjectsActive(val) {
      this.updateMapObjectsDrawingMode(!val, true);
    },
    drawPolylineMapObjectsActive(val) {
      if (this.drawMapObjectsActive) return;
      this.updateMapObjectsDrawingMode(!val, false);
    },
    measureDistancesActive(val, oldVal) {
      this.updateMeasureDistancesMode(val !== oldVal);
    },
    activeFields(currentValue: Data<Field>, previousValue: Data<Field>): void {
      this.setDrawings(false, true);
      const currentlySelected = Object.values(currentValue);
      const previouslySelected = Object.values(previousValue);
      const defaultFields = Object.values(this.defaultFields);
      const allFields = Object.values(this.allFields);

      if (this.createFieldsActive) {
        return;
      }

      const initialCentering = !this.fieldsLoaded && defaultFields.length;
      if (initialCentering) {
        this.fieldsLoaded = true;
        this.onActiveOrDefaultFieldsUpdate(this.defaultFields);
      }

      const fromAllToSingleSelection =
        this.selectionSource === 'TABLE' &&
        previouslySelected.length === allFields.length &&
        currentlySelected.length === 1;
      const sidebarIncrementalSelection =
        currentlySelected.length > previouslySelected.length && this.selectionSource === 'TABLE';
      if (fromAllToSingleSelection || sidebarIncrementalSelection) {
        this.pointsInFocus = [];
        this.onActiveOrDefaultFieldsUpdate(currentValue);
      }
    },
    defaultFields() {
      this.setDrawings();
    },
    disabledFields(val: Data<Field>, old: Data<Field>) {
      this.setDrawings();
      if (this.editableFields) {
        return;
      }

      if (Object.values(val).length > 0) {
        this.centerOnFields(Object.values(val));
      } else if (!isEqual(Object.keys(val).sort(), Object.keys(old).sort()) && !this.createFieldsActive) {
        this.centerOnFields(Object.values(val));
      }
    },
    editableFields() {
      this.setDrawings();
      setTimeout(this.setFocusOnFieldName, 300);
    },
    hoveredField(val: Field, oldVal: Field) {
      if (!this.hoverEffectsEnabled) {
        return;
      }

      if (oldVal) {
        this.setDrawings();
      }

      if (val) {
        const polygon = this.polygonsByFieldId[val.id];
        if (new Set(Object.keys(this.activeFields)).has(val.id)) {
          polygon.setOptions({
            fillColor: this.getColorForPolygon(val, GOOGLE_MAPS_SETTINGS.POLYGON_ACTIVE_HOVER_COLOR),
            strokeColor: this.getColorForPolygon(val, GOOGLE_MAPS_SETTINGS.POLYGON_ACTIVE_HOVER_COLOR),
            fillOpacity: 0.6,
            strokeOpacity: 1,
            strokeWeight: this.fieldColor === FieldColor.DEFAULT ? 4 : 0,
          });
        }

        if (new Set(Object.keys(this.defaultFields)).has(val.id)) {
          polygon.setOptions({
            fillOpacity: 0.4,
            strokeWeight: 5,
            strokeOpacity: this.fieldColor === FieldColor.DEFAULT ? 0.8 : 1,
          });
        }
      }
    },
    activities() {
      if (this.fieldColor === FieldColor.ACTIVITY) {
        this.setDrawings();
      }
    },
    activityTypes() {
      if (this.fieldColor === FieldColor.ACTIVITY) {
        this.setDrawings();
      }
    },
    visibleActivities(): void {
      this.recenterOnActivitySelection();
    },
    // eslint-disable-next-line
    visibleTracks(): void {
      this.recenterOnActivitySelection();
    },
    pointsInFocus(points: google.maps.LatLng[]): void {
      // skip centering when no points are provided
      if (points.length) {
        const bounds = new google.maps.LatLngBounds();
        points.forEach((point) => bounds.extend(point));
        this.map?.fitBounds(bounds, 60);
      }
    },
    checkDistanceActive(value: boolean) {
      const fieldUtilLayer = this.layers.find((layer: LayerItem) => layer.type === LayerType.FIELD_UTILIZATION);
      if (fieldUtilLayer) {
        fieldUtilLayer.selected = value;
      } else {
        this.layers.push({
          title: this.$t('Feldnutzung') ?? '',
          selected: value,
          type: LayerType.FIELD_UTILIZATION,
        });
      }
      this.minZoomLoadCadastres = value ? 14 : MIN_ZOOM_LOAD_CADASTRES;
      this.onActiveLayersChanged(this.layers);
    },
  },
  methods: {
    onMapObjectSubTypesVisibilityChanged(subLayers: HideableSubLayer[]) {
      this.$store.dispatch('geoObjects/setHiddenSubLayers', subLayers);
      this.showSubLayerActive = subLayers.some((subLayer) => subLayer.hidden);
    },
    toGoogleMapsPath,
    toGoogleMapsPoint,
    resetHoverTooltip() {
      // this is a small workaround to prevent the tooltip from instanly dissapearing when exiting the polygon
      setTimeout(() => {
        if (!this.isHoveringTooltip) {
          this.tooltipField = null;
        }
      }, 100);
    },
    onTooltipMouseover() {
      this.isHoveringTooltip = true;
    },
    onTooltipMouseout() {
      this.isHoveringTooltip = false;
      this.tooltipField = null;
    },
    canDrawLayer() {
      const zoom = this.map?.getZoom() ?? -1;
      return (
        (zoom >= this.minZoomLoadCadastres && zoom <= this.maxZoomLoadCadastres) ||
        (this.createReportActive && zoom >= MIN_ZOOM_LOAD_CADASTRES_REPORT && zoom <= this.maxZoomLoadCadastres)
      );
    },
    showOrHideMarkers(): void {
      const currentlyEditing = this.createFieldsActive || this.editFieldsActive;
      if (this.createReportActive || (this.alwaysShowFieldNameTooltip && !currentlyEditing)) {
        Object.values(this.markersByFieldId).forEach((marker: google.maps.Marker) => marker.setMap(null));
      } else {
        Object.values(this.markersByFieldId).forEach((marker: google.maps.Marker) => marker.setMap(this.map));
      }
    },
    handleWindowResize() {
      this.displayCaptureRegion(true, this.captureRegionOrientation);
    },
    displayCaptureRegion(show: boolean, orientation?: Orientation) {
      if (show && orientation) {
        this.showCaptureRegion = true;
        const { width, height } =
          orientation === Orientation.LANDSCAPE ? this.aspectRatio.landscape : this.aspectRatio.portrait;

        const widthMargin = 100;
        const heightMargin = 100;
        const containerWidth = (this.$refs['map-container'] as any).clientWidth - widthMargin;
        const containerHeight = (this.$refs['map-container'] as any).clientHeight - heightMargin;
        const containerAspectRatio = containerWidth / containerHeight;

        let captureWidth;
        let captureHeight;

        // Adjust the capture region based on the aspect ratio of the A4 paper
        if (containerAspectRatio > width / height) {
          captureWidth = containerHeight * (width / height);
          captureHeight = containerHeight;
        } else {
          captureWidth = containerWidth;
          captureHeight = containerWidth * (height / width);
        }

        // Calculate the position of the capture region
        const captureTop = (containerHeight - captureHeight) / 2 + heightMargin / 2;
        const captureLeft = (containerWidth - captureWidth) / 2 + widthMargin / 2;

        this.captureRegion = {
          top: captureTop,
          left: captureLeft,
          width: captureWidth,
          height: captureHeight,
        };
      } else {
        this.showCaptureRegion = false;
      }
    },
    async printMap(options: { orientation: Orientation; legendTitle: string }) {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 10));

      // eslint-disable-next-line prefer-destructuring
      const mapContainer: any = this.$refs['map-container-container'];
      const deviceRatio = window.devicePixelRatio < 2 ? window.devicePixelRatio * 2 : window.devicePixelRatio || 1;

      try {
        const canvas = await html2canvas(mapContainer, {
          useCORS: true,
          scale: deviceRatio,
        });

        const captureRegion: any = this.$refs['capture-region'];
        const captureRegionWidth = captureRegion.clientWidth;
        const captureRegionHeight = captureRegion.clientHeight;

        const imgData = await canvas.toDataURL('image/png', 1);

        const cropLeft = (mapContainer.clientWidth - captureRegionWidth) / 2;
        const cropTop = (mapContainer.clientHeight - captureRegionHeight) / 2;

        const croppedImage = new Image();
        croppedImage.crossOrigin = 'anonymous';

        croppedImage.onload = () => {
          const croppedCanvas = document.createElement('canvas');
          const croppedContext = croppedCanvas.getContext('2d');

          croppedCanvas.width = captureRegionWidth * deviceRatio;
          croppedCanvas.height = captureRegionHeight * deviceRatio;

          if (croppedContext) {
            croppedContext.imageSmoothingEnabled = true;
            croppedContext.imageSmoothingQuality = 'high';

            croppedContext.drawImage(
              croppedImage,
              cropLeft * deviceRatio,
              cropTop * deviceRatio,
              captureRegionWidth * deviceRatio,
              captureRegionHeight * deviceRatio,
              0,
              0,
              captureRegionWidth * deviceRatio,
              captureRegionHeight * deviceRatio,
            );

            croppedContext.lineWidth = 2;
            croppedContext.strokeStyle = 'black';
            croppedContext.strokeRect(0, 0, captureRegionWidth * deviceRatio, captureRegionHeight * deviceRatio);
          }

          const croppedImageData = croppedCanvas.toDataURL('image/png', 1);

          const pdf = new JsPDF({
            orientation: options.orientation,
          });
          const pageWidth = pdf.internal.pageSize.width;
          const pageHeight = pdf.internal.pageSize.height;

          pdf.addImage(croppedImageData, 'PNG', 5, 5, pageWidth - 10, pageHeight - 10);
          pdf.save(`report_${new Date().getTime()}.pdf`);
        };

        croppedImage.src = imgData;
      } catch (e) {
        console.error(e);
        return Promise.reject();
      }

      return Promise.resolve();
    },
    filteredFields(polygon: google.maps.Polygon) {
      return Object.values(this.allFields).filter((field: Field) => polygon.get('fieldId') === field.id);
    },
    saveOverlayButtonClicked() {
      if (this.createFieldsActive) {
        this.$emit('save:newFields');
        return;
      }

      if (this.editFieldsActive) {
        this.$emit('save:editFields');
        return;
      }

      if (this.createMapObjectActive || this.editMapObjectActive) {
        this.$emit('save:mapObject');
      }
    },
    cancelOverlayButtonClicked() {
      if (this.createFieldsActive) {
        this.$emit('update:createFieldsActive', false);
        return;
      }

      if (this.editFieldsActive) {
        this.$emit('update:editFieldsActive', false);
        return;
      }

      if (this.createMapObjectActive) {
        this.$emit('update:createMapObjectActive', false);
        return;
      }

      if (this.editMapObjectActive) {
        this.$emit('update:editMapObjectActive', false);
      }
    },
    drawPolygonUtilityButtonClicked(active: boolean) {
      if (this.createFieldsActive) {
        this.drawPolygonActive = !active;
      } else if (this.createMapObjectActive) {
        this.drawMapObjectsActive = !active;
        this.drawPolylineMapObjectsActive = false;
      }
    },
    onMenuItemClicked(itemId: string) {
      if (this.createMapObjectActive || this.editMapObjectActive || this.createReportActive) {
        return;
      }

      this.$emit('menuItem:clicked', itemId);
    },
    searchLinkButtonPressed() {
      this.searchByParcelNumberActive = !this.searchByParcelNumberActive;
      this.resetParcelSearchResult();
    },
    resetParcelSearchResult() {
      this.parcelSearchPoints = [];
      this.drawSearchResultMarkers();
      this.drawLayer(this.cadastreDataLayer!, this.landCadastres);
    },
    isLayerActive(layer: LayerItem | undefined): boolean {
      return layer ? layer.selected : false;
    },
    onPolygonSave(field: Field) {
      if (isValidPolygon(field.fieldContour.geoJson.coordinates)) {
        this.$emit('action:save', field.id);
      }
    },
    deleteMeasurePolyline() {
      if (this.currentMeasurePolyline) {
        this.currentMeasurePolyline.setMap(null);
        const index = this.distancePolylines.indexOf(this.currentMeasurePolyline);
        this.distancePolylines.splice(index, 1);
        this.currentMeasurePolyline = null;
      }
    },
    checkMeasurePolyline() {
      this.currentMeasurePolyline?.setOptions({
        editable: false,
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: '0px',
            repeat: '20px',
          },
        ],
      });
      this.currentMeasurePolyline = null;
    },
    splitPolygonPressed(active: boolean) {
      this.$emit('update:splitFieldEnabled', !active);
    },
    initialize() {
      // insert tooltip
      this.google.maps.event.addListener(this.map, 'mouseout', () => {
        this.hideCreateTooltip = true;
      });
      this.google.maps.event.addListener(this.map, 'mousemove', (event: any) => {
        this.hideCreateTooltip = false;
        this.createTooltipX = event.pixel.x;
        this.createTooltipY = event.pixel.y;
      });

      // map objects
      this.setDrawings(true);
      this.drawMapObjects();

      this.google.maps.event.addListener(this.map, 'dragend', async () => {
        // stop panning after user dragging, only if a layer is selected
        if (this.isCadastreLayerActive) {
          const center = this.map?.getCenter();
          this.map?.setCenter(center!);
        }

        await this.loadCadastres();
        await this.loadFieldUtilization();
        this.drawCadastreLabels();
      });

      this.google.maps.event.addListener(this.map, 'dragstart', () => {
        this.removeLabels();
      });

      this.google.maps.event.addListener(this.map, 'zoom_changed', async () => {
        this.removeLabels();
        const zoomChangedBoundsListener = this.google.maps.event.addListener(this.map, 'bounds_changed', async () => {
          if (this.canDrawLayer()) {
            this.loadCadastres();
            this.loadFieldUtilization();
            // drawing happens too early, before the map is finished with the zoom animation, therefore we wait a bit
            setTimeout(this.drawCadastreLabels, 350);
          } else {
            this.showLandCadastreLayer(false);
            this.showFieldUtilizationLayer(false);
            this.removeLabels();
          }

          this.google.maps.event.removeListener(zoomChangedBoundsListener);
        });

        if (this.map?.get(SYSTEM_ZOOM_CHANGE)) {
          this.map?.set(SYSTEM_ZOOM_CHANGE, false);
        }
      });

      // insert
      this.google.maps.event.addListener(this.map, 'mousedown', (event: any) => {
        this.splitFieldMousedownLocation = event.latLng;
      });
      this.google.maps.event.addListener(this.map, 'mouseup', (event: any) => {
        if (
          !this.splitFieldEnabled ||
          this.splitFieldPolyline != null ||
          this.splitFieldMousedownLocation == null ||
          this.splitFieldMousedownLocation.lat() !== event.latLng.lat() ||
          this.splitFieldMousedownLocation.lng() !== event.latLng.lng()
        ) {
          this.splitFieldMousedownLocation = null;
          return;
        }
        if (this.splitFieldDrawingModeClickCount > 0) {
          // @ts-ignore
          this.splitFieldDrawingModeMapActionsPosition = this.overlay
            ?.getProjection()
            .fromLatLngToContainerPixel(event.latLng);
        } else {
          this.splitFieldDrawingModeMapActionsPosition = null;
        }
        this.splitFieldDrawingModeClickCount += 1;
        this.splitFieldMousedownLocation = null;
      });
      this.google.maps.event.addListener(this.map, 'click', (event: any) => {
        if (!this.insertEnabled || event == null || event.latLng == null) {
          return;
        }
        this.insertFieldOrAddPolygon({ latLng: event.latLng });
      });
      if (this.insertEnabled) {
        this.map?.setOptions({ draggableCursor: 'crosshair' });
      }

      // custom controls
      this.updateButtons();
      this.updateSplitMode();

      // custom data layers
      this.cadastreDataLayer = new google.maps.Data();
      this.fieldUtilizationDataLayer = new google.maps.Data();
    },
    updateButtons() {
      if (this.buttonOk) {
        this.buttonOkElement.style.display = 'block';
      } else {
        this.buttonOkElement.style.display = 'none';
      }
      if (this.buttonAdd) {
        this.buttonAddElement.style.display = 'block';
      } else {
        this.buttonAddElement.style.display = 'none';
      }
    },
    /**
     * Draws a polygon into the map for the given field if the field has a fieldContour.
     * The reference is stored in polygonsByFieldId.
     * If the polygon already exists it just returns the old polygon.
     * If the polygon exists but the field has no contour (anymore) the polygon will be removed from the map.
     *
     * @param {object} field
     * @returns {{ polygon: {object}, bounds: LatLngBounds }|null}
     */
    addOrRemovePolygonForField(field: Field) {
      const fieldId = field.id;
      const bounds = new this.google.maps.LatLngBounds();
      if (this.polygonsByFieldId[fieldId] != null && field.fieldContour == null) {
        this.polygonsByFieldId[fieldId].setMap(null);
        delete this.polygonsByFieldId[fieldId];
        return null;
      }
      if (this.polygonsByFieldId[fieldId] != null) {
        return {
          polygon: this.polygonsByFieldId[fieldId],
          bounds,
        };
      }
      if (field.fieldContour?.geoJson?.type !== 'Polygon') {
        return null;
      }
      const paths: any[] = [];
      field.fieldContour.geoJson.coordinates.forEach((ring) => {
        const pathRing: { lat: number; lng: number }[] = [];
        ring.forEach((point, index) => {
          const pathPoint = { lat: point[1], lng: point[0] };
          if (isUnique(pathPoint, index, pathRing)) {
            pathRing.push(pathPoint);
            bounds.extend(pathPoint);
          }
        });
        paths.push(pathRing);
      });
      const polygon: google.maps.Polygon = new this.google.maps.Polygon({
        paths,
        strokeColor: GOOGLE_MAPS_SETTINGS.POLYGON_DISABLED_STROKE_COLOR,
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: GOOGLE_MAPS_SETTINGS.POLYGON_DISABLED_FILL_COLOR,
        fillOpacity: 0.5,
        clickable: true,
      });
      polygon.setMap(this.map);
      polygon.set('fieldId', fieldId);
      this.polygonsByFieldId[fieldId] = polygon;

      this.google.maps.event.addListener(polygon, 'mousemove', () => {
        this.hideCreateTooltip = true;
        if (this.disabledFields[fieldId] != null) {
          this.hoveredField = this.disabledFields[fieldId];
        } else if (this.activeFields[fieldId] != null) {
          this.hoveredField = this.activeFields[fieldId];
        } else if (this.defaultFields[fieldId] != null) {
          this.hoveredField = this.defaultFields[fieldId];
        }

        this.tooltipField = this.hoveredField;
      });

      this.google.maps.event.addListener(polygon, 'mouseout', () => {
        if (this.hoveredField != null && this.hoveredField.id === fieldId) {
          this.hoveredField = null;
        }

        this.resetHoverTooltip();
      });
      this.google.maps.event.addListener(polygon, 'click', () => {
        this.$emit('click:polygon', fieldId);
      });
      this.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        this.onPolygonChanged({ polygon, fieldId });
      });
      this.google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        this.onPolygonChanged({ polygon, fieldId });
      });
      this.google.maps.event.addListener(polygon, 'rightclick', (event: any) => {
        // Check if click was on a vertex control point
        if (event.vertex == null || polygon.getPath().getLength() < 4) {
          return;
        }
        polygon.getPath().removeAt(event.vertex);
        this.onPolygonChanged({ polygon, fieldId });
      });

      return {
        polygon,
        bounds,
      };
    },
    onPolygonChanged({ polygon, fieldId }: any) {
      const fieldContour = { geoJson: polygonToGeoJson(polygon) };
      this.$emit('change:polygon', { fieldContour, fieldId });
    },
    /**
     * Draws a marker into the map for the given field if the field has lat and lon.
     * The reference is stored in markersByFieldId.
     * If the marker already exists it just returns the old marker.
     *
     * @param {object} field
     * @param {string} markerPath
     * @returns {{ marker: {object}, bounds: LatLngBounds }|null}
     */
    addMarkerForField(field: Field, markerPath: string) {
      if (!this.showFieldMarkers) {
        return null;
      }
      const fieldId = field.id;
      const bounds = new this.google.maps.LatLngBounds();
      if (this.markersByFieldId[fieldId] != null) {
        if (this.markersByFieldId[fieldId].getIcon() !== markerPath) {
          this.markersByFieldId[fieldId].setIcon(markerPath);
        }

        return {
          marker: this.markersByFieldId[fieldId],
          bounds,
        };
      }

      if (field.lat == null || field.lon == null) {
        return null;
      }

      const marker = new this.google.maps.Marker({
        position: { lat: field.lat, lng: field.lon },
        map: this.map,
        icon: markerPath,
      });
      this.markersByFieldId[fieldId] = marker;
      bounds.extend(marker.getPosition());

      this.google.maps.event.addListener(marker, 'mouseover', () => {
        this.hideCreateTooltip = true;
        if (this.disabledFields[fieldId] != null) {
          this.hoveredField = this.disabledFields[fieldId];
        } else if (this.activeFields[fieldId] != null) {
          this.hoveredField = this.activeFields[fieldId];
        } else if (this.defaultFields[fieldId] != null) {
          this.hoveredField = this.defaultFields[fieldId];
        }

        this.tooltipField = this.hoveredField;
      });
      this.google.maps.event.addListener(marker, 'mouseout', () => {
        if (this.hoveredField != null && this.hoveredField.id === fieldId) {
          this.hoveredField = null;
        }
        this.resetHoverTooltip();
      });
      this.google.maps.event.addListener(marker, 'click', () => {
        this.$emit('click:marker', fieldId);
      });
      this.google.maps.event.addListener(marker, 'dragend', () => {
        this.$emit('change:marker', {
          fieldId,
          lat: marker.position.lat(),
          lon: marker.position.lng(),
        });
      });

      return {
        marker,
        bounds,
      };
    },
    drawSearchResultMarkers() {
      Object.values(this.searchResultMarkersById).forEach((marker) => {
        marker.setMap(null);
      });
      this.searchResultMarkersById = {};
      this.parcelSearchPoints.forEach((point) => {
        const marker = new this.google.maps.Marker({
          position: { lat: point.lat, lng: point.lon },
          map: this.map,
          icon: markerSearchResultPath,
        });
        this.google.maps.event.addListener(marker, 'click', () => {
          const bounds = new this.google.maps.LatLngBounds();
          bounds.extend(marker.getPosition());
          this.mapFitBounds({ bounds, usePan: true });
        });
        this.searchResultMarkersById[point.id] = marker;
      });
      this.drawSearchResultLayer();
    },
    drawMapObjects() {
      if (!this.google) {
        return;
      }

      // remove olds
      Object.values(this.mapObjectPolygonsById).forEach((polygon) => {
        polygon.setMap(null);
      });

      this.mapObjectPolygonsById = {};

      if (this.geoObjects) {
        Object.values(this.geoObjects).forEach((geoObject: GeoObject) => {
          if (geoObject.objectType === GeoObjectType.IsolationZone && !this.showIsolationZones) {
            return;
          }

          if (geoObject.objectType === GeoObjectType.MultiplierGroup && !this.showMultiplierGroups) {
            return;
          }

          if ((geoObject.geometry.type as string) === 'Polygon') {
            const paths: { lat: number; lng: number }[][] = [];
            const rings = geoObject.geometry.coordinates as number[][][];
            rings?.forEach((ring: number[][]) => {
              const pathRing: { lat: number; lng: number }[] = [];
              ring.forEach((point: number[]) => {
                const pathPoint = { lat: point[1], lng: point[0] };
                pathRing.push(pathPoint);
              });
              paths.push(pathRing);
            });
            const polygon = new this.google.maps.Polygon({
              paths,
              strokeColor:
                geoObject.objectType === GeoObjectType.IsolationZone
                  ? GOOGLE_MAPS_SETTINGS.POLYLINE_ISOLATION_ZONE_COLOR
                  : GOOGLE_MAPS_SETTINGS.POLYLINE_MULTIPLIER_GROUP_COLOR,
              strokeOpacity: 1,
              strokeWeight: 7,
              fillOpacity: 0,
              clickable: true,
            });
            polygon.setMap(this.map);

            polygon.getPath().addListener('set_at', () => this.updateMapObjectPolygon(polygon));
            polygon.getPath().addListener('insert_at', () => this.updateMapObjectPolygon(polygon));
            this.google.maps.event.addListener(polygon, 'click', () => {
              if (this.currentMapObjectPolygon || this.createMapObjectActive) {
                return;
              }

              this.currentMapObjectPolygon = polygon;
              polygon.setOptions({ editable: true });
              this.$emit('click:mapObject', geoObject.id);
            });

            this.google.maps.event.addListener(polygon, 'rightclick', (event: any) => {
              // Check if click was on a vertex control point
              if (event.vertex == null || (polygon.getPath().getLength() ?? 0) < 4) {
                return;
              }
              this.currentMapObjectPolygon?.getPath().removeAt(event.vertex);
              this.updateMapObjectPolygon(polygon);
            });

            this.mapObjectPolygonsById[geoObject.id] = polygon;
          }

          if (geoObject.geometry.type === GeometryType.LineString) {
            const ring = geoObject.geometry.coordinates as unknown as number[][];
            const path: { lat: number; lng: number }[] = [];

            ring.forEach((point: number[]) => {
              const pathPoint = { lat: point[1], lng: point[0] };
              path.push(pathPoint);
            });

            const polyline = new this.google.maps.Polyline({
              path,
              strokeColor:
                geoObject.objectType === GeoObjectType.IsolationZone
                  ? GOOGLE_MAPS_SETTINGS.POLYLINE_ISOLATION_ZONE_COLOR
                  : GOOGLE_MAPS_SETTINGS.POLYLINE_MULTIPLIER_GROUP_COLOR,
              strokeOpacity: 1,
              strokeWeight: 7,
              clickable: true,
            });
            polyline.setMap(this.map);

            polyline.getPath().addListener('set_at', () => this.updateMapObjectPolygon(polyline));
            polyline.getPath().addListener('insert_at', () => this.updateMapObjectPolygon(polyline));
            this.google.maps.event.addListener(polyline, 'click', () => {
              if (this.currentMapObjectPolygon || this.createMapObjectActive) {
                return;
              }

              this.currentMapObjectPolygon = polyline;
              polyline.setOptions({ editable: true });
              this.$emit('click:mapObject', geoObject.id);
            });

            this.google.maps.event.addListener(polyline, 'rightclick', (event: any) => {
              // Check if click was on a vertex control point
              if (event.vertex == null || (polyline.getPath().getLength() ?? 0) < 3) {
                return;
              }
              this.currentMapObjectPolygon?.getPath().removeAt(event.vertex);
              this.updateMapObjectPolygon(polyline);
            });

            this.mapObjectPolygonsById[geoObject.id] = polyline;
          }
        });
      }
    },
    /**
     * Draws polygons and markers for all fields (disabled, active, editable, default).
     * Then makes:
     *   - disabled field: gray polygon, no marker
     *   - active field: primary color polygon, marker visible
     *   - editable field: primary color polygon, marker visible, tooltip visible + field name editable
     *   - default field: black polygon, red marker
     *
     * @param setBounds
     * @returns {Promise<void>}
     */
    async setDrawings(setBounds = false, reset = false) {
      if (!this.google) {
        return;
      }

      if (reset) {
        Object.values(this.polygonsByFieldId).forEach((polygon) => polygon.setMap(null));
        this.polygonsByFieldId = {};
      }

      let latLngFound = false;
      const bounds = new this.google.maps.LatLngBounds();

      const fieldIds: string[] = [];

      Object.values(this.defaultFields).forEach((field: Field) => {
        fieldIds.push(field.id);

        if (Object.values(this.activeFields).length > 0) {
          if (this.markersByFieldId[field.id] != null) {
            this.markersByFieldId[field.id].setMap(null);
            delete this.markersByFieldId[field.id];
          }
        } else {
          const geoDataMarker = this.addMarkerForField(field, newMarkerPath);
          if (geoDataMarker != null) {
            latLngFound = true;
            bounds.union(geoDataMarker.bounds);
            geoDataMarker.marker.setOptions({
              draggable: false,
            });
          }
        }

        const geoDataPolygon = this.addOrRemovePolygonForField(field);

        if (geoDataPolygon == null) {
          return;
        }

        const hatch = this.varieties[field.varietyId]?.mapHatchStyle;

        geoDataPolygon.polygon.setOptions({
          strokeColor: this.getColorForPolygon(field, GOOGLE_MAPS_SETTINGS.POLYGON_DEFAULT_STROKE_COLOR),
          fillColor: this.getColorForPolygon(field, GOOGLE_MAPS_SETTINGS.POLYGON_DEFAULT_FILL_COLOR),
          fillOpacity: this.fieldColor === FieldColor.VARIETY && hatch ? 0 : 0.6,
          strokeWeight: this.createReportActive ? 1 : 3,
          strokeOpacity: 1,
          editable: false,
          zIndex: 1,
        });

        if (this.initialCenterIncludeDisabled) {
          latLngFound = true;
          bounds.union(geoDataPolygon.bounds);
        }
      });

      Object.values(this.disabledFields).forEach((field: Field) => {
        fieldIds.push(field.id);

        if (this.markersByFieldId[field.id] != null) {
          this.markersByFieldId[field.id].setMap(null);
          delete this.markersByFieldId[field.id];
        }

        const geoData = this.addOrRemovePolygonForField(field);
        if (geoData == null) {
          return;
        }
        geoData.polygon.setOptions({
          strokeColor: GOOGLE_MAPS_SETTINGS.POLYGON_DISABLED_STROKE_COLOR,
          strokeWeight: this.createReportActive ? 1 : 3,
          fillColor: GOOGLE_MAPS_SETTINGS.POLYGON_DISABLED_FILL_COLOR,
          fillOpacity: this.fieldColor === FieldColor.VARIETY ? 0 : 0.5,
          editable: false,
          zIndex: 2,
        });

        if (this.initialCenterIncludeDisabled) {
          latLngFound = true;
          bounds.union(geoData.bounds);
        }
      });

      Object.values(this.activeFields).forEach((field: Field) => {
        const isInvalid =
          this.invalidNewFields.some((invalidField) => invalidField.id === field.id) ||
          this.invalidEditFields.some((invalidField) => invalidField.id === field.id);
        fieldIds.push(field.id);

        const geoDataMarker = this.addMarkerForField(field, selectedMarkerPath);
        if (geoDataMarker != null) {
          latLngFound = true;
          bounds.union(geoDataMarker.bounds);
          geoDataMarker.marker.setOptions({
            draggable: false,
          });
        }

        const geoDataPolygon = this.addOrRemovePolygonForField(field);
        if (geoDataPolygon != null) {
          latLngFound = true;
          bounds.union(geoDataPolygon.bounds);
          geoDataPolygon.polygon.setOptions({
            strokeColor: isInvalid
              ? GOOGLE_MAPS_SETTINGS.POLYGON_INVALID_COLOR
              : this.getColorForPolygon(field, GOOGLE_MAPS_SETTINGS.POLYGON_ACTIVE_STROKE_COLOR),
            strokeWeight: this.createReportActive ? 1 : 3,
            fillColor: isInvalid
              ? GOOGLE_MAPS_SETTINGS.POLYGON_INVALID_COLOR
              : this.getColorForPolygon(field, GOOGLE_MAPS_SETTINGS.POLYGON_ACTIVE_FILL_COLOR),
            fillOpacity: isInvalid ? 0.5 : this.getOpacityForPolygon(field, 0.6),
            editable: false,
            zIndex: 3,
          });
        }
      });

      Object.values(this.editableFields).forEach((field: Field) => {
        const isInvalid =
          this.invalidNewFields.some((invalidField) => invalidField.id === field.id) ||
          this.invalidEditFields.some((invalidField) => invalidField.id === field.id);
        fieldIds.push(field.id);
        const geoDataPolygon = this.addOrRemovePolygonForField(field);
        if (geoDataPolygon != null) {
          latLngFound = true;
          bounds.union(geoDataPolygon.bounds);
          geoDataPolygon.polygon.setOptions({
            strokeColor: isInvalid
              ? GOOGLE_MAPS_SETTINGS.POLYGON_INVALID_COLOR
              : GOOGLE_MAPS_SETTINGS.POLYGON_ACTIVE_STROKE_COLOR,
            strokeWeight: this.createReportActive ? 1 : 3,
            fillColor: isInvalid
              ? GOOGLE_MAPS_SETTINGS.POLYGON_INVALID_COLOR
              : GOOGLE_MAPS_SETTINGS.POLYGON_ACTIVE_FILL_COLOR,
            fillOpacity: 0.5,
            editable: true,
            zIndex: 100,
          });
        }

        const geoDataMarker = this.addMarkerForField(field, selectedMarkerPath);
        if (geoDataMarker != null) {
          latLngFound = true;
          bounds.union(geoDataMarker.bounds);
          geoDataMarker.marker.setOptions({
            draggable: true,
          });
        }
      });

      Object.keys(this.polygonsByFieldId).forEach((fieldId) => {
        if (fieldIds.includes(fieldId)) {
          return;
        }
        this.polygonsByFieldId[fieldId].setMap(null);
        delete this.polygonsByFieldId[fieldId];
      });
      Object.keys(this.markersByFieldId).forEach((fieldId) => {
        if (fieldIds.includes(fieldId)) {
          return;
        }
        this.markersByFieldId[fieldId].setMap(null);
        delete this.markersByFieldId[fieldId];
      });

      this.showOrHideMarkers();

      // Check if the map should be re-centered
      if (!setBounds) {
        return;
      }

      // Make sure that google map is rendered
      await this.$nextTick();

      if (latLngFound) {
        this.map?.set(SYSTEM_ZOOM_CHANGE, true);
        this.mapFitBounds({ bounds });
      } else {
        this.centerMapByCompanies();
      }
    },
    /**
     * The maxZoom prevents the map from zooming in more than the given maxZoom level.
     *
     * @param bounds
     * @param maxZoom
     * @param {boolean} usePan
     */
    mapFitBounds({
      bounds,
      maxZoom = 16,
      usePan = false,
    }: {
      bounds: google.maps.LatLngBounds;
      maxZoom?: number;
      usePan?: boolean;
    }) {
      this.google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
        this.map?.setOptions({ maxZoom: null });
      });
      this.map?.setOptions({ maxZoom });
      if (usePan) {
        this.map?.set(SYSTEM_ZOOM_CHANGE, true);
        this.map?.setZoom(maxZoom);
        this.map?.panToBounds(bounds, 500);
      } else {
        this.map?.set(SYSTEM_ZOOM_CHANGE, true);
        this.map?.fitBounds(bounds);
      }
    },
    /**
     * Checks for lat+lon in the given company objects. Then tries to center the map to fit them all.
     * If no lat/lon is available it will try to show the region(s) of the companies.
     * The last fallback is centering/showing the whole of Austria.
     *
     * @returns {Promise<void>}
     */
    async centerMapByCompanies(): Promise<void> {
      // Check if the company has a set location
      let latLngFound = false;
      const bounds = new this.google.maps.LatLngBounds();
      this.companies.forEach((company: Company) => {
        if (company.location != null) {
          bounds.extend({ lat: company.location.lat, lng: company.location.lon });
          latLngFound = true;
        }
      });
      if (latLngFound) {
        this.map?.set(SYSTEM_ZOOM_CHANGE, true);
        this.map?.fitBounds(bounds);
        this.map?.setZoom(16);
        return;
      }

      // Try setting center on the company's region
      await Promise.all(
        this.companies.map(
          (company: Company) =>
            new Promise<void>((resolve) => {
              if (company.region == null) {
                resolve();
                return;
              }
              const geocoder = new this.google.maps.Geocoder();
              geocoder.geocode(
                { address: company.region.name },
                (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                  if (status !== this.google.maps.GeocoderStatus.OK || !results[0]) {
                    resolve();
                    return;
                  }
                  latLngFound = true;
                  bounds.union(results[0].geometry.viewport);
                  resolve();
                },
              );
            }),
        ),
      );
      if (latLngFound) {
        this.map?.set(SYSTEM_ZOOM_CHANGE, true);
        this.map?.fitBounds(bounds);
        return;
      }

      // Center on Austria
      this.map?.setCenter({
        lat: 47.59548672381274,
        lng: 13.628580142116551,
      });
      this.map?.set(SYSTEM_ZOOM_CHANGE, true);
      this.map?.setZoom(7);
    },
    async mapAddressSearch() {
      if (this.searchByParcelNumberActive) {
        const { mapApi } = this.$api as Api;
        try {
          const searchResponse = await mapApi.mapLayerLandCadastreSearch({ query: this.mapAddressSearchTerm });
          if (searchResponse.status === 200) {
            this.parcelSearchPoints = searchResponse.data.data ?? [];
            if (this.parcelSearchPoints.length === 0) {
              this.parcelSearchEmpyResult = true;
              return;
            }
            this.parcelSearchEmpyResult = false;

            const bounds = new this.google.maps.LatLngBounds();
            this.parcelSearchPoints.forEach(({ lat, lon }) => {
              bounds.extend({ lat, lng: lon });
            });
            this.mapFitBounds({ bounds });
            this.drawSearchResultMarkers();
            this.drawLayer(this.cadastreDataLayer!, this.landCadastres);
          }
        } catch (error) {
          console.error('parcel search error', error);
        }
      } else {
        const isCoordinate = COORDINATE_REGEX.test(this.mapAddressSearchTerm);

        if (isCoordinate) {
          const [lat, lon] = this.mapAddressSearchTerm.split(',').map(Number);
          this.parcelSearchPoints = [{ id: '1', lat, lon }];
          const latLng = new this.google.maps.LatLng(lat, lon);
          this.map?.setCenter(latLng);
          this.map?.setZoom(16);
        } else {
          const geocoder = new this.google.maps.Geocoder();
          geocoder.geocode({ address: this.mapAddressSearchTerm }, (results: any, status: any) => {
            if (status !== this.google.maps.GeocoderStatus.OK || !results[0]) {
              return;
            }
            this.map?.setCenter(results[0].geometry.location);
            this.map?.fitBounds(results[0].geometry.viewport);
            this.mapAddressSearchTerm = results[0].formatted_address;
          });
        }

        this.drawSearchResultMarkers();
        this.map?.set(SYSTEM_ZOOM_CHANGE, true);
      }
    },
    mapMouseOut() {
      this.hideCreateTooltip = true;
    },
    mapMouseMove(e: any) {
      if (e.target.closest('.gmnoprint') != null) {
        this.hideCreateTooltip = true;
        return;
      }
      this.hideCreateTooltip = false;
      this.createTooltipX = e.offsetX;
      this.createTooltipY = e.offsetY;
    },
    /**
     * Tries to create a field when the user clicks into the map.
     * Therefore it calls /admin/sen4/fieldContourByPoint to get a fieldContour.
     * If none can be gathered it creates a dummy rectangle.
     * Then emits an 'insert' event with the following payload:
     * `{ lat: {number}, lon: {number}, fieldContour: { geoJson: { type: 'Polygon', coordinates {array} } } }`
     *
     * @param {object} latLng
     * @param {string|null} fieldId
     * @returns {Promise<void>}
     */
    async insertFieldOrAddPolygon({
      latLng,
      fieldId,
    }: {
      latLng: google.maps.LatLng | null | undefined;
      fieldId?: string;
    }) {
      if (this.fetchingNewFieldContour || latLng == null) {
        return;
      }

      // @ts-ignore
      this.mapLoaderPosition = this.overlay?.getProjection().fromLatLngToContainerPixel(latLng);
      this.fetchingNewFieldContour = true;
      const lat = latLng.lat();
      const lon = latLng.lng();
      const newField: {
        lat: number;
        lon: number;
        fieldContour?: { geoJson: { coordinates: number[][][]; type: string } };
      } = { lat, lon };
      try {
        const { data } = await axios.post('/admin/sen4/fieldContourByPoint', { lat, lon });
        newField.fieldContour = { geoJson: data.contour };
      } catch (error) {
        console.error('FieldsMap: Unable to load field contour from Sen4 API', error);
        newField.fieldContour = this.createDummyContourFromLatLng(lat, lon);
      }
      if (fieldId == null) {
        this.$emit('insert', newField);
      } else {
        this.$emit('addPolygon', {
          ...newField,
          id: fieldId,
        });
      }
      this.fetchingNewFieldContour = false;
    },
    createDummyContourFromLatLng(lat: number, lng: number) {
      return {
        geoJson: {
          coordinates: [
            [
              [lng - 0.001, lat - 0.0005],
              [lng + 0.001, lat - 0.0005],
              [lng + 0.001, lat + 0.0005],
              [lng - 0.001, lat + 0.0005],
              [lng - 0.001, lat - 0.0005],
            ],
          ],
          type: 'Polygon',
        },
      };
    },
    /**
     * Calculates map x+y coordinates for a given fields lat+lon.
     * Used for positioning the MapActions components.
     *
     * @param field
     * @returns {{x: number, y: number}|*}
     */
    mapActionsPixelPosition(field: Field) {
      if (field.lat == null || field.lon == null) {
        return { x: 0, y: 0 };
      }
      const position = new this.google.maps.LatLng(field.lat, field.lon);
      return this.overlay?.getProjection().fromLatLngToContainerPixel(position);
    },
    mapActionsSplitLinePosition(splitFieldPolyline: google.maps.Polyline) {
      const length = splitFieldPolyline.getPath().getLength();
      if (length < 2) {
        return { x: 0, y: 0 };
      }
      const lastPoint = splitFieldPolyline.getPath().getAt(length - 1);
      return this.overlay?.getProjection().fromLatLngToContainerPixel(lastPoint);
    },
    centerOnFields(fields: Field[]): void {
      fields
        .filter(notNullOrUndefined)
        .flatMap((field: Field) => field.fieldContour?.geoJson?.coordinates)
        .filter(notNullOrUndefined)
        .map(this.toGoogleMapsPath)
        .flatMap((path: google.maps.MVCArray) => path.getArray() as google.maps.LatLng[])
        .forEach((point: google.maps.LatLng) => this.pointsInFocus.push(point));
    },
    /**
     * Checks the editable field tooltips and sets the focus on the first one with an empty field name.
     */
    setFocusOnFieldName() {
      if (!this.insertEnabled) {
        return;
      }
      const fieldsWithMissingName = Object.values(this.editableFields).filter(
        (field) => typeof field.name !== 'string' || field.name.length === 0,
      );
      if (fieldsWithMissingName.length === 0) {
        return;
      }
      const [field] = fieldsWithMissingName;
      if (
        !Array.isArray(this.$refs[`fields-map__map-field-tooltip--${field.id}`]) ||
        // @ts-ignore
        this.$refs[`fields-map__map-field-tooltip--${field.id}`][0] == null
      ) {
        return;
      }

      const bounds = this.map?.getBounds();
      const marker = this.markersByFieldId[field.id];
      const markerPosition = marker.getPosition();
      if (
        markerPosition != null &&
        bounds?.contains({
          lat: markerPosition.lat(),
          lng: markerPosition.lng(),
        })
      ) {
        // @ts-ignore
        this.$refs[`fields-map__map-field-tooltip--${field.id}`][0].$refs['map-field-tooltip__name-input'].focus();
      }
    },
    onMapActionAddPolygon(fieldId: string) {
      if (this.markersByFieldId[fieldId] == null) {
        return;
      }
      this.insertFieldOrAddPolygon({ latLng: this.markersByFieldId[fieldId].getPosition(), fieldId });
    },
    // region - Split Field
    /**
     * Initialize the DrawingManager for drawing a line.
     *
     * @private
     */
    updateSplitMode(reset = true) {
      if (reset) {
        if (this.splitFieldDrawingManager != null) {
          this.splitFieldDrawingManager.setMap(null);
          this.splitFieldDrawingManager = null;
        }
        if (this.splitFieldPolyline != null) {
          this.splitFieldPolyline.setMap(null);
          this.splitFieldPolyline = null;
        }
        this.splitFieldDrawingModeClickCount = 0;
        this.splitFieldMousedownLocation = null;
        this.splitFieldDrawingModeMapActionsPosition = null;
        this.splitFieldNewFieldA = null;
        this.splitFieldNewFieldB = null;
        this.$emit('update:splitFieldLineValid', undefined);
      }

      if (
        this.splitFieldEnabled &&
        Object.values(this.activeFields).length === 1 &&
        this.splitFieldDrawingManager == null
      ) {
        this.splitFieldDrawingManager = new this.google.maps.drawing.DrawingManager({
          drawingMode: this.google.maps.drawing.OverlayType.POLYLINE,
          drawingControl: false,
          polylineOptions: {
            geodesic: true,
            strokeOpacity: 1.0,
            strokeColor: '#FF614C',
            strokeWeight: 3,
            zIndex: 4,
          },
        });
        this.splitFieldDrawingManager?.setMap(this.map);
        this.splitFieldDrawingManager?.addListener('polylinecomplete', (polyline: google.maps.Polyline) => {
          this.splitFieldDrawingManager?.setMap(null);
          polyline.setOptions({ editable: true });
          polyline.getPath().addListener('set_at', this.calculateSplitFieldSizes);
          polyline.getPath().addListener('insert_at', this.calculateSplitFieldSizes);
          this.splitFieldPolyline = polyline;
          this.calculateSplitFieldSizes();
        });
      }
    },
    updateMapObjectsDrawingMode(reset = true, drawPolygon = true) {
      if (reset) {
        if (this.mapObjectsDrawingManager != null) {
          this.mapObjectsDrawingManager.setMap(null);
          this.mapObjectsDrawingManager = null;
        }
      }

      if (this.drawMapObjectsActive && this.mapObjectsDrawingManager == null && drawPolygon) {
        this.mapObjectsDrawingManager = new this.google.maps.drawing.DrawingManager({
          drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: false,
          polygonOptions: {
            geodesic: true,
            strokeOpacity: 1,
            strokeColor:
              this.currentMapObjectType === GeoObjectType.IsolationZone
                ? GOOGLE_MAPS_SETTINGS.POLYLINE_ISOLATION_ZONE_COLOR
                : GOOGLE_MAPS_SETTINGS.POLYLINE_MULTIPLIER_GROUP_COLOR,
            strokeWeight: 7,
            fillOpacity: 0,
            zIndex: 5,
          },
        });
      }

      if (this.drawPolylineMapObjectsActive && this.mapObjectsDrawingManager == null && !drawPolygon) {
        this.mapObjectsDrawingManager = new this.google.maps.drawing.DrawingManager({
          drawingMode: this.google.maps.drawing.OverlayType.POLYLINE,
          drawingControl: false,
          polylineOptions: {
            geodesic: true,
            strokeOpacity: 1,
            strokeColor:
              this.currentMapObjectType === GeoObjectType.IsolationZone
                ? GOOGLE_MAPS_SETTINGS.POLYLINE_ISOLATION_ZONE_COLOR
                : GOOGLE_MAPS_SETTINGS.POLYLINE_MULTIPLIER_GROUP_COLOR,
            strokeWeight: 7,
            zIndex: 5,
          },
        });
      }

      this.mapObjectsDrawingManager?.setMap(this.map);
      this.mapObjectsDrawingManager?.addListener('polygoncomplete', (polygon: google.maps.Polygon) => {
        this.drawMapObjectsActive = false;
        this.mapObjectsDrawingManager?.setMap(null);
        this.currentMapObjectPolygon = polygon;
        polygon.setOptions({ editable: true });
        polygon.getPath().addListener('set_at', () => this.updateMapObjectPolygon(polygon));
        polygon.getPath().addListener('insert_at', () => this.updateMapObjectPolygon(polygon));
        this.google.maps.event.addListener(polygon, 'rightclick', (event: any) => {
          // Check if click was on a vertex control point
          if (event.vertex == null || (polygon.getPath().getLength() ?? 0) < 4) {
            return;
          }
          this.currentMapObjectPolygon?.getPath().removeAt(event.vertex);
          this.updateMapObjectPolygon(polygon);
        });
        this.updateMapObjectPolygon(polygon);
      });

      this.mapObjectsDrawingManager?.addListener('polylinecomplete', (polyline: google.maps.Polyline) => {
        this.drawPolylineMapObjectsActive = false;
        this.mapObjectsDrawingManager?.setMap(null);
        this.currentMapObjectPolygon = polyline;
        polyline.setOptions({ editable: true });
        polyline.getPath().addListener('set_at', () => this.updateMapObjectPolygon(polyline));
        polyline.getPath().addListener('insert_at', () => this.updateMapObjectPolygon(polyline));
        this.google.maps.event.addListener(polyline, 'rightclick', (event: any) => {
          // Check if click was on a vertex control point
          if (event.vertex == null || (polyline.getPath().getLength() ?? 0) < 4) {
            return;
          }
          this.currentMapObjectPolygon?.getPath().removeAt(event.vertex);
          this.updateMapObjectPolygon(polyline);
        });
        this.updateMapObjectPolygon(polyline);
      });
    },
    updateMapObjectPolygon(polygon: google.maps.Polygon | google.maps.Polyline) {
      const geoJson = polygonToGeoJson(polygon);
      this.$emit('update:mapObjectPolyline', geoJson);
    },
    updateDrawingMode(reset = true) {
      if (reset) {
        if (this.polygonDrawingManager != null) {
          this.polygonDrawingManager.setMap(null);
          this.polygonDrawingManager = null;
        }
      }

      if (this.drawPolygonActive && this.polygonDrawingManager == null) {
        this.polygonDrawingManager = new this.google.maps.drawing.DrawingManager({
          drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: false,
          polygonOptions: {
            geodesic: true,
            strokeOpacity: 1.0,
            strokeColor: GOOGLE_MAPS_SETTINGS.POLYGON_ACTIVE_STROKE_COLOR,
            strokeWeight: 3,
            zIndex: 4,
          },
        });

        this.polygonDrawingManager?.setMap(this.map);
        this.polygonDrawingManager?.addListener('polygoncomplete', (polygon: google.maps.Polygon) => {
          // ensure, that the first point is the exact same as the last point, google maps drawing manager adds a slightly different point at the end
          const path = polygon.getPath();
          path.push(path.getAt(0));

          this.polygonDrawingManager?.setMap(null);
          this.drawPolygonActive = false;
          polygon.setOptions({ editable: true });
          polygon.setMap(null);
          this.$emit('polygon:complete', polygon);
        });
      }
    },
    updateMeasureDistancesMode(reset = true) {
      if (reset) {
        if (this.polylineMeasureDistanceDrawingManager != null) {
          this.polylineMeasureDistanceDrawingManager.setMap(null);
          this.polylineMeasureDistanceDrawingManager = null;
        }
      }

      if (this.measureDistancesActive && this.polylineMeasureDistanceDrawingManager == null) {
        this.polylineMeasureDistanceDrawingManager = new this.google.maps.drawing.DrawingManager({
          drawingMode: this.google.maps.drawing.OverlayType.POLYLINE,
          drawingControl: false,
          polylineOptions: {
            geodesic: true,
            strokeOpacity: 1,
            strokeColor: GOOGLE_MAPS_SETTINGS.MEASURE_DISTANCE_LINE_COLOR,
            strokeWeight: 3,
            zIndex: 4,
          },
        });

        this.polylineMeasureDistanceDrawingManager?.setMap(this.map);
        this.polylineMeasureDistanceDrawingManager?.addListener(
          'polylinecomplete',
          (polyline: google.maps.Polyline) => {
            if (this.currentMeasurePolyline) this.currentMeasurePolyline.setOptions({ editable: false });
            this.distancePolylines.push(polyline);
            this.currentMeasurePolyline = polyline;
            this.polylineMeasureDistanceDrawingManager?.setMap(null);
            this.measureDistancesActive = false;
            polyline.set('timestamp', new Date().valueOf());

            polyline.addListener('click', () => {
              polyline.setOptions({
                editable: true,
                strokeOpacity: 1,
                icons: [],
              });
              this.currentMeasurePolyline = polyline;
            });

            polyline.getPath().addListener('set_at', () => this.updatePolyline(polyline));
            polyline.getPath().addListener('insert_at', () => this.updatePolyline(polyline));
            polyline.setOptions({
              editable: true,
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: '0px',
                  repeat: '20px',
                },
              ],
            });
          },
        );
      }
    },
    /**
     * Try to split the currently active polygon with the drawn polyline.
     *
     * @private
     */
    calculateSplitFieldSizes() {
      let currentPolygonGroup = null;
      const polygonGroup1: google.maps.LatLng[] = [];
      const polygonGroup2: google.maps.LatLng[] = [];
      const polygonGroup3: google.maps.LatLng[] = [];
      const polygonPolylineIntersections: google.maps.LatLng[] = [];
      const polylineFragment1: google.maps.LatLng[] = [];
      const polylineFragment2: google.maps.LatLng[] = [];
      let polylineGroup: google.maps.LatLng[] = [];
      this.splitFieldNewFieldA = null;
      this.splitFieldNewFieldB = null;

      // 1. run through polygon and check for intersections with polyline (exactly TWO have to occur to split cleanly)
      if (
        this.splitFieldPolyline == null ||
        Object.values(this.activeFields).length !== 1 ||
        this.polygonsByFieldId[Object.values(this.activeFields)[0].id] == null
      ) {
        this.$emit('update:splitFieldLineValid', undefined);
        return;
      }
      const polygon = this.polygonsByFieldId[Object.values(this.activeFields)[0].id];
      let prevPolygonLatLng: google.maps.LatLng;
      currentPolygonGroup = polygonGroup1;
      polygon.getPath().forEach((polygonLatLng) => {
        if (prevPolygonLatLng == null) {
          prevPolygonLatLng = polygonLatLng;
          currentPolygonGroup.push(polygonLatLng);
          return;
        }
        let prevPolylineLatLng: google.maps.LatLng;
        this.splitFieldPolyline?.getPath().forEach((polylineLatLng: google.maps.LatLng) => {
          if (prevPolylineLatLng == null) {
            prevPolylineLatLng = polylineLatLng;
            return;
          }
          const intersectionLatLng: boolean | { x: number | null; y: number | null } = checkLineIntersection(
            prevPolygonLatLng.lat(),
            prevPolygonLatLng.lng(),
            polygonLatLng.lat(),
            polygonLatLng.lng(),
            prevPolylineLatLng.lat(),
            prevPolylineLatLng.lng(),
            polylineLatLng.lat(),
            polylineLatLng.lng(),
          );
          if (intersectionLatLng) {
            polygonPolylineIntersections.push(
              new this.google.maps.LatLng({
                lat: (intersectionLatLng as { x: number | null; y: number | null }).x,
                lng: (intersectionLatLng as { x: number | null; y: number | null }).y,
              }),
            );
            if (polygonPolylineIntersections.length === 1) {
              currentPolygonGroup = polygonGroup2;
              polylineFragment1.push(prevPolylineLatLng);
              polylineFragment1.push(polylineLatLng);
            } else if (polygonPolylineIntersections.length === 2) {
              currentPolygonGroup = polygonGroup3;
              polylineFragment2.push(prevPolylineLatLng);
              polylineFragment2.push(polylineLatLng);
            }
          }
          prevPolylineLatLng = polylineLatLng;
        });
        prevPolygonLatLng = polygonLatLng;
        currentPolygonGroup.push(polygonLatLng);
      });

      // 2. check if polyline is valid
      if (polygonPolylineIntersections.length !== 2) {
        this.$emit('update:splitFieldLineValid', false);
        return;
      }

      // 3. reduce polyline
      if (polylineFragment1[0] === polylineFragment2[0] && polylineFragment1[1] === polylineFragment2[1]) {
        polylineGroup = polygonPolylineIntersections;
      } else {
        let prevPolylineLatLng: google.maps.LatLng;
        let polylineFinished = false;
        this.splitFieldPolyline.getPath().forEach((polylineLatLng: google.maps.LatLng) => {
          if (prevPolylineLatLng == null || polylineFinished) {
            prevPolylineLatLng = polylineLatLng;
            return;
          }
          if (polylineFragment1[0] === prevPolylineLatLng && polylineFragment1[1] === polylineLatLng) {
            polylineGroup.push(polygonPolylineIntersections[0]);
            if (polylineGroup.length > 1) {
              polylineFinished = true;
              return;
            }
          } else if (polylineFragment2[0] === prevPolylineLatLng && polylineFragment2[1] === polylineLatLng) {
            polylineGroup.push(polygonPolylineIntersections[1]);
            if (polylineGroup.length > 1) {
              polylineFinished = true;
              return;
            }
          }
          if (polylineGroup.length > 0) {
            polylineGroup.push(polylineLatLng);
            prevPolylineLatLng = polylineLatLng;
          }
        });
      }

      // 4. create new polygonA
      let path;
      if (polylineGroup[0] === polygonPolylineIntersections[0]) {
        path = polygonGroup1.concat(polylineGroup, polygonGroup3);
      } else {
        path = polygonGroup1.concat(reverse(polylineGroup), polygonGroup3);
      }
      this.splitFieldNewFieldA = new this.google.maps.Polygon({ paths: path });

      // 5. create new polygonB
      if (polylineGroup[0] === polygonPolylineIntersections[1]) {
        path = polylineGroup.concat(polygonGroup2);
      } else {
        path = reverse(polylineGroup).concat(polygonGroup2);
      }
      this.splitFieldNewFieldB = new this.google.maps.Polygon({ paths: path });

      // 6. Emit event telling the parent component about the successful split
      this.$emit('update:splitFieldLineValid', true);
      this.$emit('update:newFieldPolygons', {
        geoJsonA: polygonToGeoJson(this.splitFieldNewFieldA as google.maps.Polygon),
        geoJsonB: polygonToGeoJson(this.splitFieldNewFieldB as google.maps.Polygon),
      });
    },
    // endregion - Split Field
    async onFieldColorChanged(value: FieldColor) {
      this.fieldColor = value;
      if (this.fieldColor === FieldColor.ACTIVITY) {
        this.$emit('loadActivities');
      }

      if (this.fieldColor === FieldColor.NDVI) {
        this.$emit('loadNdvi');
      }

      if (this.fieldColor === FieldColor.MBI) {
        this.$emit('loadMbi');
      }

      this.setDrawings();
    },
    onActiveLayersChanged(value: LayerItem[]) {
      this.layers = value;
      const landCadastreLayerItem = value.find((item) => item.type === LayerType.LAND_CADASTRES);
      const fieldUtilizationLayerItem = value.find((item) => item.type === LayerType.FIELD_UTILIZATION);
      const isolationZoneLayerItem = value.find((item) => item.type === LayerType.ISOLATION_ZONES);
      const mgLayerItem = value.find((item) => item.type === LayerType.MULTIPLIER_GROUPS);

      this.showIsolationZones = isolationZoneLayerItem?.selected ?? false;
      this.showMultiplierGroups = mgLayerItem?.selected ?? false;

      // disable search by parcel number if cadastre layer is not selected
      if (!this.isCadastreLayerActive) {
        this.searchByParcelNumberActive = false;
      }

      // if both layers are selected in edit mode, only show field utilization
      if (
        landCadastreLayerItem?.selected &&
        fieldUtilizationLayerItem?.selected &&
        this.canDrawLayer() &&
        this.createFieldsActive
      ) {
        this.loadFieldUtilization();
        this.showFieldUtilizationLayer(true);
        this.showLandCadastreLayer(false);
        return;
      }

      if (landCadastreLayerItem?.selected && this.canDrawLayer()) {
        this.loadCadastres();
        this.showLandCadastreLayer(true);
      } else {
        this.showLandCadastreLayer(false);
      }

      if (fieldUtilizationLayerItem?.selected && this.canDrawLayer()) {
        this.loadFieldUtilization();
        this.showFieldUtilizationLayer(true);
      } else {
        this.showFieldUtilizationLayer(false);
      }
    },
    alwaysShowFieldNameChanged(value: boolean) {
      this.alwaysShowFieldNameTooltip = value;
      this.showOrHideMarkers();
    },
    onActiveOrDefaultFieldsUpdate(fields: Data<Field>): void {
      this.updateSplitMode();
      this.centerOnFields(Object.values(fields));
    },
    getColorForPolygon(field: Field, defaultColor: string) {
      if (this.fieldColor === FieldColor.CROP && field.cropId && this.crops[field.cropId]) {
        const color = this.crops[field.cropId].mapPolygonColor;
        return color || defaultColor;
      }

      if (this.fieldColor === FieldColor.VARIETY && field.varietyId && this.varieties[field.varietyId]) {
        const color = this.varieties[field.varietyId].mapPrimaryColor;
        return color || defaultColor;
      }

      if (this.fieldColor === FieldColor.ACTIVITY) {
        const lastActivityType = findLastActivityTypeOfField(
          field.id,
          Object.values(this.activities),
          this.activityTypes,
        );
        if (lastActivityType) {
          return `#${lastActivityType.appColor}`;
        }
      }

      if (this.fieldColor === FieldColor.NDVI && field.ndviProps) {
        return field.ndviProps.fill;
      }

      if (this.fieldColor === FieldColor.MBI && field.mbiProps) {
        return field.mbiProps.fill;
      }
      return defaultColor;
    },
    getOpacityForPolygon(field: Field, defaultOpacity: number) {
      if (this.fieldColor === FieldColor.CROP && field.cropId && this.crops[field.cropId]) {
        return 1;
      }

      if (this.fieldColor === FieldColor.VARIETY && field.varietyId && this.varieties[field.varietyId]) {
        return 1;
      }

      if (
        this.fieldColor === FieldColor.ACTIVITY &&
        findLastActivityTypeOfField(field.id, Object.values(this.activities), this.activityTypes)
      ) {
        return 1;
      }

      if (this.fieldColor === FieldColor.NDVI && field.ndviProps) {
        return 1;
      }

      if (this.fieldColor === FieldColor.MBI && field.mbiProps) {
        return 1;
      }

      return defaultOpacity;
    },
    findLastActivityTypeOfField(field: Field) {
      return this.fieldColor === FieldColor.ACTIVITY
        ? findLastActivityTypeOfField(field.id, Object.values(this.activities), this.activityTypes)
        : null;
    },
    showLandCadastreLayer(show: boolean) {
      if (show) {
        this.cadastreDataLayer?.setMap(this.map);
        this.drawCadastreLabels();
      } else {
        this.cadastreDataLayer?.setMap(null);
        this.removeLabels();
      }
    },
    showFieldUtilizationLayer(show: boolean) {
      if (show) {
        this.fieldUtilizationDataLayer?.setMap(this.map);
      } else {
        this.fieldUtilizationDataLayer?.setMap(null);
      }
    },
    loadCadastres() {
      if (!this.isCadastreLayerActive) return;

      if (this.canDrawLayer()) {
        this.$emit('update:bounds', this.map?.getBounds());
        this.cadastreDataLayer?.setMap(this.map);
      } else {
        this.cadastreDataLayer?.setMap(null);
      }
    },
    loadFieldUtilization() {
      if (!this.isFieldUtilizationLayerActive) return;

      if (this.canDrawLayer()) {
        this.$emit('update:fieldUtilization', this.map?.getBounds());
        this.fieldUtilizationDataLayer?.setMap(this.map);
      } else {
        this.fieldUtilizationDataLayer?.setMap(null);
      }
    },
    drawSearchResultLayer() {
      if (this.searchResultLayer) {
        this.searchResultLayer.setMap(null);
      }
      this.searchResultLayer = new this.google.maps.Data();
      this.parcelSearchPoints.forEach((point) => {
        const feature = this.cadastrePolygonFeatures.find((f) => f.getId() === point.id);

        if (feature) {
          this.searchResultLayer?.add(feature);
        }
      });

      this.searchResultLayer?.addListener('click', (event: any) => {
        const clickedPolygon = this.searchResultLayer?.getFeatureById(event.feature.getId());
        if (clickedPolygon) {
          this.$emit('click:layerPolygon', { feature: event.feature, latLng: event.latLng, data: this.landCadastres });
        }
      });

      this.searchResultLayer?.setStyle({
        strokeWeight: 5,
        strokeColor: GOOGLE_MAPS_SETTINGS.POLYGON_ACTIVE_HOVER_COLOR,
        fillOpacity: 0,
        zIndex: 10,
      });
      this.searchResultLayer?.setMap(this.map);
    },
    drawLayer(layer: google.maps.Data, data: Data<LandCadastre> | Data<FieldUtilization>) {
      let cadastrePolygonFeaturesReset = false;

      layer.forEach((feature) => {
        layer.remove(feature);
      });

      if (Object.values(data).length > 0) {
        Object.values(data).forEach((dataObject) => {
          const isLandCadastreLayer = !!Object.prototype.hasOwnProperty.call(dataObject, 'parcelNumber');
          if (isLandCadastreLayer && !cadastrePolygonFeaturesReset) {
            this.cadastrePolygonFeatures = [];
            cadastrePolygonFeaturesReset = true;
          }
          const paths: any[] = [];
          if (dataObject.geometry) {
            const { coordinates } = dataObject.geometry;
            if (coordinates) {
              coordinates.forEach((ring: number[][]) => {
                const pathRing: { lat: number; lng: number }[] = [];
                ring.forEach((point) => {
                  const pathPoint = { lat: point[1], lng: point[0] };
                  pathRing.push(pathPoint);
                });
                paths.push(pathRing);
              });

              const polygon: google.maps.Data.Polygon = new this.google.maps.Data.Polygon(paths);
              const feature = new google.maps.Data.Feature({
                geometry: polygon,
                id: dataObject.id,
                properties: isLandCadastreLayer ? { parcelNumber: (dataObject as LandCadastre).parcelNumber } : {},
              });

              layer.add(feature);

              if (isLandCadastreLayer) this.cadastrePolygonFeatures.push(feature);
            }
          }
        });
        layer.addListener('click', (event: any) => {
          const clickedPolygon = layer.getFeatureById(event.feature.getId());
          if (clickedPolygon) {
            this.$emit('click:layerPolygon', { feature: event.feature, latLng: event.latLng, data });
          }
        });

        layer.addListener('mouseover', (event: any) => {
          layer.overrideStyle(event.feature, {
            strokeWeight: 5,
            strokeColor: GOOGLE_MAPS_SETTINGS.POLYGON_ACTIVE_HOVER_COLOR,
          });
        });

        layer.addListener('mouseout', (event: any) => {
          layer.overrideStyle(event.feature, {
            fillOpacity: 0,
            strokeWeight: this.createReportActive ? 0.3 : 1,
            strokeColor: this.getLayerStrokeColor,
          });
        });
        layer.setStyle({
          fillOpacity: 0,
          strokeWeight: this.createReportActive ? 0.3 : 1,
          strokeColor: this.getLayerStrokeColor,
        });
        this.drawSearchResultLayer();
      }
    },
    updatePolyline(polyline: google.maps.Polyline) {
      const index = this.distancePolylines.findIndex((line) => line === polyline);
      polyline.set('timestamp', new Date().valueOf());
      this.distancePolylines[index] = polyline;
    },
    drawCadastreLabel(feature: google.maps.Data.Feature, overlay: google.maps.OverlayView) {
      const center = calculateBoundsCenter(feature);

      const label = document.createElement('div');
      label.className = 'polygon-label';
      label.innerHTML = feature.getProperty('parcelNumber') ?? '';

      if (overlay.getProjection()) {
        const point = overlay.getProjection().fromLatLngToContainerPixel(center);
        label.style.top = `${point?.y}px`;
        label.style.left = `${point?.x}px`;
      } else {
        return;
      }

      this.map?.getDiv().appendChild(label);
      this.cadastreLabels.push(label);
    },
    removeLabels() {
      this.cadastreLabels.forEach((label) => {
        label.remove();
      });
      this.cadastreLabels = [];
    },
    drawCadastreLabels() {
      if (!this.isCadastreLayerActive || this.createReportActive) {
        this.removeLabels();
        return;
      }

      this.removeLabels();
      if (this.map?.getZoom()! < this.minZoomLoadCadastres) return;
      const overlay = new this.google.maps.OverlayView();
      overlay.draw = () => {};
      overlay.setMap(this.map);

      this.cadastreDataLayer?.forEach((feature) => {
        if (this.map?.getBounds()?.contains(calculateBoundsCenter(feature))) {
          this.drawCadastreLabel(feature, overlay);
        }
      });
      overlay.setMap(null);
    },
    firstPointIn(multiLineString: MultiLineString): google.maps.LatLng {
      const firstPoint: number[] = multiLineString.coordinates.flat(1)[0];
      return this.toGoogleMapsPoint(firstPoint);
    },
    lastPointIn(multiLineString: MultiLineString): google.maps.LatLng {
      // we cannot reverse the coordinates here, because we mutate the original array
      const lastPoint: number[] = multiLineString.coordinates.flat(1).slice(-1)[0];
      return this.toGoogleMapsPoint(lastPoint);
    },
    recenterOnActivitySelection(): void {
      let points = this.visibleActivities
        .map((multilineTrack: MultiLineString) => multilineTrack.coordinates)
        .flat(2)
        .map(this.toGoogleMapsPoint);

      // if activities coordinates are missing, then focus on path
      if (!points.length) {
        points = this.visibleTracks
          .map((multilineTrack: MultiLineString) => multilineTrack.coordinates)
          .flat(2)
          .map(this.toGoogleMapsPoint);
      }

      points.forEach((point: google.maps.LatLng) => this.pointsInFocus.push(point));
    },
    hasCoordinates<T extends MultiLineString>(multiLineString: T): boolean {
      return multiLineString.coordinates.flat(1).length > 0;
    },
    toDate(time: number): Date {
      return new Date(time * 1000);
    },
    formatTime(date: Date): string {
      const hhmmss = date.toTimeString().split(' ')[0];
      return hhmmss.substring(0, 5);
    },
    inferPillFillColor<T>(index: number, arr: T[]): string {
      switch (index) {
        case 0:
          return this.drawing.tracks.start_circle.fill.color;
        case arr.length - 1:
          return this.drawing.tracks.end_circle.fill.color;
        default:
          return '';
      }
    },
    inferPillTextColor<T>(index: number, arr: T[]): string {
      switch (index) {
        case 0:
        case arr.length - 1:
          return this.drawing.timestamps.text.outline;
        default:
          return this.drawing.timestamps.text.fill;
      }
    },
    inferPillTextOutline<T>(index: number, arr: T[]): string {
      switch (index) {
        case 0:
        case arr.length - 1:
          return '';
        default:
          return this.drawing.timestamps.text.outline;
      }
    },
    toLiteral(field: Field): google.maps.LatLngLiteral {
      return { lng: field.lon, lat: field.lat };
    },
    isVisibleInViewPort(field: Field): boolean {
      return this.map?.getBounds()?.contains(this.toLiteral(field)) || false;
    },
    getCaptureAreaBounds() {
      const mapRef = this.$refs['map-container'] as HTMLElement | null;
      const captureRegionRef = this.$refs['capture-region'] as HTMLElement | null;

      const mapRect = mapRef?.getBoundingClientRect();
      const captureRegion = captureRegionRef?.getBoundingClientRect();

      if (mapRect && captureRegion) {
        const swPoint = {
          x: captureRegion.left - mapRect.left,
          y: captureRegion.bottom - mapRect.top,
        };
        const nePoint = {
          x: captureRegion.right - mapRect.left,
          y: captureRegion.top - mapRect.top,
        };

        const swLatLng = this.overlay
          ?.getProjection()
          .fromContainerPixelToLatLng(new google.maps.Point(swPoint.x, swPoint.y));
        const neLatLng = this.overlay
          ?.getProjection()
          .fromContainerPixelToLatLng(new google.maps.Point(nePoint.x, nePoint.y));

        return new google.maps.LatLngBounds(swLatLng, neLatLng);
      }

      throw new Error('Could not get bounds for capture area');
    },
  },
});
