import { ActionTree } from 'vuex';

import { RootState } from '@/store/types';

import { ProductsState } from './types';

const actions: ActionTree<ProductsState, RootState> = {
  async subscribe({ dispatch }) {
    return Promise.all([
      dispatch('companyFertilizers/subscribe'),
      dispatch('crops/subscribe'),
      dispatch('harvests/subscribe'),
      dispatch('herbizides/subscribe'),
      dispatch('mineralFertilizers/subscribe'),
      dispatch('miscellaneous/subscribe'),
      dispatch('otherProductsAndFertilizers/subscribe'),
      dispatch('secondaryFertilizers/subscribe'),
      dispatch('seeds/subscribe'),
      dispatch('waads/subscribe'),
    ]);
  },
  reset({ dispatch }) {
    dispatch('companyFertilizers/reset');
    dispatch('crops/reset');
    dispatch('harvests/reset');
    dispatch('herbizides/reset');
    dispatch('mineralFertilizers/reset');
    dispatch('miscellaneous/reset');
    dispatch('otherProductsAndFertilizers/reset');
    dispatch('secondaryFertilizers/reset');
    dispatch('seeds/reset');
    dispatch('waads/reset');
  },
};

export default actions;
