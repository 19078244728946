/**
 *
 * @param value checks if value is a string of the form "hh:mm:ss" where the ss part is optional
 * @returns the number of seconds
 */
export default function parsePatternHHMMSS(value) {
    const parts = value.match(/^(?:(\d{1,2}):)?(\d{1,2}):?(?:(\d{1,2}))?$/);
    if (parts == null)
        return null;
    const separators = value.match(/:/g);
    if (!separators)
        return null;
    /* eslint-disable no-param-reassign */
    const seconds = parts.slice(1).reduce((acc, part, partIndex) => {
        if (!part)
            return acc;
        const parsedValue = parseInt(part, 10);
        if (Number.isNaN(parsedValue))
            return acc;
        if (partIndex === 0) {
            acc += parsedValue * 3600;
        }
        else if (partIndex === 1) {
            acc += parsedValue * 60;
        }
        else if (partIndex === 2) {
            acc += parsedValue;
        }
        return acc;
    }, 0);
    /* eslint-enable no-param-reassign */
    if (seconds === 0)
        return null;
    return seconds;
}
