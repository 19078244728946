import { ActionContext } from 'vuex';

import FindService from '@/activities/modals/createEditActivity/services/FindService';
import ActivityEquipmentService from '@/activities/services/ActivityEquipmentService';
import ActivityProductService from '@/activities/services/ActivityProductService';
import ActivityService from '@/activities/services/ActivityService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import CompanyService from '@/auth/services/CompanyService';
import EquipmentService from '@/equipments/services/EquipmentService';
import FieldService from '@/fields/services/FieldService';
import ProductService from '@/products/services/ProductService';
import { RootState } from '@/store/types';

import { CreateEditActivityState } from '../../types';

export default function initServices(
  state: CreateEditActivityState,
  rootState: RootState,
  rootGetters: ActionContext<CreateEditActivityState, RootState>['rootGetters'],
) {
  const equipmentService = new EquipmentService(rootState.equipments.data);
  const activityEquipmentService = new ActivityEquipmentService(equipmentService);
  const productService = new ProductService(
    rootState.products.mineralFertilizers.data,
    rootState.products.companyFertilizers.data,
    rootState.products.secondaryFertilizers.data,
    rootState.products.herbizides.data,
    rootState.products.crops.data,
    rootState.products.miscellaneous.data,
    rootState.products.harvests.data,
    rootState.products.seeds.data,
    rootState.products.otherProductsAndFertilizers.data,
    rootState.products.waads.data,
  );
  const activityProductService = new ActivityProductService(
    rootState.units.data,
    productService,
    calculateAmountsAndUnits,
  );
  const fieldService = new FieldService(rootState.fields.data, rootGetters['products/findProductById']);
  const companyService = new CompanyService(rootState.auth.companiesById);
  const activityService = new ActivityService(
    rootState.activities.data,
    fieldService,
    activityEquipmentService,
    activityProductService,
    companyService,
  );
  const findService = new FindService(state.activities, activityProductService);

  return {
    activityEquipmentService,
    activityProductService,
    companyService,
    activityService,
    findService,
  };
}
