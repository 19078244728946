
import { Activity } from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';

import ActivityProductService from '@/activities/services/ActivityProductService';
import { AmountUnitPair, AmountsAndUnits } from '@/activities/types';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import numbro from '@/initNumbro';
import ProductService from '@/products/services/ProductService';
import { Product } from '@/shared/api/rest/models';
import { Unit } from '@/shared/api/rest/models/unit';
import FormFieldInputNumberFormattedDisplayValue from '@/shared/components/form/FormFieldInputNumberFormattedDisplayValue.vue';
import { Data } from '@/shared/mixins/store/types';
import { RootState } from '@/store/types';

import { MergedActivityProduct } from '../services/MergedActivityProductsService';
import { Getters as CreateEditActivityGetters } from '../store/getters';

export default defineComponent({
  name: 'AmountSectionContainer',
  components: { FormFieldInputNumberFormattedDisplayValue },
  props: {
    activityProduct: {
      type: Object as PropType<MergedActivityProduct>,
      required: true,
    },
  },
  data() {
    return {
      productIsMissing: false,
    };
  },
  computed: {
    activities(): Activity[] {
      return (this.$store.state as RootState).activities.createEditActivity.activities;
    },
    units(): Data<Unit> {
      return (this.$store.state as RootState).units.data;
    },
    processedArea(): number | null {
      return this.$store.getters[
        `activities/createEditActivity/sumProcessedArea`
      ] as CreateEditActivityGetters['sumProcessedArea'];
    },
    amountsAndUnits(): AmountsAndUnits {
      return this.activityProductService.getAmountsAndUnits(this.activityProduct, this.processedArea);
    },
    amountRelative(): number | null {
      const {
        amountUnitRelative: { amount },
      } = this.amountsAndUnits;
      return amount;
    },
    amountTotal(): number | null {
      const {
        amountUnitTotal: { amount },
      } = this.amountsAndUnits;
      return amount;
    },
    variousRelative(): boolean {
      return this.isVarious(this.amountsAndUnits.amountUnitRelative);
    },
    variousTotal(): boolean {
      return this.isVarious(this.amountsAndUnits.amountUnitTotal);
    },
    labelRelative(): string {
      const {
        amountUnitRelative: { unitId, isFixed },
      } = this.amountsAndUnits;
      if (unitId) {
        const unit = this.units[unitId];
        return `${isFixed ? '• ' : ''}${this.$t('Menge')} in ${unit.name}`;
      }

      return this.$t('Menge') as string;
    },
    labelTotal(): string {
      const {
        amountUnitTotal: { unitId, isFixed },
      } = this.amountsAndUnits;
      if (unitId) {
        const unit = this.units[unitId];
        return `${isFixed ? '• ' : ''}${this.$t('Gesamt')} in ${unit.name}`;
      }

      return this.$t('Gesamt') as string;
    },
    productService(): ProductService {
      const rootState = this.$store.state as RootState;
      return new ProductService(
        rootState.products.mineralFertilizers.data,
        rootState.products.companyFertilizers.data,
        rootState.products.secondaryFertilizers.data,
        rootState.products.herbizides.data,
        rootState.products.crops.data,
        rootState.products.miscellaneous.data,
        rootState.products.harvests.data,
        rootState.products.seeds.data,
        rootState.products.otherProductsAndFertilizers.data,
        rootState.products.waads.data,
      );
    },
    activityProductService(): ActivityProductService {
      return new ActivityProductService(this.units, this.productService, calculateAmountsAndUnits);
    },
    isAvailableOnAllActivities(): boolean {
      return this.activityProduct.activityIds.length === this.activities.length;
    },
    selectedProduct(): Product | undefined {
      return this.$store.getters['products/findProductById'](this.activityProduct.productId);
    },
    isHerbicide(): boolean {
      return this.$store.getters['productCategories/isHerbicide'](this.selectedProduct?.categoryId ?? '');
    },
    defaultNDecimals(): number {
      return this.isHerbicide ? 3 : 2;
    },
  },
  methods: {
    setProductIsMissing(productIsMissing: boolean) {
      this.productIsMissing = productIsMissing;
    },
    isVarious(amountUnitPair: AmountUnitPair): boolean {
      const { amount } = amountUnitPair;
      return (amount && amount < 0) || !this.isAvailableOnAllActivities;
    },
    updateAmountTotal(amount: number): void {
      this.updateAmountAndUnit(amount, this.amountsAndUnits.amountUnitTotal.unitId);
    },
    updateAmountRelative(amount: number): void {
      this.updateAmountAndUnit(amount, this.amountsAndUnits.amountUnitRelative.unitId);
    },
    updateAmountAndUnit(amount: number, unitId: string | null) {
      const unit = unitId ? this.units[unitId] : null;
      if (!unit) {
        this.setProductIsMissing(true);
        // TODO: clear input
        return;
      }
      this.$store.commit(`activities/createEditActivity/updateAmountAndUnitOnActivityProductInAllActivities`, {
        activityProductId: this.activityProduct.id,
        updateValues: {
          amount,
          unit,
        },
        rootState: this.$store.state,
      });
      this.setProductIsMissing(false);
    },
    inputFormatterRelative(value: string): string {
      const unit = this.units[this.amountsAndUnits.amountUnitRelative.unitId ?? ''];
      return this.inputFormatter(value, unit);
    },
    inputFormatterTotal(value: string): string {
      const unit = this.units[this.amountsAndUnits.amountUnitTotal.unitId ?? ''];
      return this.inputFormatter(value, unit);
    },
    inputFormatter(value: string, unit: Unit): string {
      if (value === '') return value;

      try {
        return numbro(value).format({ mantissa: this.isHerbicide ? this.defaultNDecimals : unit.ddiDecimals });
      } catch (error) {
        // user input cannot be formatted (e.g. if there are letters)
        return '';
      }
    },
    updateCurrentActivityProductId() {
      this.$store.commit(`activities/createEditActivity/setCurrentActivityProductId`, this.activityProduct.id);
    },
    numbro,
  },
});
