
import { Activity } from 'farmdok-rest-api';
import Handsontable from 'handsontable';
import _debounce from 'lodash.debounce';
import { PropType, defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import TableFooterContainer from '@/activities/containers/table/TableFooterContainer.vue';
import ActivityService from '@/activities/services/ActivityService';
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
import CompanyService from '@/auth/services/CompanyService';
import EquipmentService from '@/equipments/services/EquipmentService';
import FieldService from '@/fields/services/FieldService';
import ProductService from '@/products/services/ProductService';
import { Unit } from '@/shared/api/rest/models';
import TableColumnsService from '@/shared/handsontable/rework/services/TableColumnsService';
import { ColumnSettingsWithUserSettings, FarmdokColumnSettings } from '@/shared/handsontable/rework/types';
import { Data } from '@/shared/mixins/store/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

import TableActivities from '../../components/table/TableActivities.vue';
import TableActivitiesLoading from '../../components/table/TableActivitiesLoading.vue';
import ActivityEquipmentService from '../../services/ActivityEquipmentService';
import ActivityProductService from '../../services/ActivityProductService';
import { CellChangeWithIdsActivityTable, TableDataActivity } from '../../types';
import NoContentContainer from './NoContentContainer.vue';

export default defineComponent({
  name: 'TableActivitiesContainer',
  props: {
    filterString: { type: String, default: '' },
    columns: {
      type: Array as PropType<Array<FarmdokColumnSettings>>,
      required: true,
    },
  },
  data() {
    return {
      debouncedRuleCheck: () => {},
      activitiesLoaded: false,
    };
  },
  components: { TableFooterContainer, TableActivities, TableActivitiesLoading, NoContentContainer },
  created() {
    this.loadData();
    this.debouncedRuleCheck = _debounce(this.triggerRuleCheckRecheck, 2000);
  },
  computed: {
    ...mapGetters('auth', ['currentProcessOrderIds', 'currentCompanyIds']),
    activities(): Activity[] {
      return Object.values(this.$store.state.activities.data);
    },
    pesticideIdsInUse(): string[] {
      return this.$store.getters['activities/pesticideIdsInUse'];
    },
    units(): Data<Unit> {
      return this.$store.state.units.data;
    },
    tableData(): TableDataActivity[] {
      return this.$store.getters['activities/tableDataActivities'];
    },
    columnsForHandsontable(): ColumnSettingsWithUserSettings[] {
      const { userColumnSettings } = this.$store.state.activities;
      const tableColumnsService = new TableColumnsService(this.columns);
      const columnsForHot = tableColumnsService.getColumnsForHandsontable(userColumnSettings);
      if (this.featureActivitiesReadonly) {
        return this.withReadOnly(columnsForHot);
      }
      return columnsForHot;
    },
    fixedColumnsLeft(): number {
      const fixedColumns = this.columnsForHandsontable.findIndex((column) => column.fixedColumnsLeftUntilHere === true);
      if (fixedColumns === -1) return 0;

      return fixedColumns + 1;
    },
    loading(): boolean {
      return (
        this.$store.getters['activities/loading'] ||
        this.$store.getters['activities/ruleCheck/rules/loading'] ||
        this.$store.getters['activities/ruleCheck/activityRulesViolations/loading'] ||
        this.$store.getters['activityTypes/loading'] ||
        this.$store.getters['customers/loading'] ||
        this.$store.getters['equipments/loading'] ||
        this.$store.getters['fields/loading'] ||
        this.$store.getters['productCategories/loading'] ||
        this.$store.getters['pests/loading'] ||
        this.$store.getters['storagePlaces/loading'] ||
        this.$store.getters['units/loading'] ||
        this.$store.getters['users/loading']
      );
    },
    productService(): ProductService {
      const rootState = this.$store.state as RootState;
      return new ProductService(
        rootState.products.mineralFertilizers.data,
        rootState.products.companyFertilizers.data,
        rootState.products.secondaryFertilizers.data,
        rootState.products.herbizides.data,
        rootState.products.crops.data,
        rootState.products.miscellaneous.data,
        rootState.products.harvests.data,
        rootState.products.seeds.data,
        rootState.products.otherProductsAndFertilizers.data,
        rootState.products.waads.data,
      );
    },
    activityProductService(): ActivityProductService {
      return new ActivityProductService(this.units, this.productService, calculateAmountsAndUnits);
    },
    equipmentService(): EquipmentService {
      return new EquipmentService(this.$store.state.equipments.data);
    },
    activityEquipmentService(): ActivityEquipmentService {
      return new ActivityEquipmentService(this.equipmentService);
    },
    fieldService(): FieldService {
      return new FieldService(this.$store.state.fields.data, this.$store.getters['products/findProductById']);
    },
    companyService(): CompanyService {
      return new CompanyService((this.$store.state as RootState).auth.companiesById);
    },
    activityService(): ActivityService {
      return new ActivityService(
        this.$store.state.activities.data,
        this.fieldService,
        this.activityEquipmentService,
        this.activityProductService,
        this.companyService,
      );
    },
    featureActivitiesReadonly(): boolean {
      return this.$store.getters.currentCompaniesHaveFeatureReadonly(availableFeatures.FEATURE_ACTIVITIES);
    },
  },
  watch: {
    currentProcessOrderIds() {
      this.loadData();
    },
    currentCompanyIds() {
      this.loadData();
    },
    activities() {
      if (!this.activitiesLoaded) {
        this.activitiesLoaded = true;
      } else {
        this.debouncedRuleCheck();
      }
    },
  },
  methods: {
    withReadOnly(columnsForHot: ColumnSettingsWithUserSettings[]): ColumnSettingsWithUserSettings[] {
      return columnsForHot.map((column) => {
        if ((column.data as string).includes('expand')) {
          return column;
        }

        if (column.subtableColumns) {
          return {
            ...column,
            readOnly: true,
            subtableColumns: column.subtableColumns.map((subtableColumn: any) => ({
              ...subtableColumn,
              readOnly: true,
            })),
          };
        }

        return {
          ...column,
          readOnly: true,
        };
      });
    },
    onUpdateExpandedTableData(expandedTableData: TableDataActivity[]) {
      const productIds = expandedTableData
        .filter((activity) => activity.product?.productId ?? false)
        .map((activity) => activity.product?.productId)
        .filter((productId) => this.pesticideIdsInUse.includes(productId ?? ''));
      this.$store.dispatch('pesticideIndications/loadByIds', productIds);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange(changes: CellChangeWithIdsActivityTable[], source: Handsontable.ChangeSource) {
      // TODO move this function into Activity service
      changes.forEach((change) => {
        const [, key, , newValue, { id: activityId }] = change;
        const metadataKeys = ['expand', 'select'];

        if (!metadataKeys.includes(key)) {
          this.debouncedRuleCheck();
        }

        if (key.startsWith('product')) {
          this.onChangeActivityProduct(change);
          return;
        }

        if (key.startsWith('equipment')) {
          this.onChangeActivityEquipment(change);
          return;
        }

        if (key === 'fieldDropdownItem') {
          this.onChangeFieldDropdownItem(change);
          return;
        }

        if (metadataKeys.includes(key)) {
          this.$store.commit('activities/addOrUpdateTableDataMetadataByKeyAndValue', {
            activityId,
            key,
            value: newValue,
          });
          return;
        }

        if (key === 'activityTypeDropdownItem') {
          this.$store.dispatch('activities/updateActivityTypeAndSync', {
            activityId,
            activityTypeId: newValue.id,
          });
          return;
        }

        this.$store.dispatch('activities/updateEntryByKeyAndValueAndSync', {
          guid: activityId,
          key,
          value: newValue,
        });
      });
    },
    onChangeActivityProduct(change: CellChangeWithIdsActivityTable) {
      const { id: activityId } = change[4];
      const activityProducts = (this.$store.state as RootState).activities.data[activityId].products;
      const companyId = this.activityService.getCompanyOfActivity(activityId).id;
      const updatedActivityProducts = this.activityProductService.updateActivityProductsFromChange(
        activityProducts,
        change,
        companyId,
      );

      this.$store.dispatch('activities/updateEntryByKeyAndValueAndSync', {
        guid: activityId,
        key: 'products',
        value: updatedActivityProducts,
      });
    },
    onChangeActivityEquipment(change: CellChangeWithIdsActivityTable) {
      const { id: activityId } = change[4];
      const activityEquipment = (this.$store.state as RootState).activities.data[activityId].equipment;
      const companyId = this.activityService.getCompanyOfActivity(activityId).id;

      const updatedActivityEquipment = this.activityEquipmentService.updateActivityEquipmentsFromChange(
        activityEquipment,
        change,
        companyId,
      );

      this.$store.dispatch('activities/updateEntryByKeyAndValueAndSync', {
        guid: activityId,
        key: 'equipment',
        value: updatedActivityEquipment,
      });
    },
    onChangeFieldDropdownItem(change: CellChangeWithIdsActivityTable) {
      const { id: fieldId } = change[3];
      const { id: activityId } = change[4];

      const updatedField = (this.$store.state as RootState).fields.data[fieldId];

      this.$store.commit('activities/updateEntryByKeyAndValue', {
        guid: activityId,
        key: 'processedArea',
        value: updatedField.fieldSize,
      });

      this.$store.dispatch('activities/updateEntryByKeyAndValueAndSync', {
        guid: activityId,
        key: 'fieldId',
        value: fieldId,
      });
    },
    loadData() {
      this.$store.dispatch('activities/subscribe', { forceRefresh: true });
      this.$store.dispatch('activities/ruleCheck/rules/subscribe');
      this.$store.dispatch('activities/ruleCheck/activityRuleViolations/subscribe');
      this.$store.dispatch('equipments/subscribe');
      this.$store.dispatch('fields/subscribe');
      this.$store.dispatch('activityTypes/subscribe');
      this.$store.dispatch('customers/subscribe');
      this.$store.dispatch('products/subscribe');
      this.$store.dispatch('productCategories/subscribe');
      this.$store.dispatch('pests/subscribe');
      this.$store.dispatch('storagePlaces/subscribe');
      this.$store.dispatch('units/subscribe');
      this.$store.dispatch('users/subscribe');
    },
    onUpdateHot(hot: Handsontable | null) {
      this.$store.commit('activities/setHot', hot);
      this.$emit('update:hot', hot);
    },
    triggerRuleCheckRecheck() {
      this.$store.dispatch('activities/ruleCheck/activityRuleViolations/recheck');
    },
  },
});
