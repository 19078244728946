
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import MapSelectFields from '@/shared/components/map/MapSelectFields.vue';
import { Fields, Polygon, PolygonStates } from '@/shared/components/map/types';
import { RootState } from '@/store/types';

import { WorkflowKey } from '../store/types';

export default defineComponent({
  name: 'MapContainer',
  components: { MapSelectFields },
  created() {
    this.$store.dispatch('fields/subscribe');
    this.$store.dispatch('products/crops/subscribe');
  },
  computed: {
    ...mapState('products/crops', { crops: 'data' }),

    ...mapState({
      polygons(state: any): Record<string, Polygon> {
        // @ts-ignore
        return state.activities.createEditActivity.polygons;
      },
    }),
    ...mapGetters({
      fields: 'fields',
    }),
    selectedFields: {
      get(): string[] {
        return this.$store.getters[`activities/createEditActivity/selectedFields`];
      },
      set(selected: string[]) {
        this.$store.dispatch(`activities/createEditActivity/updateActivitiesBasedOnSelectedFields`, selected);
      },
    },
    filteredFields(): Fields {
      if (this.workflowKey === 'editActivity') {
        const filteredFields = Object.keys(this.fields)
          .filter((key) => this.selectedFields.includes(key))
          .reduce((acc: Fields, key) => {
            acc[key] = this.fields[key];
            return acc;
          }, {});
        return filteredFields;
      }
      return this.fields;
    },
    workflowKey(): WorkflowKey {
      return (this.$store.state as RootState).activities.createEditActivity.workflowKey;
    },
  },
  methods: {
    addPolygon(polygon: Polygon) {
      this.$store.commit(`activities/createEditActivity/addPolygon`, polygon);
    },
    polygonSetState({ key, state }: { key: string; state: PolygonStates }) {
      this.$store.commit(`activities/createEditActivity/polygonSetState`, {
        key,
        state,
      });
    },
  },
});
