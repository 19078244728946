var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue, { ref } from 'vue';
const pollingInProgress = ref(false);
const importProgress = ref(0);
const importStatus = ref();
const importErrorMessage = ref(null);
export default function useCnhActivityImport() {
    const POLLING_INTERVAL = 3000;
    const { activityImportApi } = Vue.prototype.$api;
    function startPollingImportStatus() {
        pollingInProgress.value = true;
        importProgress.value = 0;
        importErrorMessage.value = null;
        setTimeout(() => pollImportStatus(), 3000);
    }
    function stopPollingImportStatus() {
        pollingInProgress.value = false;
    }
    function pollImportStatus() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function* () {
            if (!pollingInProgress.value)
                return;
            try {
                const statusResponse = yield cnhImportStatus();
                importStatus.value = (_b = (_a = statusResponse.data.data) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : 'Error';
                importProgress.value = (_d = (_c = statusResponse.data.data) === null || _c === void 0 ? void 0 : _c.progressPercent) !== null && _d !== void 0 ? _d : 1;
                if (((_e = statusResponse.data.data) === null || _e === void 0 ? void 0 : _e.status) === 'Pending') {
                    setTimeout(() => pollImportStatus(), POLLING_INTERVAL);
                }
                else if (((_f = statusResponse.data.data) === null || _f === void 0 ? void 0 : _f.status) === 'Error') {
                    importErrorMessage.value = (_h = (_g = statusResponse.data.data) === null || _g === void 0 ? void 0 : _g.errorUserMessage) !== null && _h !== void 0 ? _h : 'An error occured';
                    importProgress.value = 0;
                    pollingInProgress.value = false;
                    stopPollingImportStatus();
                }
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    function resetImport() {
        const requestParametes = {
            activityImportResetRequest: { source: 'CnhDownload' },
        };
        return activityImportApi.activityImportReset(requestParametes);
    }
    function cnhImportStatus() {
        const requestParametes = { source: 'CnhDownload' };
        return activityImportApi.activityImportStatus(requestParametes);
    }
    return {
        startPollingImportStatus,
        stopPollingImportStatus,
        resetImport,
        cnhImportStatus,
        importProgress,
        pollingInProgress,
        importStatus,
        importErrorMessage,
    };
}
