import _cloneDeep from 'lodash.clonedeep';
import ActivityProductService from '@/activities/services/ActivityProductService';
import initServices from './utils/initServices';
export default function updatePestOnActivityProductInAllActivities({ state, commit, rootState, rootGetters }, data) {
    const { activityService, findService } = initServices(state, rootState, rootGetters);
    const referenceActivityProduct = _cloneDeep(findService.getActivityProduct(data.activityProductId));
    state.activities.forEach((activity) => {
        const clonedActivity = _cloneDeep(activity);
        const family = activityService.findActivityProductsOrSiblings(clonedActivity, referenceActivityProduct);
        if (family.length > 0) {
            updateProducts(family, data.updateValues, clonedActivity.id, commit);
        }
        else {
            addNewProduct(clonedActivity, data.updateValues, activityService, commit);
        }
    });
}
function updateProducts(product, updateValues, activityId, commit) {
    product.forEach((p) => {
        updateProduct(p, updateValues, activityId, commit);
    });
}
function updateProduct(product, updateValues, activityId, commit) {
    const updatedProduct = updateActivityProductValues(product, updateValues);
    commit('setActivityProduct', { activityId, activityProduct: updatedProduct });
}
function addNewProduct(activity, updateValues, activityService, commit) {
    let activityProduct = ActivityProductService.createActivityProduct();
    activityProduct = updateActivityProductValues(activityProduct, updateValues);
    activityService.addActivityProduct(activity, activityProduct);
    commit('addActivityProduct', { activityId: activity.id, activityProduct: activity.products.slice(-1)[0] });
}
// TODO combine this with updateValues of updateProductOnActivityProductInAllActivities.ts
function updateActivityProductValues(activityProduct, { pestId, pesticideIndicationId }) {
    const updatedActivityProduct = _cloneDeep(activityProduct);
    updatedActivityProduct.pestId = pestId;
    updatedActivityProduct.pesticideIndicationId = pesticideIndicationId;
    return updatedActivityProduct;
}
